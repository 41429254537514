import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Button, message as AntMessage, Modal, Table} from 'antd'
import PropTypes from 'prop-types'
import {LayoutAdmin} from './../../../components'
import {deleteAdminAPI, getAdminsAPI, setAdminPropertyValue} from '../../../effects/actions'
import {EyeOutlined} from "@ant-design/icons"

const AdminListPage = ({admins, loading, totalAdmins, getAdminsAPI, deleteAdminAPI, successMessage, setAdminPropertyValue}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const [showAdminDeleteModal, setShowAdminDeleteModal] = useState(false)
  const [adminToDelete, setAdminToDelete] = useState({})

  const history = useHistory()

  useEffect(() => {
    getAdminsAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getAdminsAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      setAdminPropertyValue('successMessage', null)
      AntMessage.success(successMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage])

  const adminListColumnsCustom = [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'action',
      width: 50,
      render: (id) => (
          <Link to={`/admin/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('email')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Receives Notifications?',
      dataIndex: 'receives_notifications',
      key: 'receives_notifications',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('receives_notifications')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (member) => member ? 'YES': 'NO',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button danger onClick={() => handleDeleteConfirmation(record)}>Delete Admin</Button>
      ),
    },
  ]

  const handleDeleteConfirmation = (admin) => {
    setAdminToDelete(admin)
    setShowAdminDeleteModal(true)
  }

  const cancelAdminDelete = () => {
    setAdminToDelete({})
    setShowAdminDeleteModal(false)
  }

  const handleDeleteAdmin = () => {
    setShowAdminDeleteModal(false)
    deleteAdminAPI(adminToDelete)
  }

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }
  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/admin/new')}>New</Button>
      <Table
          dataSource={admins}
          columns={adminListColumnsCustom}
          pagination={{ total: totalAdmins, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading}
          rowKey="id"
      />
      <Modal
          title={<b>Are you sure?</b>}
          visible={showAdminDeleteModal}
          onOk={handleDeleteAdmin}
          onCancel={cancelAdminDelete}
          okText={'Delete'}
          okButtonProps={{ type: 'danger' }}
      >Remove Admin {adminToDelete.name} ({adminToDelete.email})?</Modal>
    </LayoutAdmin>
  )
}

AdminListPage.propTypes = {
  getAdminsAPI: PropTypes.func,
  admins: PropTypes.array,
  loading: PropTypes.bool,
  removed: PropTypes.any,
  deleteAdminAPI: PropTypes.func,
}

const mapStateToProps = ({ adminAPI }) => {
  const {
    admins, loading, totalAdmins, removed, successMessage,
  } = adminAPI
  return {
    admins,
    loading,
    totalAdmins,
    successMessage,
    removed,
  }
}

export default
connect(mapStateToProps, {
  getAdminsAPI,
  deleteAdminAPI,
  setAdminPropertyValue,
})(AdminListPage)
