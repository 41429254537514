import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {Button as ButtonComponent} from '../../index'
import {Button, Col, message as AntMessage, Modal, Row, Table} from 'antd'
import {
    deleteMemberSplashAPI,
    getMemberSplashesAPI,
    putMemberSplashAPI,
    setMemberPropertyValue,
    repositionMemberSplashAPI,
    postMemberSplashAPI, setOriginalMemberSplashValue, getMemberSplashAPI,
} from '../../../effects/actions'
import { EyeOutlined } from "@ant-design/icons"
import { Util } from "../../../utils"
import styled from "styled-components"
import DraggableBodyRow from "../../../components/draggableBodyRow"
import MemberSplashForm from "../member-splash-form"

const ButtonStyled = styled(Button)`
    margin: 4px
`
const MemberSplashListPage = ({ memberSplashes, loading, getMemberSplashesAPI,postMemberSplashAPI,  putMemberSplashAPI, deleteMemberSplashAPI, getMemberSplashAPI, setMemberPropertyValue,successMessage,repositionMemberSplashAPI, memberSplashInputs, memberId, setOriginalMemberSplashValue }) => {
    const [orderKey, setOrderKey] = useState('order')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showSplashDeleteModal, setShowSplashDeleteModal] = useState(false)
    const [showSplashStatusModal, setShowSplashStatusModal] = useState(false)
    const [showOrderModal, setShowOrderModal] = useState(false)
    const [selectedSplash, setSelectedSplash] = useState({})

    const [isAddNewModalVisible, setIsAddNewModalVisible] = useState(false)
    const [isEditModalVisible, setIsEditModalVisible] = useState(false)

    const splashListColumns = [
        {
            title: 'View',
            key: 'id',
            width: 50,
            render: (splash) => {
                return (
                        <Button onClick={() => showEditModal(splash)}>
                            <EyeOutlined />
                        </Button>
                )
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('type')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('order')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Time (secs)',
            dataIndex: 'time_length',
            key: 'time_length',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('time_length')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (time_length) => time_length / 1000,
        },
        {
            title: 'Status',
            dataIndex: 'is_enabled',
            key: 'is_enabled',
            render: (is_enabled) => {
                return is_enabled ? 'Enabled' : 'Disabled'
            },
        },
        {
            title: 'Media',
            dataIndex: 'media',
            width: 200,
            render: (media) => {
                return media !== null && Util.renderMedia(media, "auto")
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div>
                    <ButtonStyled danger onClick={() => handleDeleteConfirmation(record)}>Delete Splash</ButtonStyled>
                    <ButtonStyled primary onClick={() => handleStatusConfirmation(record)}>Toggle Status</ButtonStyled>
                </div>
            ),
        },
    ]

    useEffect(() => {
        getMemberSplashesAPI({member_id: memberId, orderKey, orderDirection})
    }, [getMemberSplashesAPI, orderKey, orderDirection, memberId])

    useEffect(() => {
        if (successMessage) {
            AntMessage.success(successMessage)
            setMemberPropertyValue('successMessage', null)
        }
    }, [setMemberPropertyValue, successMessage])

    const showEditModal = (splash) => {
        getMemberSplashAPI(splash.id)
        setIsEditModalVisible(true)
    }
    const handleStatusConfirmation = (splash) => {
        setSelectedSplash(splash)
        setShowSplashStatusModal(true)
    }
    const handleDeleteConfirmation = (splash) => {
        setSelectedSplash(splash)
        setShowSplashDeleteModal(true)
    }

    const cancelSplashDelete = () => {
        setSelectedSplash({})
        setShowSplashDeleteModal(false)
    }

    const handleDeleteSplash = () => {
        setShowSplashDeleteModal(false)
        deleteMemberSplashAPI(selectedSplash)
    }

    const cancelSplashStatus = () => {
        setSelectedSplash({})
        setShowSplashStatusModal(false)
    }

    const handleStatusSplash = () => {
        setShowSplashStatusModal(false)
        putMemberSplashAPI({
            active: selectedSplash.active,
            id: selectedSplash.id,
            media: selectedSplash.media,
            message: selectedSplash.message,
            order: selectedSplash.order,
            time_length: selectedSplash.time_length / 1000,
            type: selectedSplash.type,
            is_enabled: !selectedSplash.is_enabled,
        })
    }

    const handleSortingOnOrderCol = () => {
        setShowOrderModal(false)
        setOrderKey('order')
        setOrderDirection('asc')
    }

    const cancelSortingOnOrderCol = () => {
        setShowOrderModal(false)
    }

    const onSave = (isNew = true) => {
        if(isNew){
            postMemberSplashAPI({...memberSplashInputs, member_id: memberId })
        }else{
            putMemberSplashAPI({...memberSplashInputs, member_id: memberId })
        }
    }

    const onCancel = () => {
        setOriginalMemberSplashValue(true)
    }

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            if (orderKey !== 'order') {
                setShowOrderModal(true)
                return
            }
            repositionMemberSplashAPI({
                fromIndex: dragIndex,
                toIndex: hoverIndex,
                orderKey,
                orderDirection,
                member_id: memberId,
            })
        },
        [memberId, orderDirection, orderKey, repositionMemberSplashAPI],
    )

    return (
        <div>
            <Row justify="end">
                <Col span={6}>
                    <ButtonComponent marginh={String(true)} type="primary" onClick={() => setIsAddNewModalVisible(true)} label="Add New Splash"/>
                </Col>
            </Row>
            <DndProvider backend={HTML5Backend}>
                <Table
                    dataSource={memberSplashes}
                    columns={splashListColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                    rowClassName={(record) => {
                        if (!record.is_enabled) {
                            return 'light-red-colored-row'
                        }
                    }}
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
            </DndProvider>
            <Modal
                title={<b>Splash list ordering issue</b>}
                visible={showOrderModal}
                onOk={handleSortingOnOrderCol}
                onCancel={cancelSortingOnOrderCol}
                okText={'Sort on order column'}
                okButtonProps={{ type: 'primary' }}
            >The table must be sorted by Order prior to changing the order of elements. Do you want to sort the table by Order?</Modal>
            <Modal
                title={<b>Are you sure?</b>}
                visible={showSplashDeleteModal}
                onOk={handleDeleteSplash}
                onCancel={cancelSplashDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Delete {selectedSplash.type} splash: {selectedSplash.name} - {selectedSplash.message}</Modal>
            <Modal
                title={<b>Are you sure?</b>}
                visible={showSplashStatusModal}
                onOk={handleStatusSplash}
                onCancel={cancelSplashStatus}
                okText={selectedSplash.is_enabled ? 'Disable' : 'Enable'}
                okButtonProps={{ type: selectedSplash.is_enabled ? 'danger' : 'primary' }}
            >{selectedSplash.is_enabled ? 'Disable' : 'Enable'} {selectedSplash.type} splash: {selectedSplash.name} - {selectedSplash.message}</Modal>
            <Modal
                visible={isEditModalVisible}
                onOk={() => {
                    onSave( false)
                    setIsEditModalVisible(false)}
                }
                onCancel={() =>{
                    onCancel()
                    setIsEditModalVisible(false)
                }}
            >
                <MemberSplashForm isNew={false}/>
            </Modal>
            <Modal
                visible={isAddNewModalVisible}
                onOk={() => {
                    onSave()
                    setIsAddNewModalVisible(false)}
                }
                onCancel={() => {
                    onCancel()
                    setIsAddNewModalVisible(false)
                }}
                width={1000}
            >
                <MemberSplashForm viewMode={false} isNew={true}/>
            </Modal>
        </div>
    )
}

MemberSplashListPage.propTypes = {
    getMemberSplashesAPI: PropTypes.func,
    memberSplashes: PropTypes.array,
    splashListColumns: PropTypes.array,
    loading: PropTypes.bool,
}

const mapStateToProps = ({ memberSplashAPI }) => {
    const {memberSplashes, loading, splashListColumns, totalMemberSplashes, successMessage, memberSplashInputs} = memberSplashAPI
    return {
        memberSplashes,
        splashListColumns,
        totalMemberSplashes,
        successMessage,
        loading,
        memberSplashInputs,
    }
}

export default
connect(mapStateToProps, {
    getMemberSplashesAPI,
    deleteMemberSplashAPI,
    putMemberSplashAPI,
    setMemberPropertyValue,
    repositionMemberSplashAPI,
    postMemberSplashAPI,
    getMemberSplashAPI,
    setOriginalMemberSplashValue,
})(MemberSplashListPage)
