import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Card, Row, Col, Input, Space, message,
} from 'antd'
import { Button } from './../../components'
import { signInAPI, changeSignInInputValue, setAuthPropertyValue } from './../../effects/actions'
import { Util } from './../../utils'
import {Link} from "react-router-dom"

const CardStyled = styled(Card)`
  max-width: 400px;
`

const ForgotContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  margin: 24px 0 0 0;
  height: 40px;
  width: 100%;
`

const SignInForm = ({
  loading, signInInputs, signInAPI, changeSignInInputValue, errorMessage, setAuthPropertyValue,
  successMessage,
}) => {
  const onSubmit = () => {
    if (signInInputs.email === '') {
      message.warning('Email can\'t be empty')
    } else if (signInInputs.password === '') {
      message.warning('Password can\'t be empty')
    } else {
      signInAPI(signInInputs)
    }
  }

  const onChangeInput = (e) => {
    const { name, value } = e.target
    changeSignInInputValue(name, value)
  }

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setAuthPropertyValue('errorMessage', null)
    } else if (successMessage !== null) {
      window.location.replace('/home')
      setAuthPropertyValue('successMessage', null)
    }
  })

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={20} sm={16} md={12} lg={8} xl={5}>
        <CardStyled title="Sign in" bordered={false}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input name="email" type="text" placeholder="Email" size="large" onChange={onChangeInput} />
            <Input name="password" type="password" placeholder="Password" size="large" onChange={onChangeInput} />
            <StyledButton label="Sign in" full="true" onClick={onSubmit} loading={loading} disabled={loading} />
            <ForgotContainer>
              <Link to={'/forgot-password'}>Forgot password</Link>
            </ForgotContainer>
          </Space>
        </CardStyled>
      </Col>
    </Row>

  )
}

SignInForm.propTypes = {
  changeSignInInputValue: PropTypes.func,
  signInAPI: PropTypes.func,
  signInInputs: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  setAuthPropertyValue: PropTypes.func,
  successMessage: PropTypes.string,
}

const mapStateToProps = ({ authAPI }) => {
  const {
    errorMessage, successMessage, loading, signInInputs,
  } = authAPI
  return {
    errorMessage,
    successMessage,
    loading,
    signInInputs,
  }
}

export default
connect(mapStateToProps, {
  signInAPI,
  changeSignInInputValue,
  setAuthPropertyValue,
})(SignInForm)
