import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select} from 'antd'
import {changeSplashInputValue} from './../../effects/actions'
import UploadMedia from "../../components/upload-media"

const SplashForm = ({
  splashInputs, changeSplashInputValue, viewMode, isNew,
}) => {
  const onChangeInput = (e) => {
    const { name, value } = e.target
    changeSplashInputValue(name, value)
  }

  const typeOptions = ['image', 'video'].map(d => <Select.Option key={d}>{d}</Select.Option>)

  return (
    <Row gutter={[12, 0]}>

      <Col span={12}>
        <Form.Item label="Type" labelCol={{ span: 24 }}>
          <Select
              showSearch
              value={splashInputs.type}
              placeholder={"Type"}
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={true}
              onChange={(value) => {
                changeSplashInputValue('type', value)
              }}
              notFoundContent={<>0 results found</>}
              size="large"
              disabled={viewMode}
              labelInValue={false}
            >
            {typeOptions}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Message" labelCol={{ span: 24 }}>
          <Input name="message" type="text" placeholder="Message" size="large" onChange={onChangeInput} value={splashInputs.message} disabled={viewMode} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label="Time length (seconds)" labelCol={{ span: 24 }}>
          <Input name="time_length" type="text" placeholder="Time length (seconds)" size="large" onChange={onChangeInput} value={splashInputs.time_length} disabled={viewMode} />
        </Form.Item>
      </Col>
        {
            !isNew ? (
                <Col span={12}>
                    <Form.Item label="Order" labelCol={{ span: 24 }}>
                        <Input name="order" type="text" placeholder="Order" size="large" value={splashInputs.order} disabled />
                    </Form.Item>
                </Col>
            ) : null
        }
        <Col span={12}>
            <Form.Item label="Icon and text color" labelCol={{ span: 24 }}>
                <Input name="icons_fill_color" type="color" placeholder="Icons Color" size="large" onChange={onChangeInput} value={splashInputs.icons_fill_color} disabled={viewMode} />
            </Form.Item>
        </Col>
      <Col span={12}>
        <Form.Item label="Media" labelCol={{ span: 24 }}>
            <UploadMedia
                media={splashInputs.media}
                changeHandler={(value) => changeSplashInputValue('media', value)}
                imageDimensions={[200, 400]}
                viewMode={viewMode}
            />
        </Form.Item>
      </Col>
    </Row>

  )
}

SplashForm.propTypes = {
  changeSplashInputValue: PropTypes.func,
  splashInputs: PropTypes.object,
  viewMode: PropTypes.bool,
}

SplashForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ splashAPI }) => {
  const {
    errorMessage, successMessage, splashInputs,
  } = splashAPI
  return {
    errorMessage,
    successMessage,
    splashInputs,
  }
}

export default
connect(mapStateToProps, {
  changeSplashInputValue,
})(SplashForm)
