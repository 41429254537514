import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Row, Col, Form,
  Select, Input, Switch,
} from 'antd'
import {
  changeMessageInputValue, getMembersAPI, getMessageTemplatesAPI,
  getEventsAPI,
} from './../../effects/actions'
import { Util } from '../../utils'

const EditEventCampaignForm = ({
  messageInputs, changeMessageInputValue, viewMode,
  getMembersAPI, types, getMessageTemplatesAPI, getEventsAPI,
}) => {
  useEffect(() => {
    getMembersAPI()
    getMessageTemplatesAPI() // TODO is this needed here?
    getEventsAPI()
  }, [getEventsAPI, getMembersAPI, getMessageTemplatesAPI])

  const onChangeInput = (e) => {
    let { name, value } = e.target
    if(name === 'color_1' || name === 'color_2'){
      value = Util.hexToRgb(value)
    }
    changeMessageInputValue(name, value)
  }

  const typeOptions = types.map(d => <Select.Option key={d}>{d}</Select.Option>)

  return (
    <Row>
      <Col>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label="Type" labelCol={{ span: 24 }}>
              <Select
                  showSearch
                  value={messageInputs.type_id}
                  placeholder={"Type"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  labelInValue={false}
                  filterOption={true}
                  onChange={(value) => {
                    changeMessageInputValue('type_id', value)
                    changeMessageInputValue('message_template_id',  { key: null, label: null, value: 0})
                    changeMessageInputValue('event_id',  { key: null, label: null, value: 0})
                  }}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={true}
                >
                {typeOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {messageInputs.type_id === "message" && <Form.Item label="Template" labelCol={{ span: 24 }}>
              Template:
            </Form.Item>}
          </Col>

          <Col span={12}>
            {messageInputs.type_id === "event" &&
              <Form.Item label="Event" labelCol={{ span: 24 }}>
                  {messageInputs && messageInputs.event && `${messageInputs.event.id} - ${messageInputs.event.headline}`}
              </Form.Item>
            }
          </Col>
        </Row>


        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label="Show contact me" labelCol={{ span: 24 }}>
              <Switch name="show_contact_me" size="large" onChange={(value) => changeMessageInputValue('show_contact_me', value)} checked={messageInputs.show_contact_me} disabled={viewMode} />
            </Form.Item>
          </Col>
          {messageInputs.type_id === "event" && <Col span={12}>
            <Form.Item label="Show learn more" labelCol={{ span: 24 }}>
              <Switch name="show_learn_more" size="large" onChange={(value) => changeMessageInputValue('show_learn_more', value)} checked={messageInputs.show_learn_more} disabled={viewMode} />
            </Form.Item>
          </Col>}
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={4}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_one_show" size="large" onChange={(value) => changeMessageInputValue('action_one_show', value)} checked={messageInputs.action_one_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Action 1 text" labelCol={{ span: 24 }}>
              <Input name="action_one_text" type="text" placeholder="Action 1 text" size="large" onChange={onChangeInput} value={messageInputs.action_one_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Action 1 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_one_confirmation_message" type="text" placeholder="Action 1 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_one_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Action 1 text color" labelCol={{ span: 24 }}>
              <Input name="action_one_color" type="color" placeholder="Action 1 text color" size="large" onChange={onChangeInput} value={messageInputs.action_one_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Action 1 background color" labelCol={{ span: 24 }}>
              <Input name="action_one_background" type="color" placeholder="Action 1 background color" size="large" onChange={onChangeInput} value={messageInputs.action_one_background} disabled={viewMode} />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_two_show" size="large" onChange={(value) => changeMessageInputValue('action_two_show', value)} checked={messageInputs.action_two_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Action 2 text" labelCol={{ span: 24 }}>
              <Input name="action_two_text" type="text" placeholder="Action 2 text" size="large" onChange={onChangeInput} value={messageInputs.action_two_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Action 2 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_two_confirmation_message" type="text" placeholder="Action 2 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_two_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Action 2 text color" labelCol={{ span: 24 }}>
              <Input name="action_two_color" type="color" placeholder="Action 2 text color" size="large" onChange={onChangeInput} value={messageInputs.action_two_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Action 2 background color" labelCol={{ span: 24 }}>
              <Input name="action_two_background" type="color" placeholder="Action 2 background color" size="large" onChange={onChangeInput} value={messageInputs.action_two_background} disabled={viewMode} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>

  )
}

EditEventCampaignForm.propTypes = {
  changeMessageInputValue: PropTypes.func,
  getCampaignsAPI: PropTypes.func,
  getMembersAPI: PropTypes.func,
  messageInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  campaigns: PropTypes.array,
  statuses: PropTypes.array,
  isNew: PropTypes.bool,
  messageTemplates: PropTypes.array,
  getMessageTemplatesAPI: PropTypes.func,
  getEventsAPI:PropTypes.func,
}

EditEventCampaignForm.defaultProps = {
  viewMode: false,
  isNew: false,
}
const mapStateToProps = ({ messageAPI, memberAPI, statusAPI, eventAPI }) => {
  const {
    errorMessage, successMessage, messageInputs, types,
    messageTemplates,
  } = messageAPI
  const { events } = eventAPI
  const { members } = memberAPI
  const { statuses } = statusAPI
  return {
    errorMessage,
    successMessage,
    messageInputs,
    events,
    members,
    statuses,
    types,
    messageTemplates,
  }
}

export default
connect(mapStateToProps, {
  changeMessageInputValue,
  getMembersAPI,
  getMessageTemplatesAPI,
  getEventsAPI,
})(EditEventCampaignForm)
