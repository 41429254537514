import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects'

import {
  getStatusesAPIFailure,
  getStatusesAPISuccess,
  getStatusAPISuccess,
  getStatusAPIFailure,
  putStatusAPISuccess,
  putStatusAPIFailure,
  postStatusAPISuccess,
  postStatusAPIFailure,
} from './../../actions'
import {
  StatusActionType,
} from './../../types'
import { API } from './../../../utils/api'

const getStatusesRequest = async () => API.get('status')
function* getStatusesAPI() {
  try {
    const request = yield call(getStatusesRequest)
    yield put(getStatusesAPISuccess(request))
  } catch (error) {
    yield put(getStatusesAPIFailure(error))
  }
}

export function* getStatuses() {
  yield takeLatest(StatusActionType.GET_LIST_API, getStatusesAPI)
}

const getStatusRequest = async (id) => API.get(`status/${id}`)
function* getStatusAPI({ payload }) {
  try {
    const request = yield call(getStatusRequest, payload)
    yield put(getStatusAPISuccess(request))
  } catch (error) {
    yield put(getStatusAPIFailure(error))
  }
}

export function* getStatus() {
  yield takeLatest(StatusActionType.GET_BY_ID_API, getStatusAPI)
}

const putStatusRequest = async (data) => API.put(`status/${data.id}`, data)
function* putStatusAPI({ payload }) {
  try {
    const request = yield call(putStatusRequest, payload)
    yield put(putStatusAPISuccess(request))
  } catch (error) {
    yield put(putStatusAPIFailure(error))
  }
}

export function* putStatus() {
  yield takeLatest(StatusActionType.PUT_BY_ID_API, putStatusAPI)
}

const postStatusRequest = async (data) => API.post('status', data)
function* postStatusAPI({ payload }) {
  try {
    const request = yield call(postStatusRequest, payload)
    yield put(postStatusAPISuccess(request))
  } catch (error) {
    yield put(postStatusAPIFailure(error))
  }
}

export function* postStatus() {
  yield takeLatest(StatusActionType.POST_BY_ID_API, postStatusAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getStatuses),
    fork(getStatus),
    fork(putStatus),
    fork(postStatus),
  ])
}
