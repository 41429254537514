import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import {MemberActionType} from '../../types'
import {defaultFormatDate} from '../../../constants/date'
import {Util} from "../../../utils"

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  members: [],
  totalMembers: 0,
  messageReceiverRepliesListColumns: [
    {
      title: 'Member',
      dataIndex: 'member',
      render: (member) => `${member.id} - ${member.name}`,
      sorter: (a, b) => a.member.toString().localeCompare(b.member),
      sortDirections: ['descend', 'ascend'],
    },
     {
      title: 'Campaign',
      dataIndex: 'message',
      render: (data) => {
        return (
          <Link to={`/campaign/${data.campaign.id}`} key={data.campaign.id}>
            {data.campaign.name}
          </Link>
        )
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width: 50,
      render: (message) => {
        if(message && message.message_template_id){
          return (
            <Link to={`/message/${message.message_template_id}`} key={message.message_template_id}>
              {message.message_template_id}
            </Link>
          )
        }
        return null
      },
    },
    {
      title: 'Event',
      dataIndex: 'message',
      width: 50,
      render: (record) => {
        if(record.message && record.message.event_id){
          let id = record.message.event_id
          return(
            <Link to={`/notification/${id}`} key={id}>
              {id}
            </Link>
          )
        }else{
          return null
        }

      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        return status.name
      },
      sorter: (a, b) => a.status.name.toString().localeCompare(b.status.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Replied at',
      dataIndex: 'updated_at',
      render: (date) => {
        return Util.convertDateToPST(date).format(defaultFormatDate)
      },
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },
  ],
  member: null,
  memberInputs: {
    name: '',
    surname: '',
    member_code: '',
    active: true,
    box: {
      id: '',
      mac_address: '',
      active: true,
    },
    is_activated: true,
    deactivated_message: '',
    box_id: { key: 0, label: null, value: 0},
    user: {
      name: '',
      email: '',
      password: '',
      active: true,
    },
    message_media: '',
    message: '',
    message_length: 0,
  },
  id: 0,
  replies: [],
  totalReplies: 0,
  loadingReplies: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case MemberActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case MemberActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.payload.data,
        totalMembers: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case MemberActionType.GET_LIST_API_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      }

    case MemberActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.GET_BY_ID_API_SUCCESS:
      const box_id = !_.isEmpty(action.payload.data.box) ? {
      key: action.payload.data.box_id,
      value: action.payload.data.box_id,
      label: `${action.payload.data.box.name} - ${action.payload.data.box.mac_address}`,
    } : null
      return {
        ...state,
        loading: false,
        member: {
          ...action.payload.data,
          message_length: action.payload.data.message_length / 1000,
          box_id,
        },
        memberInputs: {
          ...action.payload.data,
          message_length: action.payload.data.message_length / 1000,
          box_id,
        },
      }

    case MemberActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.CHANGE_MEMBER_INPUT_VALUE:
      return { ...state, memberInputs: { ...state.memberInputs, [action.payload.property]: action.payload.value } }

    case MemberActionType.CHANGE_MEMBER_USER_INPUT_VALUE:
      return { ...state, memberInputs: { ...state.memberInputs, user: { ...state.memberInputs.user, [action.payload.property]: action.payload.value } } }

    case MemberActionType.SET_ORIGINAL_MEMBER_VALUE:
      return { ...state, memberInputs: action.payload.isNew ? defaultState.memberInputs : state.member }

    case MemberActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case MemberActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `Member with name '${action.payload.data.name}' created successfully!`,
      }

    case MemberActionType.POST_BY_ID_API_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.data,
      }

    case MemberActionType.DETACH_BOX_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.DETACH_BOX_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        members: state.members.map(member => member.id !== action.payload.id ? member : action.payload),
      }

    case MemberActionType.DETACH_BOX_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.DEACTIVATE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.DEACTIVATE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        members: state.members.map(member => member.id !== action.payload.id ? member : action.payload),
      }

    case MemberActionType.DEACTIVATE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        members: state.members.filter(member => member.id !== action.payload.id),
      }

    case MemberActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberActionType.GET_REPLIES_BY_MEMBER_ID_API:
      return {
        ...state,
        loadingReplies: true,
      }

    case MemberActionType.GET_REPLIES_BY_MEMBER_ID_API_SUCCESS:
      return {
        ...state,
        loadingReplies: false,
        replies: action.payload.data,
        successMessage: 'message.success',
        totalReplies: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case MemberActionType.GET_REPLIES_BY_MEMBER_ID_API_FAILURE:
      return { ...state, loadingReplies: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
