import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  resetPasswordAPIFailure,
  resetPasswordAPISuccess,
  sendVerificationCodeAPIFailure,
  sendVerificationCodeAPISuccess,
  signInAPIFailure,
  signInAPISuccess,
} from './../../actions'
import {
  AuthActionType,
} from './../../types'
import { API } from './../../../utils/api'
import { Util } from './../../../utils'

const signInRequest = async (user) => API.post('auth/login', user)
function* signInAPI({ payload }) {
  try {
    const request = yield call(signInRequest, payload)
    yield put(signInAPISuccess(request))
  } catch (error) {
    yield put(signInAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* signIn() {
  yield takeEvery(AuthActionType.SIGN_IN_API, signInAPI)
}

const sendVerificationCodeRequest = async (payload) => API.post('auth/send-verification-code', payload)
function* sendVerificationCodeAPI({ payload }) {
  try {
    const request = yield call(sendVerificationCodeRequest, payload)
    yield put(sendVerificationCodeAPISuccess(request))
  } catch (error) {
    yield put(sendVerificationCodeAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* sendVerificationCode() {
  yield takeEvery(AuthActionType.SEND_VERIFICATION_CODE_API, sendVerificationCodeAPI)
}

const resetPasswordRequest = async (payload) => API.post('auth/reset-password', payload)
function* resetPasswordAPI({ payload }) {
  try {
    const request = yield call(resetPasswordRequest, payload)
    yield put(resetPasswordAPISuccess(request))
  } catch (error) {
    yield put(resetPasswordAPIFailure(Util.getErrorLogin(error)))
  }
}

export function* resetPassword() {
  yield takeEvery(AuthActionType.RESET_PASSWORD_API, resetPasswordAPI)
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(sendVerificationCode),
    fork(resetPassword),
  ])
}
