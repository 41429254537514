import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'antd'

const ButtonStyled = styled(Button)`
  width: ${(props) => props.full ? '95%' : 'auto'};
  margin: 8px ${(props) => props.marginh ? '8px' : '0px'};
`

const ButtonComponent = ({ label, ...rest }) => {
  return (
    <ButtonStyled {...rest}>
      {label}
    </ButtonStyled>
  )
}

ButtonComponent.propTypes = {
  label: PropTypes.any.isRequired,
  full: PropTypes.string,
  marginh: PropTypes.any,
}

ButtonComponent.defaultProps = {
  full: 'false',
  marginh: 'false',
}

export default ButtonComponent
