export const MembersRequestActionType = {
    SET_PROPERTY_VALUE: 'MESSAGE_REQUEST.SET_PROPERTY_VALUE',
    GET_LIST_API: 'MEMBERS_REQUEST.GET_LIST_API',
    GET_LIST_API_SUCCESS: 'MEMBERS_REQUEST.GET_LIST_API_SUCCESS',
    GET_LIST_API_FAILURE: 'MEMBERS_REQUEST.GET_LIST_API_FAILURE',
    PUT_BY_ID_API: 'MEMBERS_REQUEST.PUT_BY_ID_API',
    PUT_BY_ID_API_FAILURE: 'MEMBERS_REQUEST.PUT_BY_ID_API_FAILURE',
    PUT_BY_ID_API_SUCCESS: 'MEMBERS_REQUEST.PUT_BY_ID_API_SUCCESS',
    DELETE_BY_ID_API: 'MEMBERS_REQUEST.DELETE_BY_ID_API',
    DELETE_BY_ID_API_FAILURE: 'MEMBERS_REQUEST.DELETE_BY_ID_API_FAILURE',
    DELETE_BY_ID_API_SUCCESS: 'MEMBERS_REQUEST.DELETE_BY_ID_API_SUCCESS',
    GET_CONTACT_ME_LIST_API: 'MEMBERS_REQUEST.GET_CONTACT_ME_LIST_API',
    GET_CONTACT_ME_LIST_API_SUCCESS: 'MEMBERS_REQUEST.GET_CONTACT_ME_LIST_API_SUCCESS',
    GET_CONTACT_ME_LIST_API_FAILURE: 'MEMBERS_REQUEST.GET_CONTACT_ME_LIST_API_FAILURE',
    PUT_CONTACT_ME_BY_ID_API: 'MEMBERS_REQUEST.PUT_CONTACT_ME_BY_ID_API',
    PUT_CONTACT_ME_BY_ID_API_FAILURE: 'MEMBERS_REQUEST.PUT_CONTACT_ME_BY_ID_API_FAILURE',
    PUT_CONTACT_ME_BY_ID_API_SUCCESS: 'MEMBERS_REQUEST.PUT_CONTACT_ME_BY_ID_API_SUCCESS',
    DELETE_CONTACT_ME_BY_ID_API: 'MEMBERS_REQUEST.DELETE_CONTACT_ME_BY_ID_API',
    DELETE_CONTACT_ME_BY_ID_API_FAILURE: 'MEMBERS_REQUEST.DELETE_CONTACT_ME_BY_ID_API_FAILURE',
    DELETE_CONTACT_ME_BY_ID_API_SUCCESS: 'MEMBERS_REQUEST.DELETE_CONTACT_ME_BY_ID_API_SUCCESS',
    GET_MESSAGE_REQUEST_META_DATA_API: 'MEMBERS_REQUEST.GET_MESSAGE_REQUEST_META_DATA_API',
    GET_MESSAGE_REQUEST_META_DATA_API_FAILURE: 'MEMBERS_REQUEST.GET_MESSAGE_REQUEST_META_DATA_API_FAILURE',
    GET_MESSAGE_REQUEST_META_DATA_API_SUCCESS: 'MEMBERS_REQUEST.GET_MESSAGE_REQUEST_META_DATA_API_SUCCESS',
}
