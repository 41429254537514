import { BoxActionType } from './../../types'

export const getBoxesAPISuccess = (response) => ({
  type: BoxActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getBoxesAPIFailure = (
  error,
) => ({
  type: BoxActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getBoxesAPI = (queryParams) => ({
  type: BoxActionType.GET_LIST_API,
  params: queryParams,
})

export const getBoxAPISuccess = (response) => ({
  type: BoxActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getBoxAPIFailure = (
  error,
) => ({
  type: BoxActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getBoxAPI = (
  id,
) => ({
  type: BoxActionType.GET_BY_ID_API,
  payload: id,
})

export const changeBoxInputValue = (
  property, value,
) => ({
  type: BoxActionType.CHANGE_BOX_INPUT_VALUE,
  payload: { property, value },
})

export const setBoxPropertyValue = (
  property, value,
) => ({
  type: BoxActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putBoxAPISuccess = (response) => ({
  type: BoxActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putBoxAPIFailure = (
  error,
) => ({
  type: BoxActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putBoxAPI = (
  data,
) => ({
  type: BoxActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalBoxValue = (isNew) => ({
  type: BoxActionType.SET_ORIGINAL_BOX_VALUE,
  payload: { isNew },
})

export const postBoxAPISuccess = (response) => ({
  type: BoxActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postBoxAPIFailure = (
  error,
) => ({
  type: BoxActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postBoxAPI = (
  data,
) => ({
  type: BoxActionType.POST_BY_ID_API,
  payload: data,
})

export const getAvailableBoxesAPI = (
  ) => ({
    type: BoxActionType.GET_AVAILABLES_API,
  })

export const getAvailableBoxesAPISuccess = (response) => ({
  type: BoxActionType.GET_AVAILABLES_API_SUCCESS,
  payload: response,
})

export const getAvailableBoxesAPIFailure = (
  error,
) => ({
  type: BoxActionType.GET_AVAILABLES_API_FAILURE,
  payload: error,
})

export const patchBoxAPISuccess = (response) => ({
  type: BoxActionType.PATCH_BY_ID_API_SUCCESS,
  payload: response,
})

export const patchBoxAPIFailure = (
  error,
) => ({
  type: BoxActionType.PATCH_BY_ID_API_FAILURE,
  payload: error,
})

export const patchBoxAPI = (
  data,
) => ({
  type: BoxActionType.PATCH_BY_ID_API,
  payload: data,
})

export const deleteBoxAPISuccess = (response) => ({
  type: BoxActionType.DELETE_BY_ID_API_SUCCESS,
  payload: response,
})

export const deleteBoxAPIFailure = (
    error,
) => ({
  type: BoxActionType.DELETE_BY_ID_API_FAILURE,
  payload: error,
})

export const deleteBoxAPI = (
    data,
) => ({
  type: BoxActionType.DELETE_BY_ID_API,
  payload: data,
})
