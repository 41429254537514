import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import {Table, Button, message as AntMessage} from 'antd'
import { LayoutAdmin } from './../../../components'
import {getMessageTemplatesAPI, setMessageTemplatePropertyValue} from '../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"
import {Util} from "../../../utils"

const MessageTemplateListPage = ({
  messageTemplates, loading, getMessageTemplatesAPI, totalMessageTemplates, setMessageTemplatePropertyValue, successMessage,
}) => {

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const history = useHistory()
  const messageTemplateListColumns =  [
    {
      title: 'View',
      dataIndex: 'id',
      width: 50,
      key: 'id',
      // sorter: {
      //   compare: (a, b, sortDirection) => {
      //     setOrderKey('id')
      //     if(sortDirection==='descend') setOrderDirection('desc')
      //     if(sortDirection==='ascend') setOrderDirection('asc')
      //   },
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (id) => (
          <Link to={`/message/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('subject')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (subject) => subject && String(subject).substring(0,299),
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('text')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (text) => text && String(text).substring(0,299),
    },
    {
      title: 'Media',
      dataIndex: 'media',
      width: 200,
      render: (media) => {
        return media !== null && Util.renderMedia(media)
      },
    },
  ]

  useEffect(() => {
    getMessageTemplatesAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getMessageTemplatesAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setMessageTemplatePropertyValue('successMessage', null)
    }
  }, [setMessageTemplatePropertyValue, successMessage])

  const updateTableDimensions = (updatedPageNumber, updatedPageSize) => {
    if(updatedPageNumber!==pageNumber) {
      setPageNumber(updatedPageNumber)
    }
    if(updatedPageSize!==pageSize) {
      setPageSize(updatedPageSize)
    }
  }

  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/message/new')}>New</Button>
      <Table
          dataSource={messageTemplates}
          columns={messageTemplateListColumns}
          pagination={{ total: totalMessageTemplates, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading}
          rowKey="id"
      />
    </LayoutAdmin>
  )
}

MessageTemplateListPage.propTypes = {
  getMessageTemplatesAPI: PropTypes.func,
  messageTemplates: PropTypes.array,
  messageTemplateListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ messageTemplateAPI }) => {
  const {
    messageTemplates, loading, totalMessageTemplates, successMessage,
  } = messageTemplateAPI
  return {
    messageTemplates,
    totalMessageTemplates,
    loading,
    successMessage,
  }
}

export default
connect(mapStateToProps, {
  getMessageTemplatesAPI,
  setMessageTemplatePropertyValue,
})(MessageTemplateListPage)
