import {EventActionType} from './../../types'
import moment from "moment"

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  events: [],
  totalEvents: 0,
  event: null,
  eventInputs: {
    headline: '',
    subheadline: '',
    location:'',
    description:'',
    media: null,
    start_date: moment(),
    end_date: moment(),
    status_id: { key: 0, label: null, value: 0},
    media_detail: null,
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case EventActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case EventActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case EventActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.data,
        totalEvents: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case EventActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case EventActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case EventActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        event: {
          ...action.payload.data,
        },
        eventInputs: {
          ...action.payload.data,
          status_id: {
            key: action.payload.data.status_id,
            value: action.payload.data.status_id,
            label: action.payload.data.status && `${action.payload.data.status.name}`,
          },
        },
      }

    case EventActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case EventActionType.CHANGE_EVENT_INPUT_VALUE:
      return { ...state, eventInputs: { ...state.eventInputs, [action.payload.property]: action.payload.value } }

    case EventActionType.SET_ORIGINAL_EVENT_VALUE:
      return { ...state, eventInputs: action.payload.isNew ? defaultState.eventInputs : state.event }

    case EventActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case EventActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case EventActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case EventActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case EventActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `Event with name '${action.payload.data.headline}' created successfully!`,
      }

    case EventActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
