import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getSplashAPI, putSplashAPI, setOriginalSplashValue, postSplashAPI, setSplashPropertyValue,
} from './../../../effects/actions'
import { SplashForm } from './../../../forms'
import { message } from 'antd'
import { Util } from './../../../utils'

const SplashFormPage = ({
  splashInputs, successMessage, errorMessage, setOriginalSplashValue,
  getSplashAPI, setSplashPropertyValue, postSplashAPI, putSplashAPI, loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const paramId = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (paramId !== 'new') {
      getSplashAPI(paramId)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalSplashValue(true)
    }
  }, [getSplashAPI, paramId, setOriginalSplashValue])

  // if its a deleted record, redirect to the listing page
  useEffect(() => {
    if (paramId !== 'new' && !splashInputs.active) {
      history.push('/splash')
    }
  })


  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage && isNew) {
      history.push({pathname: `/splash`})
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setSplashPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setSplashPropertyValue])

  const onSave = () => {
    if (!isNew) {
      putSplashAPI(splashInputs)
    } else {
      postSplashAPI(splashInputs)
    }
  }

  const onCancel = () => {
    setOriginalSplashValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm
          title="Splash"
          onSave={onSave}
          onCancel={onCancel}
          viewMode={viewMode}
          setViewMode={setViewMode}
          isNew={isNew}
      />
      <SplashForm viewMode={viewMode} isNew={isNew} />
    </LayoutAdmin>
  )
}

SplashFormPage.propTypes = {
  getSplashAPI: PropTypes.func,
  splashInputs: PropTypes.any,
  loading: PropTypes.bool,
  putSplashAPI: PropTypes.func,
  setOriginalSplashValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.string,
  setSplashPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ splashAPI }) => {
  const {
    splashInputs, loading,
    successMessage, id, errorMessage,
  } = splashAPI
  return {
    splashInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getSplashAPI,
  putSplashAPI,
  setOriginalSplashValue,
  postSplashAPI,
  setSplashPropertyValue,
})(SplashFormPage)
