import {MembersRequestActionType} from '../../types'

export const setMembersRequestAPIPropertyValue = (payload) => ({
    type: MembersRequestActionType.SET_PROPERTY_VALUE,
    payload,
})
export const getMembersRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.GET_LIST_API_SUCCESS,
    payload: response,
})

export const getMembersRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.GET_LIST_API_FAILURE,
    payload: error,
})

export const getMembersRequestAPI = (queryParams) => ({
    type: MembersRequestActionType.GET_LIST_API,
    payload: queryParams,
})

export const putMembersRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.PUT_BY_ID_API_SUCCESS,
    payload: response,
})

export const putMembersRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.PUT_BY_ID_API_FAILURE,
    payload: error,
})

export const putMembersRequestAPI = (
    data,
) => ({
    type: MembersRequestActionType.PUT_BY_ID_API,
    payload: data,
})

export const deleteMembersRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.DELETE_BY_ID_API_SUCCESS,
    payload: response,
})

export const deleteMembersRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.DELETE_BY_ID_API_FAILURE,
    payload: error,
})

export const deleteMembersRequestAPI = (
    data,
) => ({
    type: MembersRequestActionType.DELETE_BY_ID_API,
    payload: data,
})

export const getContactMeRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.GET_CONTACT_ME_LIST_API_SUCCESS,
    payload: response,
})

export const getContactMeRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.GET_CONTACT_ME_LIST_API_FAILURE,
    payload: error,
})

export const getContactMeRequestAPI = (queryParams) => ({
    type: MembersRequestActionType.GET_CONTACT_ME_LIST_API,
    payload: queryParams,
})

export const putContactMeRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API_SUCCESS,
    payload: response,
})

export const putContactMeRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API_FAILURE,
    payload: error,
})

export const putContactMeRequestAPI = (
    data,
) => ({
    type: MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API,
    payload: data,
})

export const deleteContactMeRequestAPISuccess = (response) => ({
    type: MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API_SUCCESS,
    payload: response,
})

export const deleteContactMeRequestAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API_FAILURE,
    payload: error,
})

export const deleteContactMeRequestAPI = (
    data,
) => ({
    type: MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API,
    payload: data,
})


export const getMemberRequestsMetaDataAPISuccess = (response) => ({
    type: MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API_SUCCESS,
    payload: response,
})

export const getMemberRequestsMetaDataAPIFailure = (
    error,
) => ({
    type: MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API_FAILURE,
    payload: error,
})

export const getMemberRequestsMetaDataAPI = (
    data,
) => ({
    type: MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API,
    payload: data,
})
