import { StatusActionType } from './../../types'

export const getStatusesAPISuccess = (response) => ({
  type: StatusActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getStatusesAPIFailure = (
  error,
) => ({
  type: StatusActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getStatusesAPI = (
) => ({
  type: StatusActionType.GET_LIST_API,
})

export const getStatusAPISuccess = (response) => ({
  type: StatusActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getStatusAPIFailure = (
  error,
) => ({
  type: StatusActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getStatusAPI = (
  id,
) => ({
  type: StatusActionType.GET_BY_ID_API,
  payload: id,
})

export const changeStatusInputValue = (
  property, value,
) => ({
  type: StatusActionType.CHANGE_STATUS_INPUT_VALUE,
  payload: { property, value },
})

export const setStatusPropertyValue = (
  property, value,
) => ({
  type: StatusActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putStatusAPISuccess = (response) => ({
  type: StatusActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putStatusAPIFailure = (
  error,
) => ({
  type: StatusActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putStatusAPI = (
  data,
) => ({
  type: StatusActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalStatusValue = (isNew) => ({
  type: StatusActionType.SET_ORIGINAL_STATUS_VALUE,
  payload: { isNew },
})

export const postStatusAPISuccess = (response) => ({
  type: StatusActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postStatusAPIFailure = (
  error,
) => ({
  type: StatusActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postStatusAPI = (
  data,
) => ({
  type: StatusActionType.POST_BY_ID_API,
  payload: data,
})
