import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MaskedInput from 'antd-mask-input'
import {
  Row, Col, Form, Input,
} from 'antd'
import { changeBoxInputValue } from './../../effects/actions'

const BoxForm = ({
  boxInputs, changeBoxInputValue, viewMode, id,
}) => {
  const onChangeInput = (e) => {
    const { name, value } = e.target
    changeBoxInputValue(name, value)
  }
  return (
    <Row gutter={[12, 0]}>
      {id !== "new" && <Col span={10}>
        <Form.Item label="Name" labelCol={{ span: 24 }}>
          <Input 
            size="large" 
            type="text" 
            name="name"
            disabled={true}
            placeholder="Name" 
            onChange={onChangeInput} 
            value={boxInputs.name}
          />
        </Form.Item>
      </Col>}
      <Col span={10}>
        <Form.Item label="Mac Address" labelCol={{ span: 24 }}>
          <MaskedInput 
            mask="**:**:**:**:**:**"
            size="large" 
            type="text" 
            name="mac_address"
            disabled={viewMode}
            placeholder="Mac Address" 
            onChange={onChangeInput} 
            value={boxInputs.mac_address}
          />
        </Form.Item>
      </Col>
    </Row>

  )
}

BoxForm.propTypes = {
  changeBoxInputValue: PropTypes.func,
  boxInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  id: PropTypes.any,
}

BoxForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ boxAPI }) => {
  const {
    errorMessage, successMessage, boxInputs,
  } = boxAPI
  return {
    errorMessage,
    successMessage,
    boxInputs,
  }
}

export default
connect(mapStateToProps, {
  changeBoxInputValue,
})(BoxForm)
