import React from 'react'
import PropTypes from 'prop-types'

import {connect} from 'react-redux'
import {Col, Form, Input, Row} from 'antd'
import {changeMessageTemplateInputValue} from '../../effects/actions'
import UploadMedia from "../../components/upload-media"

const { Item } = Form

const MessageTemplateForm = ({
  messageTemplateInputs, changeMessageTemplateInputValue, viewMode,
}) => {

  return (
    <Row gutter={[12, 0]}>
      <Col span={24}>
        <Item label="Subject" labelCol={{ span: 24 }}>
          <Input
            name="subject"
            type="text"
            placeholder="Subject"
            size="large"
            onChange={(event) => changeMessageTemplateInputValue('subject', event.target.value)}
            value={messageTemplateInputs.subject}
            disabled={viewMode}
          />
        </Item>
      </Col>
      <Col span={20}>
        <Item label="Text" labelCol={{ span: 24 }}>
          <Input.TextArea
            name="text"
            type="text"
            placeholder="Text"
            size="large"
            onChange={(event) => changeMessageTemplateInputValue('text', event.target.value)}
            value={messageTemplateInputs.text}
            disabled={viewMode}
          />
        </Item>
      </Col>
      <Col span={4}>
        <Item label="Media" labelCol={{ span: 24 }}>
            <UploadMedia
                media={messageTemplateInputs.media}
                changeHandler={(value) => changeMessageTemplateInputValue('media', value)}
                viewMode={viewMode}
            />
        </Item>
      </Col>
    </Row>

  )
}

MessageTemplateForm.propTypes = {
  changeMessageTemplateInputValue: PropTypes.func,
  messageTemplateInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  statuses: PropTypes.array,
  isNew: PropTypes.bool,
}

MessageTemplateForm.defaultProps = {
  viewMode: false,
  isNew: false,
}
const mapStateToProps = ({ messageTemplateAPI }) => {
  const {
    errorMessage, successMessage, messageTemplateInputs,
  } = messageTemplateAPI
  return {
    errorMessage,
    successMessage,
    messageTemplateInputs,
  }
}

export default
connect(mapStateToProps, {
  changeMessageTemplateInputValue,
})(MessageTemplateForm)
