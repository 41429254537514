import { EventActionType } from './../../types'

export const getEventsAPISuccess = (response) => ({
  type: EventActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getEventsAPIFailure = (
  error,
) => ({
  type: EventActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getEventsAPI = (queryParams) => ({
  type: EventActionType.GET_LIST_API,
  params: queryParams,
})

export const getEventAPISuccess = (response) => ({
  type: EventActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getEventAPIFailure = (
  error,
) => ({
  type: EventActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getEventAPI = (
  id,
) => ({
  type: EventActionType.GET_BY_ID_API,
  payload: id,
})

export const changeEventInputValue = (
  property, value,
) => ({
  type: EventActionType.CHANGE_EVENT_INPUT_VALUE,
  payload: { property, value },
})

export const setEventPropertyValue = (
  property, value,
) => ({
  type: EventActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putEventAPISuccess = (response) => ({
  type: EventActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putEventAPIFailure = (
  error,
) => ({
  type: EventActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putEventAPI = (
  data,
) => ({
  type: EventActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalEventValue = (isNew) => ({
  type: EventActionType.SET_ORIGINAL_EVENT_VALUE,
  payload: { isNew },
})

export const postEventAPISuccess = (response) => ({
  type: EventActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postEventAPIFailure = (
  error,
) => ({
  type: EventActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postEventAPI = (
  data,
) => ({
  type: EventActionType.POST_BY_ID_API,
  payload: data,
})
