import {Button} from "../../index"
import {DeleteOutlined, EditOutlined} from "@ant-design/icons"
import {
    getEventsAPI, getMessageAPI, getMessageEventsAPI, patchMessageInfoAPI, postMessageAPI,
    removeEventFromMessageAPI, setMessageDataUpdated, setMessagePropertyValue, setOriginalMessageValue,
} from "../../../effects/actions"
import React, {useEffect, useState} from "react"
import {Col, Modal, Row, Table, message} from "antd"
import PropTypes from "prop-types"
import {CampaignEventForm, EditEventCampaignForm} from "../../../forms"
import {connect} from "react-redux"
import {Util} from "../../../utils"
import EventModal from "../../../modals/event"
import styled from "styled-components"

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`
const CampaignEventsTable = ({
    id, loading, successMessage, errorMessage, messageInputs, messageEvents, totalMessageEvents, getMessageEventsAPI,
    setOriginalMessageValue, postMessageAPI, patchMessageInfoAPI, setMessagePropertyValue, getMessageAPI,
    removeEventFromMessageAPI, messageDataUpdated, setMessageDataUpdated,
}) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [isAddNewModalVisible, setIsAddNewModalVisible] = useState(false)
    const [isEditModalVisible, setIsEditModalVisible] = useState(false)

    const [selectedEvent, setSelectedEvent] = useState({})
    const [showDeleteMessageEventModal, setShowDeleteMessageEventModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)

    const eventsColumns = [
        {
        title: 'Event',
        width: 50,
        render: (record) => {
            if (record && record.event_id) {
                return (
                    <StyledLink onClick={() => handleEventCellClick(record.event)}>
                        {record.event_id} - {record.event.headline}
                    </StyledLink>
                )
            } else {
                return null
            }

        },
    }, {
        title: 'Edit',
        width: 20,
        render: (record) => {
            if (record && record.event_id) {
                return (
                    <Button
                        label={<EditOutlined/>}
                        onClick={() => {
                            getMessageAPI(record.id)
                            setIsEditModalVisible(true)
                        }}
                    />
                )
            } else {
                return null
            }

        },
    }, {
        title: 'Remove',
        width: 20,
        render: (record) => {
            if (record && record.event_id) {
                let id = record.event_id
                return <Button
                            label={<DeleteOutlined/>}
                            onClick={() => {
                                setShowDeleteMessageEventModal(true)
                                setSelectedEvent(record)
                            }}
                            key={id}
                        />
            } else {
                return null
            }
        },
    }]

    const handleEventCellClick = (event) => {
        setShowEventModal(true)
        setSelectedEvent(event)
    }

    const handleEventDoneCallback = () => {
        setShowEventModal(false)
        setSelectedEvent({})
    }

    const deleteMessageEvent = () => {
        removeEventFromMessageAPI({
            id: selectedEvent.id,
            campaign_id: selectedEvent.campaign_id,
        })
        setSelectedEvent({})
        setShowDeleteMessageEventModal(false)
    }

    const cancelMessageEventDelete = () => {
        setSelectedEvent({})
        setShowDeleteMessageEventModal(false)
    }

    const onSave = (isNew = true) => {
        if(isNew){
            postMessageAPI({...messageInputs, campaign_id: id, is_notification: false })
        }else{
            patchMessageInfoAPI({...messageInputs, campaign_id: id })
        }

    }

    const onCancel = () => {
        setOriginalMessageValue(true)
    }

    const updateTableDimensions = (pageNum, pageS) => {
        if(pageNum!==pageNumber) {
            setPageNumber(pageNum)
        }
        if(pageS!==pageSize) {
            setPageSize(pageS)
        }
    }

    useEffect(() => {
        if(id !== "new") {
            getMessageEventsAPI({limit: pageSize, page: pageNumber, id})
        }
    },[getMessageEventsAPI, id, pageSize, pageNumber])

    useEffect(() => {
        if (messageDataUpdated) {
            setMessageDataUpdated(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageDataUpdated])

    useEffect(() => {
        if (successMessage) {
            setMessagePropertyValue('successMessage', null)
            message.success(`Events list updated successfully!`)
            getMessageEventsAPI({limit: pageSize, page: pageNumber, id})
            setMessagePropertyValue('successMessage', null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMessagePropertyValue, successMessage])

    useEffect(() => {
        if (errorMessage) {
            message.error(Util.renderMessage(errorMessage))
            setMessagePropertyValue('errorMessage', null)
        }
    }, [errorMessage, setMessagePropertyValue])

    return (
        <>
            <Row justify="end">
                <Col span={6}>
                    <Button marginh={String(true)} type="primary" onClick={() => setIsAddNewModalVisible(true)} label="Add New Event"/>
                </Col>
            </Row>
            <Table
                dataSource={messageEvents}
                columns={eventsColumns}
                loading={loading}
                rowKey="id"
                pagination={{ total: totalMessageEvents, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
            />
            <Modal
                visible={isEditModalVisible}
                onOk={() => {
                    onSave( false)
                    setIsEditModalVisible(false)}
                }
                onCancel={() =>{
                    onCancel()
                    setIsEditModalVisible(false)
                }}
            >
                <EditEventCampaignForm />
            </Modal>
            <Modal
                visible={isAddNewModalVisible}
                onOk={() => {
                    onSave()
                    setIsAddNewModalVisible(false)}
                }
                onCancel={() => {
                    onCancel()
                    setIsAddNewModalVisible(false)
                }}
                width={1000}
                footer={[
                    <Row justify="end">
                        <Col>
                            <Button label="Cancel" onClick={() => {
                                onCancel()
                                setIsAddNewModalVisible(false)
                            }} />
                        </Col>
                        <Col>
                            <Button label="Send" type="primary" onClick={() => {
                                onSave()
                                setIsAddNewModalVisible(false)
                            }} />
                        </Col>
                    </Row>,
                ]}
            >
                <CampaignEventForm viewMode={false} isNew={true}/>
            </Modal>
            <Modal
                title={<b>Are you sure?</b>}
                visible={showDeleteMessageEventModal}
                onOk={deleteMessageEvent}
                onCancel={cancelMessageEventDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >
                Delete event: {selectedEvent.id} - {selectedEvent.headline} from this campaign.
            </Modal>
            <EventModal
                visible={showEventModal}
                doneCallback={handleEventDoneCallback}
                eventData={selectedEvent}
            />
        </>
    )
}
CampaignEventsTable.propTypes = {
    messageEvents: PropTypes.array,
    id: PropTypes.string,
}
const mapStateToProps = ({ eventAPI, messageAPI }) => {
    const { events} = eventAPI
    const { loading, messages, messageInputs, messageEvents, totalMessageEvents, messageDataUpdated, errorMessage, successMessage } = messageAPI

    return {
        errorMessage,
        successMessage,
        messageDataUpdated,
        events,
        loading,
        messages,
        messageInputs,
        messageEvents,
        totalMessageEvents,
    }
}

export default connect(mapStateToProps, {
    getEventsAPI,
    setOriginalMessageValue,
    postMessageAPI,
    getMessageEventsAPI,
    patchMessageInfoAPI,
    removeEventFromMessageAPI,
    setMessageDataUpdated,
    getMessageAPI,
    setMessagePropertyValue,
})(CampaignEventsTable)
