import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useHistory, useParams} from 'react-router-dom'
import {ButtonForm, LayoutAdmin} from './../../../components'
import {
    getBoxLightStateAPI,
    putBoxLightStateAPI,
    setBoxLightStatePropertyValue,
    setOriginalBoxLightStateValue,
} from '../../../effects/actions'
import {message} from 'antd'
import {Util} from '../../../utils'
import BoxLightStateForm from "../../../forms/box-light-state"

const BoxLightStateFormPage = ({boxLightStateInputs, successMessage, errorMessage, getBoxLightStateAPI, setOriginalBoxLightStateValue, setBoxLightStatePropertyValue, id, putBoxLightStateAPI, loading}) => {
    const [viewMode, setViewMode] = useState(true)

    const idParam = useParams().id
    const history = useHistory()

    useEffect(() => {
        getBoxLightStateAPI(idParam)
        setViewMode(true)
    }, [getBoxLightStateAPI, idParam, setOriginalBoxLightStateValue])

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (successMessage) {
            history.push({
                pathname: `/box-light-state`,
            })
        }
    }, [history, successMessage])

    useEffect(() => {
        if (errorMessage !== null) {
            message.error(Util.renderMessage(errorMessage))
            setBoxLightStatePropertyValue('errorMessage', null)
            setViewMode(false)
        }
    }, [errorMessage, setBoxLightStatePropertyValue])

    const onSave = () => {
        // Basic Error checking
        putBoxLightStateAPI(boxLightStateInputs)
    }

    const onCancel = () => {
        setOriginalBoxLightStateValue()
    }

    return (
        <LayoutAdmin loading={loading}>
            <ButtonForm title="Box Light State" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} />
            <BoxLightStateForm viewMode={viewMode} id={id}/>
        </LayoutAdmin>
    )
}

BoxLightStateFormPage.propTypes = {
    getBoxLightStateAPI: PropTypes.func,
    boxLightStateInputs: PropTypes.any,
    loading: PropTypes.bool,
    putBoxLightStateAPI: PropTypes.func,
    setOriginalBoxLightStateValue: PropTypes.func,
    successMessage: PropTypes.string,
    id: PropTypes.number,
    errorMessage: PropTypes.any,
    setBoxLightStatePropertyValue: PropTypes.func,
}

const mapStateToProps = ({ boxLightStateAPI }) => {
    const {
        boxLightStateInputs, loading,
        successMessage, id, errorMessage,
    } = boxLightStateAPI
    return {
        boxLightStateInputs,
        loading,
        successMessage,
        id,
        errorMessage,
    }
}

export default
connect(mapStateToProps, {
    getBoxLightStateAPI,
    putBoxLightStateAPI,
    setOriginalBoxLightStateValue,
    setBoxLightStatePropertyValue,
})(BoxLightStateFormPage)
