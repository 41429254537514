export const menuItems = [
  {
    hasSubItems: false,
    title: 'Admins',
    breadcrumbName: 'Admins',
    path: '/admin',
    key: 'admin',
  },
  {
    hasSubItems: false,
    title: 'Boxes',
    breadcrumbName: 'Boxes',
    path: '/box',
    key: 'box',
  },
  {
    hasSubItems: false,
    title: 'Members',
    breadcrumbName: 'Members',
    path: '/member',
    key: 'member',
  },
  {
    hasSubItems: false,
    title: 'Campaigns',
    breadcrumbName: 'Campaigns',
    path: '/campaign',
    key: 'campaign',
  },
  {
    hasSubItems: true,
    title: 'Templates',
    breadcrumbName: 'Templates',
    key: 'templates',
    children: [
      {
        title: 'Messages',
        breadcrumbName: 'Messages',
        path: '/message',
        key: 'message',
      },
      {
        hasSubItems: false,
        title: 'Events',
        breadcrumbName: 'Events',
        path: '/event',
        key: 'event',
      },
    ],
  },
  {
    hasSubItems: false,
    title: 'Notifications',
    breadcrumbName: 'Notifications',
    path: '/notification',
    key: 'notification',
  },
  {
    hasSubItems: false,
    title: 'Members Request',
    breadcrumbName: 'Members Request',
    path: '/members-request',
    key: 'members-request',
  },
  {
    hasSubItems: false,
    title: 'Splash',
    breadcrumbName: 'Splash',
    path: '/splash',
    key: 'splash',
  },
  {
    hasSubItems: false,
    title: 'Led Sequences',
    breadcrumbName: 'led-sequence',
    path: '/led-sequence',
    key: 'led-sequence',
  },
  {
    hasSubItems: false,
    title: 'Box Light States',
    breadcrumbName: 'box-light-state',
    path: '/box-light-state',
    key: 'box-light-state',
  },
]
