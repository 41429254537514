import { LedSequenceActionType } from '../../types'

export const getLedSequenceListAPISuccess = (response) => ({
  type: LedSequenceActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getLedSequenceListAPIFailure = (
  error,
) => ({
  type: LedSequenceActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getLedSequenceListAPI = (queryParams) => ({
  type: LedSequenceActionType.GET_LIST_API,
  params: queryParams,
})

export const getLedSequenceAPISuccess = (response) => ({
  type: LedSequenceActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getLedSequenceAPIFailure = (
  error,
) => ({
  type: LedSequenceActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getLedSequenceAPI = (
  id,
) => ({
  type: LedSequenceActionType.GET_BY_ID_API,
  payload: id,
})

export const changeLedSequenceInputValue = (
  property, value,
) => ({
  type: LedSequenceActionType.CHANGE_LED_SEQUENCE_INPUT_VALUE,
  payload: { property, value },
})

export const setLedSequencePropertyValue = (
  property, value,
) => ({
  type: LedSequenceActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putLedSequenceAPISuccess = (response) => ({
  type: LedSequenceActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putLedSequenceAPIFailure = (
  error,
) => ({
  type: LedSequenceActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putLedSequenceAPI = (
  data,
) => ({
  type: LedSequenceActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalLedSequenceValue = (isNew) => ({
  type: LedSequenceActionType.SET_ORIGINAL_LED_SEQUENCE_VALUE,
  payload: { isNew },
})

export const postLedSequenceAPISuccess = (response) => ({
  type: LedSequenceActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postLedSequenceAPIFailure = (
  error,
) => ({
  type: LedSequenceActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postLedSequenceAPI = (
  data,
) => ({
  type: LedSequenceActionType.POST_BY_ID_API,
  payload: data,
})

export const deleteLedSequenceAPISuccess = (response) => ({
  type: LedSequenceActionType.DELETE_BY_ID_API_SUCCESS,
  payload: response,
})

export const deleteLedSequenceAPIFailure = (
    error,
) => ({
  type: LedSequenceActionType.DELETE_BY_ID_API_FAILURE,
  payload: error,
})

export const deleteLedSequenceAPI = (
    data,
) => ({
  type: LedSequenceActionType.DELETE_BY_ID_API,
  payload: data,
})
