import {
  all,
} from 'redux-saga/effects'

import authSagas from './api/auth'
import memberSagas from './api/member'
import boxSagas from './api/box'
import eventSagas from './api/event'
import campaignSagas from './api/campaign'
import messageSagas from './api/message'
import messageTemplateSagas from './api/message-template'
import statusSagas from './api/status'
import splashSagas from './api/splash'
import membersRequestSagas from './api/members-request'
import ledSequenceSagas from './api/led-sequence'
import adminSagas from './api/admin'
import memberSplashSagas from './api/member-splash'
import boxLightStateSagas from './api/box-light-state'

export default function* rootSaga() {
  yield all([
    authSagas(),
    memberSagas(),
    boxSagas(),
    eventSagas(),
    campaignSagas(),
    messageSagas(),
    messageTemplateSagas(),
    statusSagas(),
    splashSagas(),
    membersRequestSagas(),
    ledSequenceSagas(),
    adminSagas(),
    memberSplashSagas(),
    boxLightStateSagas(),
  ])
}
