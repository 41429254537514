import {BoxLightStateActionType} from '../../types'

export const getBoxLightStatesAPISuccess = (response) => ({
  type: BoxLightStateActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getBoxLightStatesAPIFailure = (
  error,
) => ({
  type: BoxLightStateActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getBoxLightStatesAPI = () => ({
  type: BoxLightStateActionType.GET_LIST_API,
})

export const changeBoxLightStateInputValue = (
  property, value,
) => ({
  type: BoxLightStateActionType.CHANGE_BOX_LIGHT_STATE_INPUT_VALUE,
  payload: { property, value },
})

export const setBoxLightStatePropertyValue = (
  property, value,
) => ({
  type: BoxLightStateActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const getBoxLightStateAPISuccess = (response) => ({
  type: BoxLightStateActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getBoxLightStateAPIFailure = (
    error,
) => ({
  type: BoxLightStateActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getBoxLightStateAPI = (
    id,
) => ({
  type: BoxLightStateActionType.GET_BY_ID_API,
  payload: id,
})


export const putBoxLightStateAPISuccess = (response) => ({
  type: BoxLightStateActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putBoxLightStateAPIFailure = (
  error,
) => ({
  type: BoxLightStateActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putBoxLightStateAPI = (
  data,
) => ({
  type: BoxLightStateActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalBoxLightStateValue = (isNew) => ({
  type: BoxLightStateActionType.SET_ORIGINAL_BOX_LIGHT_STATE_VALUE,
  payload: { isNew },
})
