import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ContainerStyled = styled.div`
    padding: 16px
`

const ContainerComponent = ({ children, ...props }) => {
  return (
    <ContainerStyled {...props}>
      {children}
    </ContainerStyled>
  )
}

ContainerComponent.propTypes = {
  children: PropTypes.any,
}

ContainerComponent.defaultProps = {
}

export default ContainerComponent
