import React from 'react'
import { Container } from './../../components'
import { SignInForm } from './../../forms'

const SignInPage = () => {
  return (
    <Container>
      <SignInForm />
    </Container>
  )
}

export default SignInPage
