export const BoxLightStateActionType = {
  SET_PROPERTY_VALUE: 'BOX_LIGHT_STATE.SET_PROPERTY_VALUE',
  GET_LIST_API: 'BOX_LIGHT_STATE.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'BOX_LIGHT_STATE.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'BOX_LIGHT_STATE.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'BOX_LIGHT_STATE.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'BOX_LIGHT_STATE.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'BOX_LIGHT_STATE.GET_BY_ID_API_FAILURE',
  CHANGE_BOX_LIGHT_STATE_INPUT_VALUE: 'BOX_LIGHT_STATE.CHANGE_BOX_LIGHT_STATE_INPUT_VALUE',
  PUT_BY_ID_API: 'BOX_LIGHT_STATE.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'BOX_LIGHT_STATE.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'BOX_LIGHT_STATE.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_BOX_LIGHT_STATE_VALUE: 'BOX_LIGHT_STATE.SET_ORIGINAL_BOX_LIGHT_STATE_VALUE',
 }
