export const AdminActionType = {
  SET_PROPERTY_VALUE: 'ADMIN.SET_PROPERTY_VALUE',

  GET_LIST_API: 'ADMIN.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'ADMIN.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'ADMIN.GET_LIST_API_FAILURE',

  GET_BY_ID_API: 'ADMIN.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'ADMIN.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'ADMIN.GET_BY_ID_API_FAILURE',

  CHANGE_ADMIN_INPUT_VALUE: 'ADMIN.CHANGE_ADMIN_INPUT_VALUE',
  CHANGE_ADMIN_USER_INPUT_VALUE: 'ADMIN.CHANGE_ADMIN_USER_INPUT_VALUE',

  PUT_BY_ID_API: 'ADMIN.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'ADMIN.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'ADMIN.PUT_BY_ID_API_SUCCESS',

  SET_ORIGINAL_ADMIN_VALUE: 'ADMIN.SET_ORIGINAL_ADMIN_VALUE',

  POST_BY_ID_API: 'ADMIN.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'ADMIN.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'ADMIN.POST_BY_ID_API_SUCCESS',

  DELETE_BY_ID_API: 'ADMIN.DELETE_BY_ID_API',
  DELETE_BY_ID_API_FAILURE: 'ADMIN.DELETE_BY_ID_API_FAILURE',
  DELETE_BY_ID_API_SUCCESS: 'ADMIN.DELETE_BY_ID_API_SUCCESS',
}
