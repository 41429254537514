import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getEventsAPIFailure,
  getEventsAPISuccess,
  getEventAPISuccess,
  getEventAPIFailure,
  putEventAPISuccess,
  putEventAPIFailure,
  postEventAPISuccess,
  postEventAPIFailure,
} from './../../actions'
import {
  EventActionType,
} from './../../types'
import { API } from './../../../utils/api'


const getEventsRequest = async (queryParams) => {
  if (!queryParams) {
    return API.get('event')
  } else {
    const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc' } = queryParams
    return API.get(`event?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
  }
}

function* getEventsAPI({params}) {
  try {
    const request = yield call(getEventsRequest, params)
    const payload = params ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getEventsAPISuccess(payload))
  } catch (error) {
    yield put(getEventsAPIFailure(error))
  }
}

export function* getEvents() {
  yield takeEvery(EventActionType.GET_LIST_API, getEventsAPI)
}

const getEventRequest = async (id) => API.get(`event/${id}`)
function* getEventAPI({ payload }) {
  try {
    const request = yield call(getEventRequest, payload)
    yield put(getEventAPISuccess(request))
  } catch (error) {
    yield put(getEventAPIFailure(error))
  }
}

export function* getEvent() {
  yield takeEvery(EventActionType.GET_BY_ID_API, getEventAPI)
}

const putMediaEvent = async (id, data, input) => API.put(`events/${id}/media/${input}`, data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

const putEventRequest = async (data) => API.put(`event/${data.id}`, data)
function* putEventAPI({ payload }) {
  try {
    let media =  typeof payload.media !== 'string' ? null : payload.media
    let mediaDetail =  typeof payload.media_detail !== 'string' ? null : payload.media_detail
    let eventData = {
      ...payload,
      media_detail: mediaDetail,
      media: media,
      status_id: payload.status_id.value,
    }

    delete eventData.status
    const request = yield call(putEventRequest, eventData)
    if(payload && payload.id && typeof payload.media !== 'string'){
      let data = new FormData()
      data.append('media', payload.media)
      yield call(putMediaEvent, payload.id, data, 'media')
    }

    if(payload && payload.id && typeof payload.media_detail !== 'string'){
      let data = new FormData()
      data.append('media', payload.media_detail)
      yield call(putMediaEvent, payload.id, data, 'media_detail')
    }

    yield put(putEventAPISuccess(request))
  } catch (error) {
    yield put(putEventAPIFailure(error))
  }
}

export function* putEvent() {
  yield takeEvery(EventActionType.PUT_BY_ID_API, putEventAPI)
}

const postEventRequest = async (data) => API.post('event', data)
function* postEventAPI({ payload }) {
  try {
    const request = yield call(postEventRequest, {
      ...payload,
      media: null,
      media_detail: null,
      status_id: payload.status_id.value,
    })

    if(request && request.data && request.data.id){
      let data = new FormData()
      let dataDetail = new FormData()
      data.append('media', payload.media)
      dataDetail.append('media', payload.media_detail)
      yield call(putMediaEvent, request.data.id, data, 'media')
      yield call(putMediaEvent, request.data.id, dataDetail, 'media_detail')
    }

    yield put(postEventAPISuccess(request))
  } catch (error) {
    yield put(postEventAPIFailure(error))
  }
}

export function* postEvent() {
  yield takeEvery(EventActionType.POST_BY_ID_API, postEventAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getEvents),
    fork(getEvent),
    fork(putEvent),
    fork(postEvent),
  ])
}
