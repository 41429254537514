import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Button, message as AntMessage, Table} from 'antd'
import PropTypes from 'prop-types'
import {LayoutAdmin} from './../../../components'
import {getBoxLightStatesAPI, setBoxLightStatePropertyValue} from '../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"

const BoxLightStateListPage = ({getBoxLightStatesAPI, boxLightStates, successMessage, setBoxLightStatePropertyValue, loading}) => {

    useEffect(() => {
        getBoxLightStatesAPI()
    }, [getBoxLightStatesAPI])

    useEffect(() => {
        if (successMessage) {
            setBoxLightStatePropertyValue('successMessage', null)
            AntMessage.success(successMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const boxLightStateColumns = [
        {
            title: 'View',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/box-light-state/${id}`} key={id}>
                    <Button>
                        <EyeOutlined />
                    </Button>
                </Link>
            ),
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Led Sequence Id',
            dataIndex: 'ledSequence',
            key: 'ledSequence',
            render: (value) => (value ? value.name : '--' ),
        },
    ]
    return (
        <LayoutAdmin>
            <Table
                dataSource={boxLightStates}
                columns={boxLightStateColumns}
                loading={loading}
                pagination={false}
                rowKey="id"
            />
        </LayoutAdmin>
    )
}

BoxLightStateListPage.propTypes = {
    getBoxLightStatesAPI: PropTypes.func,
    boxLightStates: PropTypes.array,
    loading: PropTypes.bool,
}

const mapStateToProps = ({ boxLightStateAPI }) => {
    const {
        boxLightStates, loading,
    } = boxLightStateAPI
    return {
        boxLightStates,
        loading,
    }
}

export default
connect(mapStateToProps, {
    getBoxLightStatesAPI,
    setBoxLightStatePropertyValue,
})(BoxLightStateListPage)
