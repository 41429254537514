import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getCampaignsAPIFailure,
  getCampaignsAPISuccess,
  getCampaignAPISuccess,
  getCampaignAPIFailure,
  putCampaignAPISuccess,
  putCampaignAPIFailure,
  postCampaignAPISuccess,
  postCampaignAPIFailure,
} from '../../actions'
import {
  CampaignActionType,
} from '../../types'
import { API } from '../../../utils/api'


const getCampaignsRequest = async ({limit=10, page=1, orderKey = 'id', orderDirection='desc'}) => {
  return API.get(`campaign?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
}

function* getCampaignsAPI({params}) {
  try {
    const request = yield call(getCampaignsRequest, params)
    yield put(getCampaignsAPISuccess(request))
  } catch (error) {
    yield put(getCampaignsAPIFailure(error))
  }
}

export function* getCampaigns() {
  yield takeEvery(CampaignActionType.GET_LIST_API, getCampaignsAPI)
}

const getCampaignRequest = async (id) => API.get(`campaign/${id}`)
function* getCampaignAPI({ payload }) {
  try {
    const request = yield call(getCampaignRequest, payload)
    yield put(getCampaignAPISuccess(request))
  } catch (error) {
    yield put(getCampaignAPIFailure(error))
  }
}

export function* getCampaign() {
  yield takeEvery(CampaignActionType.GET_BY_ID_API, getCampaignAPI)
}

const putCampaignRequest = async (data) => API.put(`campaign/${data.id}`, data) // TODO extra param is passed and which is causing the internal error
function* putCampaignAPI({ payload }) {
  try {
    let campaign = {
      ...payload,
    }

    delete campaign.event

    const request = yield call(putCampaignRequest, campaign)
    yield put(putCampaignAPISuccess(request))
  } catch (error) {
    yield put(putCampaignAPIFailure(error))
  }
}

export function* putCampaign() {
  yield takeEvery(CampaignActionType.PUT_BY_ID_API, putCampaignAPI)
}

const postCampaignRequest = async (data) => API.post('campaign', data)
function* postCampaignAPI({ payload }) {
  try {
    const request = yield call(postCampaignRequest, payload)
    yield put(postCampaignAPISuccess(request))
  } catch (error) {
    yield put(postCampaignAPIFailure(error))
  }
}

export function* postCampaign() {
  yield takeEvery(CampaignActionType.POST_BY_ID_API, postCampaignAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getCampaigns),
    fork(getCampaign),
    fork(putCampaign),
    fork(postCampaign),
  ])
}
