import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Row, Col, Form, Transfer,
  Select, Input, Switch, message,
} from 'antd'
import {
  changeMessageInputValue, getMembersAPI, getMessageTemplatesAPI,
  getEventsAPI, setMessagePropertyValue,
} from './../../effects/actions'
import { Util } from '../../utils'
import styled from "styled-components"
import LedPreview from "../../components/led-preview"

const { Option } = Select

const StyledTag = styled.div`
  padding: 4px 8px;
  border: 2px solid #dc3d3d;
  background: #feef;
  border-radius: 4px;
`

const StyledRow = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`

const MessageForm = ({
  messageInputs, changeMessageInputValue, viewMode, isNew, members,
  getMembersAPI, ledSequences, types, messageTemplates, errorMessage, successMessage,
  getMessageTemplatesAPI, events, getEventsAPI, setMessagePropertyValue, ledSequenceForPreview,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([])
  const [showLedPreview, setShowLedPreview] = useState(false)

  useEffect(() => {
    getMembersAPI()
    getMessageTemplatesAPI()
    getEventsAPI()
  }, [getEventsAPI, getMembersAPI, getMessageTemplatesAPI])

  useEffect(() => {
    if (successMessage) {
      setMessagePropertyValue('successMessage', null)
      message.success(`Message list updated successfully!`)
      setMessagePropertyValue('successMessage', null)
    }
  }, [setMessagePropertyValue, successMessage])

  useEffect(() => {
    if (errorMessage) {
      message.error(Util.renderMessage(errorMessage))
      setMessagePropertyValue('errorMessage', null)
    }
  }, [errorMessage, setMessagePropertyValue])

  const onChangeInput = (e) => {
    let { name, value } = e.target
    changeMessageInputValue(name, value)
  }

  const membersOptions = members.filter(d => d.box_id).map(d => { return { key: d.id, name: `${d.id} - ${d.name} ${d.surname} (${d.box_name})` }})
  const ledSequencesOptions = ledSequences.map(d => <Option key={d.id}>{d.name} - {d.type}</Option>)
  const typeOptions = types.map(d => <Option key={d}>{d}</Option>)
  const templateOptions  = messageTemplates.map(d => <Option key={d.id}>{d.id} - {d.subject}</Option>)
  const options = events.map(d => <Option key={d.id}>{d.headline} - {d.subheadline}</Option>)

  const handleChangeMember = (nextTargetKeys) => changeMessageInputValue('members', nextTargetKeys)
  const handleSelectMemberChange = (sourceSelectedKeys, targetSelectedKeys) => setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  const handleLedSequenceChange = (value) => {
    const selectedSequence = ledSequences.find(seq => +seq.id === +value)
    changeMessageInputValue('led_sequence_id', value)
    setMessagePropertyValue('ledSequenceForPreview', selectedSequence)
  }
  return (
    <Row>
      <Col>
        {!isNew && (
            <StyledRow>
              <StyledTag >
                Campaign: <b>{messageInputs.campaign.id} - {messageInputs.campaign.name}</b>
              </StyledTag>
              <StyledTag >
                Is Notification?: <b>{messageInputs.is_notification ? 'True' : 'False'}</b>
              </StyledTag>
              <StyledTag>
                Status: <b>{messageInputs.status ? messageInputs.status.name: '-'}</b>
              </StyledTag>
            </StyledRow>
        )}

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label="Type" labelCol={{ span: 24 }}>
              <Select
                  showSearch
                  value={messageInputs.type_id}
                  placeholder={"Type"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  labelInValue={false}
                  filterOption={true}
                  onChange={(value) => {
                    changeMessageInputValue('type_id', value)
                    changeMessageInputValue('message_template_id',  { key: null, label: null, value: 0})
                    changeMessageInputValue('event_id',  { key: null, label: null, value: 0})
                  }}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={!isNew}
                >
                {typeOptions}
              </Select>
            </Form.Item>
          </Col>
          {messageInputs.type_id === "message" &&
          <Col span={12}>
            <Form.Item label="Template" labelCol={{span: 24}}>
              <Select
                  showSearch
                  value={messageInputs.message_template_id}
                  placeholder={"Template"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  labelInValue={true}
                  filterOption={true}
                  onChange={(value) => {
                    changeMessageInputValue('message_template_id', value)
                  }}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={!isNew}
              >
                {templateOptions}
              </Select>
            </Form.Item>
          </Col>
          }
          {/* <Col span={12}>
            <Form.Item label="Status" labelCol={{ span: 24 }}>
              <Select
                showSearch
                value={messageInputs.status_id}
                placeholder={"Status"}
                defaultActiveFirstOption={false}
                showArrow={true}
                labelInValue={true}
                filterOption={true}
                onChange={(value) => {
                  changeMessageInputValue('status_id', value)
                }}
                notFoundContent={<>0 results found</>}
                size="large"
                disabled={viewMode}
              >
                {statusesOptions}
              </Select>
            </Form.Item>
          </Col> */}
          {messageInputs.type_id === "event" && (
              <Col span={12}>
                <Form.Item label="Event" labelCol={{span: 24}}>
                  <Select
                      showSearch
                      value={messageInputs.event_id}
                      placeholder={"event"}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={true}
                      onChange={(value) => changeMessageInputValue('event_id', value)}
                      notFoundContent={<>0 results found</>}
                      size="large"
                      disabled={!isNew}
                      labelInValue={true}
                  >
                    {options}
                  </Select>
                </Form.Item>
              </Col>)
          }
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Transfer
              dataSource={membersOptions}
              titles={['Members', 'Selected']}
              targetKeys={messageInputs.members}
              selectedKeys={selectedKeys}
              onChange={handleChangeMember}
              onSelectChange={handleSelectMemberChange}
              render={item => `${item.name}`}
              disabled={viewMode}
              style={{ marginBottom: 16, flex: 1 }}
            />
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label="Led Sequence" labelCol={{ span: 24 }}>
              <Select
                  showSearch
                  value={messageInputs.led_sequence_id}
                  placeholder={"Led Sequence"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={true}
                  onChange={handleLedSequenceChange}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={viewMode}
                >
                {ledSequencesOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item label={"Display LED Preview"} labelCol={{ span: 24 }}>
              <Switch size="large" onChange={(value) => setShowLedPreview(value)} checked={showLedPreview} />
            </Form.Item>
          </Col>
        </Row>
        {
          showLedPreview && (
              <Row >
                <LedPreview
                    led_sequence_type={ledSequenceForPreview.type}
                    color1={ledSequenceForPreview.color_1}
                    color2={ledSequenceForPreview.color_2}
                    brightness1={+ledSequenceForPreview.brightness_1}
                    brightness2={+ledSequenceForPreview.brightness_2}
                    speed={+ledSequenceForPreview.speed}
                />
              </Row>
          )
        }
        <Row gutter={[12, 0]}>
          <Col span={4}>
            <Form.Item label="Show contact me" labelCol={{ span: 24 }}>
              <Switch name="show_contact_me" size="large" onChange={(value) => changeMessageInputValue('show_contact_me', value)} checked={messageInputs.show_contact_me} disabled={viewMode} />
            </Form.Item>
          </Col>
          {messageInputs.type_id === "event" && <Col span={4}>
            <Form.Item label="Show learn more" labelCol={{ span: 24 }}>
              <Switch name="show_learn_more" size="large" onChange={(value) => changeMessageInputValue('show_learn_more', value)} checked={messageInputs.show_learn_more} disabled={viewMode} />
            </Form.Item>
          </Col>}
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={2}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_one_show" size="large" onChange={(value) => changeMessageInputValue('action_one_show', value)} checked={messageInputs.action_one_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 1 text" labelCol={{ span: 24 }}>
              <Input name="action_one_text" type="text" placeholder="Action 1 text" size="large" onChange={onChangeInput} value={messageInputs.action_one_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 1 text color" labelCol={{ span: 24 }}>
              <Input name="action_one_color" type="color" placeholder="Action 1 text color" size="large" onChange={onChangeInput} value={messageInputs.action_one_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 1 background color" labelCol={{ span: 24 }}>
              <Input name="action_one_background" type="color" placeholder="Action 1 background color" size="large" onChange={onChangeInput} value={messageInputs.action_one_background} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 1 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_one_confirmation_message" type="text" placeholder="Action 1 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_one_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_two_show" size="large" onChange={(value) => changeMessageInputValue('action_two_show', value)} checked={messageInputs.action_two_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 2 text" labelCol={{ span: 24 }}>
              <Input name="action_two_text" type="text" placeholder="Action 2 text" size="large" onChange={onChangeInput} value={messageInputs.action_two_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 2 text color" labelCol={{ span: 24 }}>
              <Input name="action_two_color" type="color" placeholder="Action 2 text color" size="large" onChange={onChangeInput} value={messageInputs.action_two_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 2 background color" labelCol={{ span: 24 }}>
              <Input name="action_two_background" type="color" placeholder="Action 2 background color" size="large" onChange={onChangeInput} value={messageInputs.action_two_background} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 2 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_two_confirmation_message" type="text" placeholder="Action 2 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_two_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>
        </Row>

      </Col>
    </Row>

  )
}

MessageForm.propTypes = {
  changeMessageInputValue: PropTypes.func,
  getCampaignsAPI: PropTypes.func,
  getMembersAPI: PropTypes.func,
  messageInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  campaigns: PropTypes.array,
  statuses: PropTypes.array,
  isNew: PropTypes.bool,
  messageTemplates: PropTypes.array,
  getMessageTemplatesAPI: PropTypes.func,
  getEventsAPI:PropTypes.func,
}

MessageForm.defaultProps = {
  viewMode: false,
  isNew: false,
}
const mapStateToProps = ({ messageAPI, messageTemplateAPI, memberAPI, statusAPI, eventAPI, ledSequenceAPI }) => {
  const {
    errorMessage, successMessage, messageInputs, types, ledSequenceForPreview,
  } = messageAPI
  const { events } = eventAPI
  const { members } = memberAPI
  const { statuses } = statusAPI
  const { messageTemplates } = messageTemplateAPI
  const { ledSequences } = ledSequenceAPI
  return {
    errorMessage,
    successMessage,
    messageInputs,
    events,
    members,
    statuses,
    ledSequences,
    types,
    messageTemplates,
    ledSequenceForPreview,
  }
}

export default
connect(mapStateToProps, {
  changeMessageInputValue,
  getMembersAPI,
  getMessageTemplatesAPI,
  getEventsAPI,
  setMessagePropertyValue,
})(MessageForm)
