import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import UploadMedia from "../../components/upload-media"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const MemberModal = ({visible, doneCallback, memberData}) => {
    const history =  useHistory()
    console.log('memberData is ', memberData)
    if (_.isEmpty(memberData)) {
        return null
    }
    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Member Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Member" icon={<EditOutlined />} onClick={() => history.push(`/member/${memberData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={10}>
                        <Form.Item label="Name" labelCol={{ span: 24 }}>
                            <Input name="name" type="text" placeholder="Name" size="large" value={memberData.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Last name" labelCol={{ span: 24 }}>
                            <Input name="surname" type="text" placeholder="Last name" size="large" value={memberData.surname} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Membership number" labelCol={{ span: 24 }}>
                            <Input name="member_code" type="text" placeholder="Membership number" size="large" value={memberData.member_code} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="E-mail" labelCol={{ span: 24 }}>
                            <Input name="user.email" type="text" placeholder="Email" size="large" value={memberData.user.email} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Box" labelCol={{ span: 24 }}>
                            <Input name="box" type="text" placeholder="Box" size="large" value={memberData.box ? `${memberData.box.name}-${memberData.box.mac_address}` : null} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Welcome message" labelCol={{ span: 24 }}>
                            <Input name="message" type="text" placeholder="Welcome message" size="large" value={memberData.message} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Welcome time length (seconds)" labelCol={{ span: 24 }}>
                            <Input name="message_length" type="text" placeholder="Welcome time length (seconds)" size="large" value={memberData.message_length} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Welcome media" labelCol={{ span: 24 }}>
                            <UploadMedia
                                media={memberData.message_media}
                                viewMode={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

MemberModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    memberData: PropTypes.object,
}

export default MemberModal
