import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'

import {
  deleteMemberAPIFailure,
  deleteMemberAPISuccess,
  detachBoxAPIFailure,
  detachBoxAPISuccess,
  getMemberAPIFailure,
  getMemberAPISuccess,
  getMembersAPIFailure,
  getMembersAPISuccess,
  getRepliesByMemberIdAPIFailure,
  getRepliesByMemberIdAPISuccess,
  postMemberAPIFailure,
  postMemberAPISuccess,
  putMemberAPIFailure,
  putMemberAPISuccess, toggleDeactivateMemberAPIFailure, toggleDeactivateMemberAPISuccess,
} from '../../actions'
import {MemberActionType} from '../../types'
import {API} from '../../../utils/api'


const getMembersRequest = async (queryParams) => {
  if (!queryParams) {
    return API.get('member')
  } else {
    const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc' } = queryParams
    return API.get(`member?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
  }
}

function* getMembersAPI({params}) {
  try {
    const request = yield call(getMembersRequest, params)
    const payload = params ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getMembersAPISuccess(payload))
  } catch (error) {
    yield put(getMembersAPIFailure(error))
  }
}

export function* getMembers() {
  yield takeEvery(MemberActionType.GET_LIST_API, getMembersAPI)
}

const getMemberRequest = async (id) => API.get(`member/${id}`)
function* getMemberAPI({ payload }) {
  try {
    const request = yield call(getMemberRequest, payload)
    yield put(getMemberAPISuccess(request))
  } catch (error) {
    yield put(getMemberAPIFailure(error))
  }
}

export function* getMember() {
  yield takeEvery(MemberActionType.GET_BY_ID_API, getMemberAPI)
}

const putMediaMember = async (id, data) => API.put(`members/${id}/media`, data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})


const putMemberRequest = async (data) => API.put(`member/${data.id}`, data)
function* putMemberAPI({ payload }) {
  try {
    let media = typeof payload.message_media !== 'string' ? null : payload.message_media
    let memberData = {
      ...payload,
      message_media: media,
      box_id: payload.box_id ? payload.box_id.value : 0,
      message_length: payload.message_length * 1000,
    }

    delete memberData.box
    const request = yield call(putMemberRequest, memberData)
    if(payload && payload.id && typeof payload.message_media !== 'string'){
      let data = new FormData()
      data.append('message_media', payload.message_media)
      yield call(putMediaMember, payload.id, data)
    }

    yield put(putMemberAPISuccess(request))
  } catch (error) {
    yield put(putMemberAPIFailure(error))
  }
}

export function* putMember() {
  yield takeEvery(MemberActionType.PUT_BY_ID_API, putMemberAPI)
}

const detachBoxRequest = async (memberId) => API.post(`member/detach-box/${memberId}`)
function* detachBoxAPI({ payload }) {
  try {
    if (!payload.id) {
      console.log('No box id specified!')
      return
    }
    const response = yield call(detachBoxRequest, payload.id)
    yield put(detachBoxAPISuccess(response.data))
  } catch (error) {
    yield put(detachBoxAPIFailure(error))
  }
}

export function* detachBox() {
  yield takeLatest(MemberActionType.DETACH_BOX_BY_ID_API, detachBoxAPI)
}

const deleteMemberRequest = async (memberId) => API.delete(`member/${memberId}`)
function* deleteMemberAPI({ payload }) {
  try {
    if (!payload.id) {
      console.log('No member id specified!')
      return
    }
    const response = yield call(deleteMemberRequest, payload.id)
    yield put(deleteMemberAPISuccess(response.data))
  } catch (error) {
    yield put(deleteMemberAPIFailure(error))
  }
}

export function* deleteMember() {
  yield takeLatest(MemberActionType.DELETE_BY_ID_API, deleteMemberAPI)
}

const toggleDeactivateMemberRequest = async (data) => API.post(`member/update-deactivate-status/${data.id}`, data)
function* toggleDeactivateMemberAPI({ payload }) {

  try {
    if (!payload.id) {
      console.log('No member id specified!')
      return
    }
    const response = yield call(toggleDeactivateMemberRequest, {
      id: payload.id,
      is_activated: !payload.is_activated,
    })
    const box = response.data[0].box
    delete response.data[0].box
    yield put(toggleDeactivateMemberAPISuccess({
      ...response.data[0],
      box_name: box.name,
      mac_address: box.mac_address,
    }))
  } catch (error) {
    yield put(toggleDeactivateMemberAPIFailure(error))
  }
}

export function* toggleDeactivateMember() {
  yield takeLatest(MemberActionType.DEACTIVATE_BY_ID_API, toggleDeactivateMemberAPI)
}

const postMemberRequest = async (data) => API.post('member', data)
function* postMemberAPI({ payload }) {
  try {
    const data = {
      ...payload,
      message_media: null,
      box_id: payload.box_id.value,
      message_length: payload.message_length * 1000,
    }
    delete data.box
    const request = yield call(postMemberRequest, data)

    if (request && request.data.id && typeof payload.message_media !== 'string') {
      let data = new FormData()
      data.append('message_media', payload.message_media)
      yield call(putMediaMember, request.data.id, data)
    }
    yield put(postMemberAPISuccess(request))
  } catch (error) {
    yield put(postMemberAPIFailure(error))
  }
}

export function* postMember() {
  yield takeEvery(MemberActionType.POST_BY_ID_API, postMemberAPI)
}

const getMessageReceiverRepliesRequest = async ({id, queryParams}) => {
  if (!queryParams) {
    return API.get(`members/${id}/replies`)
  } else {
    const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc' } = queryParams
    return API.get(`members/${id}/replies?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
  }
}
function* getMessageReceiverRepliesAPI({ payload }) {
  try {
    const request = yield call(getMessageReceiverRepliesRequest, payload)
    const responseData = payload.queryParams ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getRepliesByMemberIdAPISuccess(responseData))
  } catch (error) {
    yield put(getRepliesByMemberIdAPIFailure(error))
  }
}
export function* getMessageReceiverReplies() {
  yield takeEvery(MemberActionType.GET_REPLIES_BY_MEMBER_ID_API, getMessageReceiverRepliesAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getMembers),
    fork(getMember),
    fork(putMember),
    fork(postMember),
    fork(deleteMember),
    fork(detachBox),
    fork(toggleDeactivateMember),
    fork(getMessageReceiverReplies),
  ])
}
