export const MemberSplashActionType = {
  SET_PROPERTY_VALUE: 'MEMBER_SPLASH.SET_PROPERTY_VALUE',
  GET_LIST_API: 'MEMBER_SPLASH.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'MEMBER_SPLASH.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'MEMBER_SPLASH.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'MEMBER_SPLASH.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'MEMBER_SPLASH.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'MEMBER_SPLASH.GET_BY_ID_API_FAILURE',
  CHANGE_SPLASH_INPUT_VALUE: 'MEMBER_SPLASH.CHANGE_SPLASH_INPUT_VALUE',
  PUT_BY_ID_API: 'MEMBER_SPLASH.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'MEMBER_SPLASH.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'MEMBER_SPLASH.PUT_BY_ID_API_SUCCESS',
  DELETE_BY_ID_API: 'MEMBER_SPLASH.DELETE_BY_ID_API',
  DELETE_BY_ID_API_FAILURE: 'MEMBER_SPLASH.DELETE_BY_ID_API_FAILURE',
  DELETE_BY_ID_API_SUCCESS: 'MEMBER_SPLASH.DELETE_BY_ID_API_SUCCESS',
  SET_ORIGINAL_SPLASH_VALUE: 'MEMBER_SPLASH.SET_ORIGINAL_SPLASH_VALUE',
  POST_BY_ID_API: 'MEMBER_SPLASH.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'MEMBER_SPLASH.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'MEMBER_SPLASH.POST_BY_ID_API_SUCCESS',
  REPOSITION_SPLASH_ITEM: 'MEMBER_SPLASH.REPOSITION_SPLASH_ITEM',
  REPOSITION_SPLASH_ITEM_FAILURE: 'MEMBER_SPLASH.REPOSITION_SPLASH_ITEM_FAILURE',
  REPOSITION_SPLASH_ITEM_SUCCESS: 'MEMBER_SPLASH.REPOSITION_SPLASH_ITEM_SUCCESS',
}
