import {Table} from "antd"
import MessageModal from "../../../modals/message"
import EventModal from "../../../modals/event"
import MessageTemplateModal from "../../../modals/message-template"
import React, {useEffect, useState} from "react"
import moment from "moment"
import {defaultFormatDate} from "../../../constants/date"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import styled from "styled-components"
import {getRepliesByMemberIdAPI} from "../../../effects/actions"
import BoxModal from "../../../modals/box"
import CampaignModal from "../../../modals/campaign"

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const MemberMessageRepliesTable = ({replies, getRepliesByMemberIdAPI, id, loadingReplies, totalReplies}) => {

    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)
    const [showMessageTemplateModal, setShowMessageTemplateModal] = useState(false)
    const [showBoxModal, setShowBoxModal] = useState(false)
    const [showCampaignModal, setShowCampaignModal] = useState(false)

    const [selectedNotification, setSelectedNotification] = useState()
    const [selectedEvent, setSelectedEvent] = useState()
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState()
    const [selectedBox, setSelectedBox] = useState()
    const [selectedCampaign, setSelectedCampaign] = useState()

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const memberRepliesListColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 20,
            render: (message, data) => data.id,
        },{
            title: 'Campaign',
            dataIndex: 'message',
            render: (message) => (
                <StyledLink onClick={() => handleCampaignCellClick(message.campaign)}>
                    {message.campaign.id} - {message.campaign.name}
                </StyledLink>
            ),
        }, {
            title: 'Is notification?',
            dataIndex: 'isNotification',
            width: 20,
            render: (message, data) => data && data.message && data.message.is_notification ? "Yes" : "No",
        },
        {
            title: 'Notification',
            dataIndex: 'message',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message, data) => (
                <StyledLink onClick={() => handleNotificationCellClick(data)}>
                    {message.id}
                </StyledLink>
            ),
        },
        {
            title: 'Message Template',
            dataIndex: 'message',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_template_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message) => {
                if(message && message.message_template_id){
                    return (
                        <StyledLink onClick={() => handleMessageTemplateCellClick(message)}>
                            {message.message_template_id}
                        </StyledLink>
                    )
                }
                return '-'
            },
        },
        {
            title: 'Event',
            dataIndex: 'message',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('event_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message) => {
                if (message && message.event_id){
                    return (
                        <StyledLink onClick={() => handleEventCellClick(message)}>
                            {message.event_id}
                        </StyledLink>
                    )
                } else {
                    return '-'
                }
            },
        }, {
            title: 'Box',
            dataIndex: 'box',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('box_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (box) => (
                <StyledLink onClick={() => handleBoxCellClick(box)}>
                    {box.id} - {box.name}
                </StyledLink>
            ),
        },
        {
            title: 'Response',
            dataIndex: 'status',
            render: (status, record) => {
                if(status.id === 5){
                    return status.name
                }else if(status.id === 3){
                    return record && record.message && record.message && record.message.action_one_text
                }else if(status.id === 4) {
                    return record && record.message && record.message && record.message.action_two_text
                }else{
                    return '-'
                }
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('status_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Replied at',
            dataIndex: 'updated_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Submitted at',
            dataIndex: 'message.created_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('created_at')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
    ]

    const handleNotificationCellClick = (data) => {
        setShowNotificationModal(true)
        setSelectedNotification(data.message)
    }

    const handleNotificationDoneCallback = () => {
        setShowNotificationModal(false)
        setSelectedNotification(null)
    }

    const handleEventCellClick = (message) => {
        setShowEventModal(true)
        setSelectedEvent(message.event)
    }

    const handleEventDoneCallback = () => {
        setShowEventModal(false)
        setSelectedEvent(null)
    }

    const handleMessageTemplateCellClick = (message) => {
        setShowMessageTemplateModal(true)
        setSelectedMessageTemplate(message.template)
    }

    const handleMessageTemplateDoneCallback = () => {
        setShowMessageTemplateModal(false)
        setSelectedMessageTemplate(null)
    }

    const handleCampaignCellClick = (data) => {
        setShowCampaignModal(true)
        setSelectedCampaign(data)
    }

    const handleCampaignDoneCallback = () => {
        setShowCampaignModal(false)
        setSelectedCampaign(null)
    }

    const handleBoxCellClick = (data) => {
        setShowBoxModal(true)
        setSelectedBox(data)
    }

    const handleBoxDoneCallBack = () => {
        setShowBoxModal(false)
        setSelectedBox(null)
    }


    const updateTableDimensions = (pageNum, pageS) => {
        if(pageNum!==pageNumber) {
            setPageNumber(pageNum)
        }
        if(pageS!==pageSize) {
            setPageSize(pageS)
        }
    }

    useEffect(() => {
        if (id !== 'new') {
            getRepliesByMemberIdAPI({
                id,
                queryParams: {limit: pageSize, page: pageNumber, orderKey, orderDirection},
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRepliesByMemberIdAPI, id, pageSize, pageNumber, orderKey, orderDirection])

    return (
        <>
            <Table
                dataSource={replies}
                columns={memberRepliesListColumns}
                loading={loadingReplies}
                rowKey="id"
                pagination={{ total: totalReplies, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            />
            <MessageModal
                visible={showNotificationModal}
                doneCallback={handleNotificationDoneCallback}
                messageData={selectedNotification}
            />
            <EventModal
                visible={showEventModal}
                doneCallback={handleEventDoneCallback}
                eventData={selectedEvent}
            />
            <MessageTemplateModal
                visible={showMessageTemplateModal}
                doneCallback={handleMessageTemplateDoneCallback}
                messageTemplateData={selectedMessageTemplate}
            />
            <BoxModal
                visible={showBoxModal}
                doneCallback={handleBoxDoneCallBack}
                boxData={selectedBox}
            />
            <CampaignModal
                visible={showCampaignModal}
                doneCallback={handleCampaignDoneCallback}
                campaignData={selectedCampaign}
            />
        </>
    )
}

MemberMessageRepliesTable.propTypes = {
    id: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    loadingReplies: PropTypes.bool,
}

const mapStateToProps = ({ memberAPI }) => {
    const { replies, errorMessage, successMessage, loadingReplies, totalReplies } = memberAPI

    return {
        errorMessage,
        successMessage,
        loadingReplies,
        replies,
        totalReplies,
    }
}

export default connect(mapStateToProps, {
    getRepliesByMemberIdAPI,
})(MemberMessageRepliesTable)

