import { MessageActionType } from './../../types'

export const getMessagesAPISuccess = (response) => ({
  type: MessageActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getMessagesAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getMessagesAPI = (queryParams) => ({
  type: MessageActionType.GET_LIST_API,
  params: queryParams,
})

export const getMessageAPISuccess = (response) => ({
  type: MessageActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getMessageAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getMessageAPI = (
  id,
) => ({
  type: MessageActionType.GET_BY_ID_API,
  payload: id,
})

export const changeMessageInputValue = (
  property, value,
) => ({
  type: MessageActionType.CHANGE_MESSAGE_INPUT_VALUE,
  payload: { property, value },
})

export const setMessagePropertyValue = (
  property, value,
) => ({
  type: MessageActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putMessageAPISuccess = (response) => ({
  type: MessageActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putMessageAPIFailure = (
  error,
) => ({
  type: MessageActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putMessageAPI = (
  data,
) => ({
  type: MessageActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalMessageValue = (isNew) => ({
  type: MessageActionType.SET_ORIGINAL_MESSAGE_VALUE,
  payload: { isNew },
})

export const postMessageAPISuccess = (response) => ({
  type: MessageActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postMessageAPIFailure = (
  error,
) => ({
  type: MessageActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postMessageAPI = (
  data,
) => ({
  type: MessageActionType.POST_BY_ID_API,
  payload: data,
})

export const getMessageReceiverRepliesAPISuccess = (response) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_API_SUCCESS,
  payload: response,
})

export const getMessageReceiverRepliesAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_API_FAILURE,
  payload: error,
})

export const getMessageReceiverRepliesAPI = (
  id,
) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_API,
  payload: id,
})

export const getMessagesByCampaignIdAPISuccess = (response) => ({
  type: MessageActionType.GET_BY_CAMPAIGN_ID_API_SUCCESS,
  payload: response,
})

export const getMessagesByCampaignIdAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_BY_CAMPAIGN_ID_API_FAILURE,
  payload: error,
})

export const getMessagesByCampaignIdAPI = (
  id,
) => ({
  type: MessageActionType.GET_BY_CAMPAIGN_ID_API,
  payload: id,
})

export const getReceiverRepliesByCampaignIdAPISuccess = (response) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_SUCCESS,
  payload: response,
})

export const getReceiverRepliesByCampaignIdAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_FAILURE,
  payload: error,
})

export const getReceiverRepliesByCampaignIdAPI = (payload) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API,
  payload,
})

export const getMessageEventsAPISuccess = (response) => ({
  type: MessageActionType.GET_MESSAGE_EVENTS_API_SUCCESS,
  payload: response,
})

export const getMessageEventsAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_MESSAGE_EVENTS_API_FAILURE,
  payload: error,
})

export const getMessageEventsAPI = (
  payload,
) => ({
  type: MessageActionType.GET_MESSAGE_EVENTS_API,
  payload: payload,
})

export const getReceiverRepliesByEventIdAPISuccess = (response) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_SUCCESS,
  payload: response,
})

export const getReceiverRepliesByEventIdAPIFailure = (
  error,
) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_FAILURE,
  payload: error,
})

export const getReceiverRepliesByEventIdAPI = (
  id,
) => ({
  type: MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API,
  payload: id,
})

export const removeEventFromMessageAPISuccess = (response) => ({
  type: MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API_SUCCESS,
  payload: response,
})

export const removeEventFromMessageAPIFailure = (
  error,
) => ({
  type: MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API_FAILURE,
  payload: error,
})

export const removeEventFromMessageAPI = (
  data,
) => ({
  type: MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API,
  payload: data,
})


export const patchMessageInfoAPISuccess = (response) => ({
  type: MessageActionType.PATCH_INFO_BY_ID_SUCCESS,
  payload: response,
})

export const patchMessageInfoAPIFailure = (
  error,
) => ({
  type: MessageActionType.PATCH_INFO_BY_ID_FAILURE,
  payload: error,
})

export const patchMessageInfoAPI = (
  data,
) => ({
  type: MessageActionType.PATCH_INFO_BY_ID,
  payload: data,
})

export const setMessageDataUpdated = (
  data,
) => ({
  type: MessageActionType.SET_MESSAGE_DATA_UPDATED,
  payload: data,
})
