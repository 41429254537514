import {AdminActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  admins: [],
  totalAdmins: 0,
  admin: {
    name: '',
    email: '',
    receives_notifications: false,
  },
  adminInputs: {
    user: {
      name: '',
      email: '',
      password: '',
      active: true,
    },
    receives_notifications: false,
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case AdminActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case AdminActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case AdminActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload.data,
        totalBoxes: action.payload.total,
      }

    case AdminActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AdminActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case AdminActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        admin: action.payload.data,
        adminInputs: action.payload.data,
      }

    case AdminActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AdminActionType.CHANGE_ADMIN_INPUT_VALUE:
      return { ...state, adminInputs: { ...state.adminInputs, [action.payload.property]: action.payload.value } }

    case AdminActionType.CHANGE_ADMIN_USER_INPUT_VALUE:
      return { ...state, adminInputs: { ...state.adminInputs, user: { ...state.adminInputs.user, [action.payload.property]: action.payload.value } } }

    case AdminActionType.SET_ORIGINAL_ADMIN_VALUE:
      return { ...state, adminInputs: action.payload.isNew ? defaultState.adminInputs : state.admin }

    case AdminActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case AdminActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case AdminActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AdminActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case AdminActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `New Admin '${action.payload.data.name}' has been added successfully!`,
      }

    case AdminActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AdminActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case AdminActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: state.admins.filter(admin => admin.id !== action.payload.id),
      }

    case AdminActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
