import {MessageTemplateActionType} from "../../types/message-template"

const defaultState = {
    successMessage: null,
    errorMessage: null,
    loading: false,
    messageTemplates: [],
    messageTemplate: null,
    totalMessageTemplates: 0,
    messageTemplateInputs: {
        text: '',
        media: null,
        subject: '',
    },
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case MessageTemplateActionType.SET_PROPERTY_VALUE:
            return {
                ...state,
                [action.payload.property]: action.payload.value,
            }

        case MessageTemplateActionType.GET_LIST_API:
            return {
                ...state,
                loading: true,
            }

        case MessageTemplateActionType.GET_LIST_API_SUCCESS:
            return {
                ...state,
                loading: false,
                messageTemplates: action.payload.data,
            }

        case MessageTemplateActionType.GET_LIST_API_FAILURE:
            return { ...state, loading: false, errorMessage: action.payload.data }

        case MessageTemplateActionType.GET_BY_ID_API:
            return {
                ...state,
                loading: true,
            }

        case MessageTemplateActionType.GET_BY_ID_API_SUCCESS:
            return {
                ...state,
                loading: false,
                messageTemplate: action.payload.data,
                messageTemplateInputs: action.payload.data,
            }

        case MessageTemplateActionType.GET_BY_ID_API_FAILURE:
            return { ...state, loading: false, errorMessage: action.payload.data.message }

        case MessageTemplateActionType.CHANGE_MESSAGE_TEMPLATE_INPUT_VALUE:
            return { ...state, messageTemplateInputs: { ...state.messageTemplateInputs, [action.payload.property]: action.payload.value } }

        case MessageTemplateActionType.SET_ORIGINAL_MESSAGE_TEMPLATE_VALUE:
            return { ...state, messageTemplateInputs: action.payload.isNew ? defaultState.messageTemplateInputs : state.messageTemplateInputs }

        case MessageTemplateActionType.PUT_BY_ID_API:
            return {
                ...state,
                loading: true,
            }

        case MessageTemplateActionType.PUT_BY_ID_API_SUCCESS:
            return {
                ...state,
                loading: false,
                id: action.payload.data.id,
                successMessage: 'message.success',
            }

        case MessageTemplateActionType.PUT_BY_ID_API_FAILURE:
            return { ...state, loading: false, errorMessage: action.payload.data }

        case MessageTemplateActionType.POST_BY_ID_API:
            return {
                ...state,
                loading: true,
            }

        case MessageTemplateActionType.POST_BY_ID_API_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: `Message with text '${action.payload.data.text}' created successfully!`,
                id: null,
                messageTemplateInputs: defaultState.messageTemplateInputs,
            }

        case MessageTemplateActionType.POST_BY_ID_API_FAILURE:
            return { ...state, loading: false, errorMessage: action.payload.data }

        default: return { ...state }
    }
}
