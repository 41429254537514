import moment from 'moment'
import { CampaignActionType } from './../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  campaigns: [],
  campaign: null,
  campaignInputs: {
    name: '',
    end_date: moment(),
    start_date: moment(),
  },
  id: 0,

}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CampaignActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case CampaignActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case CampaignActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload.data,
        totalCampaigns: action.payload.total,
        campaignInputs: defaultState.campaignInputs,
      }

    case CampaignActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case CampaignActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case CampaignActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.payload.data, // TODO whats going on here, why are we using payload data at two places? difference between campaigns and campaignInputs
        campaignInputs: {
          ...action.payload.data,
        },
      }

    case CampaignActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case CampaignActionType.CHANGE_CAMPAIGN_INPUT_VALUE:
      return { ...state, campaignInputs: { ...state.campaignInputs, [action.payload.property]: action.payload.value } }

    case CampaignActionType.SET_ORIGINAL_CAMPAIGN_VALUE:
      return { ...state, campaignInputs: action.payload.isNew ? defaultState.campaignInputs : state.campaign }

    case CampaignActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case CampaignActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case CampaignActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case CampaignActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case CampaignActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `Campaign with name '${action.payload.data.name}' created successfully!`,
      }

    case CampaignActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
