import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getEventAPI, putEventAPI, setOriginalEventValue, postEventAPI, setEventPropertyValue,
  getReceiverRepliesByEventIdAPI,
} from './../../../effects/actions'
import { EventForm } from './../../../forms'
import { message } from 'antd'
import { Util } from './../../../utils'

const EventFormPage = ({
  eventInputs, successMessage, errorMessage,
  setOriginalEventValue, getEventAPI, setEventPropertyValue,
  putEventAPI, postEventAPI, loading, getReceiverRepliesByEventIdAPI,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getEventAPI(idParam)
      getReceiverRepliesByEventIdAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalEventValue(true)
    }
  }, [getEventAPI, getReceiverRepliesByEventIdAPI, idParam, setOriginalEventValue])

  useEffect(() => {
    if (successMessage !== null && isNew) {
      history.push({
        pathname: `/event`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setEventPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setEventPropertyValue])

  const onSave = () => {
    if (!isNew) {
      putEventAPI(eventInputs)
    } else {
      postEventAPI(eventInputs)
    }
  }

  const onCancel = () => {
    setOriginalEventValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Event" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <EventForm viewMode={viewMode} id={idParam}/>
    </LayoutAdmin>
  )
}

EventFormPage.propTypes = {
  getEventAPI: PropTypes.func,
  eventInputs: PropTypes.any,
  loading: PropTypes.bool,
  putEventAPI: PropTypes.func,
  setOriginalEventValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.string,
  setEventPropertyValue: PropTypes.func,
  getReceiverRepliesByEventIdAPI: PropTypes.func,
}

const mapStateToProps = ({ eventAPI }) => {
  const {
    eventInputs, loading,
    successMessage, id, errorMessage,
  } = eventAPI
  return {
    eventInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getEventAPI,
  putEventAPI,
  setOriginalEventValue,
  postEventAPI,
  setEventPropertyValue,
  getReceiverRepliesByEventIdAPI,
})(EventFormPage)
