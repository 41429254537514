export const BoxActionType = {
  SET_PROPERTY_VALUE: 'BOX.SET_PROPERTY_VALUE',
  GET_LIST_API: 'BOX.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'BOX.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'BOX.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'BOX.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'BOX.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'BOX.GET_BY_ID_API_FAILURE',
  CHANGE_BOX_INPUT_VALUE: 'BOX.CHANGE_BOX_INPUT_VALUE',
  PUT_BY_ID_API: 'BOX.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'BOX.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'BOX.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_BOX_VALUE: 'BOX.SET_ORIGINAL_BOX_VALUE',
  POST_BY_ID_API: 'BOX.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'BOX.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'BOX.POST_BY_ID_API_SUCCESS',
  GET_AVAILABLES_API: 'BOX.GET_AVAILABLES_API',
  GET_AVAILABLES_API_SUCCESS: 'BOX.GET_AVAILABLES_API_SUCCESS',
  GET_AVAILABLES_API_FAILURE: 'BOX.GET_AVAILABLES_API_FAILURE',
  PATCH_BY_ID_API: 'BOX.PATCH_BY_ID_API',
  PATCH_BY_ID_API_FAILURE: 'BOX.PATCH_BY_ID_API_FAILURE',
  PATCH_BY_ID_API_SUCCESS: 'BOX.PATCH_BY_ID_API_SUCCESS',
  DELETE_BY_ID_API: 'BOX.DELETE_BY_ID_API',
  DELETE_BY_ID_API_FAILURE: 'BOX.DELETE_BY_ID_API_FAILURE',
  DELETE_BY_ID_API_SUCCESS: 'BOX.DELETE_BY_ID_API_SUCCESS',
}
