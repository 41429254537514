import {Checkbox, Col, message as AntdMessage, Table, Row, Select} from "antd"
import React, {useEffect, useState} from "react"
import moment from "moment"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import styled from "styled-components"
import BoxModal from "../../../../modals/box"
import {
    getContactMeRequestAPI,
    putContactMeRequestAPI,
    setMembersRequestAPIPropertyValue,
} from "../../../../effects/actions"
import {defaultFormatDate} from "../../../../constants/date"
import MemberModal from "../../../../modals/member"
import SearchWithOption from "../../../../components/campaign/search-with-option"
import {isContactedMeOptions} from "../../constants/constant"

const {Option} = Select

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const ContactMeRequestsTab = ({contactMeList, getContactMeRequestAPI, contactMeLoading, totalContactMe, putContactMeRequestAPI, contactMeSuccessMessage, setMembersRequestAPIPropertyValue}) => {

    const [showBoxModal, setShowBoxModal] = useState(false)
    const [showMemberModal, setShowMemberModal] = useState(false)

    const [selectedBox, setSelectedBox] = useState()
    const [selectedMember, setSelectedMember] = useState()

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [searchFilter, setSearchFilter] = useState({ name: '', value: ''})

    const contactMeRequestListColumns = [
        {
            title: 'Mark handled',
            dataIndex: 'is_contacted',
            render: (value, data) => <Checkbox onChange={() => handleMarkAsHandled(data)} checked={value}/>,
        },
        {
            title: 'Id',
            dataIndex: 'id',
            render: (message, data) => data.id,
        }, {
            title: 'Member',
            dataIndex: 'member',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('member_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (member) => (
                <StyledLink onClick={() => handleMemberCellClick(member)}>
                    {member.id} - {member.name}
                </StyledLink>
            ),
        }, {
            title: 'Box',
            dataIndex: 'box',
            render: (_, data) => {
                if (data && data.member && data.member.box) {
                   return (
                       <StyledLink onClick={() => handleBoxCellClick(data.member.box)}>
                           {data.member.box.id} - {data.member.box.name}
                       </StyledLink>
                   )
                } else {
                    return '-'
                }
            },
        }, {
            title: 'Contact me',
            dataIndex: 'contact_me',
            render: () => 'Yes',
        }, {
            title: 'Last requested at',
            dataIndex: 'updated_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('updated_at')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'First requested at',
            dataIndex: 'created_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('created_at')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
    ]

    useEffect(() => {
        if (contactMeSuccessMessage) {
            AntdMessage.success(contactMeSuccessMessage)
            setMembersRequestAPIPropertyValue({property: 'contactMeSuccessMessage', value: null})
        }
    }, [contactMeSuccessMessage, setMembersRequestAPIPropertyValue])

    const handleMarkAsHandled = (data) => {
        putContactMeRequestAPI({
            id: data.id,
            is_contacted: !data.is_contacted,
        })
    }

    const handleBoxCellClick = (data) => {
        console.log(data)
        setShowBoxModal(true)
        setSelectedBox(data)
    }

    const handleBoxDoneCallBack = () => {
        setShowBoxModal(false)
        setSelectedBox(null)
    }

    const handleMemberCellClick = (data) => {
        setShowMemberModal(true)
        setSelectedMember(data)
    }

    const handleMemberDoneCallback = () => {
        setShowMemberModal(false)
        setSelectedMember(null)
    }

    const updateTableDimensions = (pageNum, pageS) => {
        if(pageNum!==pageNumber) {
            setPageNumber(pageNum)
        }
        if(pageS!==pageSize) {
            setPageSize(pageS)
        }
    }

    const onSearch = (name, value) => {
        setSearchFilter({ name, value})
    }

    useEffect(() => {
        getContactMeRequestAPI({
            queryParams: {limit: pageSize, page: pageNumber, orderKey, orderDirection, filter: searchFilter},
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getContactMeRequestAPI, pageSize, pageNumber, orderKey, orderDirection, searchFilter.name, searchFilter.value])

    return (
        <div style={{width: '100%'}}>
            <Row>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'is_contacted'}
                        placeholder="Is Contacted?"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={isContactedMeOptions.map(d => <Option key={d.id} value={d.value} id={d.id} label={d.id}>{d.id}</Option>)}
                    />
                </Col>
            </Row>
            <div>
                <Table
                    dataSource={contactMeList}
                    columns={contactMeRequestListColumns}
                    loading={contactMeLoading}
                    rowKey="id"
                    pagination={{ total: totalContactMe, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
                />
                <BoxModal
                    visible={showBoxModal}
                    doneCallback={handleBoxDoneCallBack}
                    boxData={selectedBox}
                />
                <MemberModal
                    visible={showMemberModal}
                    doneCallback={handleMemberDoneCallback}
                    memberData={selectedMember}
                />
            </div>
        </div>
    )
}

ContactMeRequestsTab.propTypes = {
    contactMeErrorMessage: PropTypes.string,
    contactMeSuccessMessage: PropTypes.string,
    contactMeLoading: PropTypes.bool,
}

const mapStateToProps = ({ membersRequestAPI }) => {
    const { contactMeList, contactMeErrorMessage, contactMeSuccessMessage, contactMeLoading, totalContactMe } = membersRequestAPI

    return {
        contactMeErrorMessage,
        contactMeSuccessMessage,
        contactMeLoading,
        contactMeList,
        totalContactMe,
    }
}

export default connect(mapStateToProps, {
    getContactMeRequestAPI,
    putContactMeRequestAPI,
    setMembersRequestAPIPropertyValue,
})(ContactMeRequestsTab)

