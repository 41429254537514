import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { LayoutAdmin } from './../../../components'
import { getMessageReceiverRepliesAPI } from './../../../effects/actions'

const MessageRepliesPage = ({
  messageReceiverRepliesListColumns, messageReceiverReplies, loading,
}) => {
  useEffect(() => {
    getMessageReceiverRepliesAPI()
  }, [])

  return (
    <LayoutAdmin>
      <Table dataSource={messageReceiverReplies} columns={messageReceiverRepliesListColumns} loading={loading} rowKey="id" />
    </LayoutAdmin>
  )
}

MessageRepliesPage.propTypes = {
  getMessageReceiverRepliesAPI: PropTypes.func,
  messageReceiverReplies: PropTypes.array,
  messageReceiverRepliesListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ messageAPI }) => {
  const {
    messageReceiverReplies, loading, messageReceiverRepliesListColumns,
  } = messageAPI
  return {
    messageReceiverReplies,
    messageReceiverRepliesListColumns,
    loading,
  }
}

export default
connect(mapStateToProps, {
  getMessageReceiverRepliesAPI,
})(MessageRepliesPage)
