import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Button, Modal, Table, message as AntMessage} from 'antd'
import PropTypes from 'prop-types'
import {LayoutAdmin} from './../../../components'
import {deleteLedSequenceAPI, getLedSequenceListAPI, setLedSequencePropertyValue} from '../../../effects/actions'
import {EyeOutlined} from "@ant-design/icons"
import styled from "styled-components"

const ColorCell = styled.div.attrs(
    ({ color }) => ({
      style: {
        background:  color ? color : 'white',
      },
    }),
)`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`

const LedSequenceListPage = ({ledSequences, loading, totalLedSequences, getLedSequenceListAPI, deleteLedSequenceAPI, successMessage}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const [showLedSequenceDeleteModal, setShowLedSequenceDeleteModal] = useState(false)
  const [ledSequenceToDelete, setLedSequenceToDelete] = useState({})

  const history = useHistory()

  useEffect(() => {
    getLedSequenceListAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getLedSequenceListAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setLedSequencePropertyValue('successMessage', null)
    }
  }, [successMessage])

  const ledSequenceListColumnsCustom = [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'action',
      width: 50,
      render: (id) => (
          <Link to={`/led-sequence/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>
      ),
    },
    {
      title: 'Sequence Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('type')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Color 1',
      dataIndex: 'color_1',
      key: 'color_1',
      render: (colorVal, data) => {
        return data.type === 'rainbow' ? 'N/A' : <ColorCell color={colorVal}/>
      },
    },
    {
      title: 'Color 2',
      dataIndex: 'color_2',
      key: 'color_2',
      render: (colorVal, data) => {
        return data.type === 'rainbow' ? 'N/A' : <ColorCell color={colorVal}/>
      },
    },
    {
      title: 'Brightness 1',
      dataIndex: 'brightness_1',
      key: 'brightness_1',
      render: (val, data) => {
        return  data.type === 'rainbow' ? 'N/A' : val
      },
    },
    {
      title: 'Brightness 2',
      dataIndex: 'brightness_2',
      key: 'brightness_2',
      render: (val, data) => {
        return data.type === 'rainbow' ? 'N/A' : val
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button danger disabled={!record.isDeletable} onClick={() => handleDeleteConfirmation(record)}>Delete Sequence</Button>
      ),
    },
  ]

  const handleDeleteConfirmation = (ledSequence) => {
    setLedSequenceToDelete(ledSequence)
    setShowLedSequenceDeleteModal(true)
  }

  const cancelLedSequenceDelete = () => {
    setLedSequenceToDelete({})
    setShowLedSequenceDeleteModal(false)
  }

  const handleDeleteLedSequence = () => {
    setShowLedSequenceDeleteModal(false)
    deleteLedSequenceAPI(ledSequenceToDelete)
  }

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }
  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/led-sequence/new')}>New</Button>
      <Table
          dataSource={ledSequences}
          columns={ledSequenceListColumnsCustom}
          pagination={{ total: totalLedSequences, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading}
          rowKey="id"
      />
      <Modal
          title={<b>Are you sure?</b>}
          visible={showLedSequenceDeleteModal}
          onOk={handleDeleteLedSequence}
          onCancel={cancelLedSequenceDelete}
          okText={'Delete'}
          okButtonProps={{ type: 'danger' }}
      >Delete Sequence {ledSequenceToDelete.type} - {ledSequenceToDelete.name}</Modal>
    </LayoutAdmin>
  )
}

LedSequenceListPage.propTypes = {
  getLedSequenceListAPI: PropTypes.func,
  ledSequences: PropTypes.array,
  loading: PropTypes.bool,
  removed: PropTypes.any,
  deleteLedSequenceAPI: PropTypes.func,
}

const mapStateToProps = ({ ledSequenceAPI }) => {
  const {
    ledSequences, loading, totalLedSequences, removed, successMessage,
  } = ledSequenceAPI
  return {
    ledSequences,
    loading,
    totalLedSequences,
    successMessage,
    removed,
  }
}

export default
connect(mapStateToProps, {
  getLedSequenceListAPI,
  deleteLedSequenceAPI,
  setLedSequencePropertyValue,
})(LedSequenceListPage)
