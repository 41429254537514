import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'antd'

const TextStyled = styled(Typography.Text)`
    color: ${(props) => props.color}
`

const TextComponent = ({ children, ...props }) => {
  return (
    <TextStyled {...props}>
      {children}
    </TextStyled>
  )
}

TextComponent.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
}

TextComponent.defaultProps = {
  color: '#000',
}

export default TextComponent
