import {Checkbox, Col, message as AntdMessage, Row, Table, Select} from "antd"
import MessageModal from "../../../../modals/message"
import EventModal from "../../../../modals/event"
import MessageTemplateModal from "../../../../modals/message-template"
import React, {useEffect, useState} from "react"
import moment from "moment"
import {defaultFormatDate} from "../../../../constants/date"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import styled from "styled-components"
import BoxModal from "../../../../modals/box"
import CampaignModal from "../../../../modals/campaign"
import {
    getMembersRequestAPI,
    putMembersRequestAPI,
    setMembersRequestAPIPropertyValue,
} from "../../../../effects/actions"
import SearchWithOption from "../../../../components/campaign/search-with-option"
import {isContactedMeOptions, statusOptions} from "../../constants/constant"

const {Option} = Select

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const MessageRequestsTab = ({messageRequests, getMembersRequestAPI, messageLoading, totalRequests, putMembersRequestAPI, messageSuccessMessage, responseOptions, setMembersRequestAPIPropertyValue}) => {

    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)
    const [showMessageTemplateModal, setShowMessageTemplateModal] = useState(false)
    const [showBoxModal, setShowBoxModal] = useState(false)
    const [showCampaignModal, setShowCampaignModal] = useState(false)

    const [selectedNotification, setSelectedNotification] = useState()
    const [selectedEvent, setSelectedEvent] = useState()
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState()
    const [selectedBox, setSelectedBox] = useState()
    const [selectedCampaign, setSelectedCampaign] = useState()

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [searchFilter, setSearchFilter] = useState({ name: '', value: ''})

    const memberRepliesListColumns = [
        {
            title: 'Mark handled',
            dataIndex: 'is_contacted',
            render: (value, data) => <Checkbox onChange={() => handleMarkAsHandled(data)} checked={value}/>,
        },
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message, data) => data.id,
        }, {
            title: 'Campaign',
            dataIndex: 'message',
            render: (message) => (
                <StyledLink onClick={() => handleCampaignCellClick(message.campaign)}>
                    {message.campaign.id} - {message.campaign.name}
                </StyledLink>
            ),
        }, {
            title: 'Is notification?',
            dataIndex: 'isNotification',
            render: (message, data) => data && data.message && data.message.is_notification ? "Yes" : "No",
        },
        {
            title: 'Notification',
            dataIndex: 'message',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message, data) => (
                <StyledLink onClick={() => handleNotificationCellClick(data)}>
                    {message.id}
                </StyledLink>
            ),
        },
        {
            title: 'Message Template',
            dataIndex: 'message',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_template_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message) => {
                if(message && message.message_template_id){
                    return (
                        <StyledLink onClick={() => handleMessageTemplateCellClick(message)}>
                            {message.message_template_id}
                        </StyledLink>
                    )
                }
                return '-'
            },
        },
        {
            title: 'Event',
            dataIndex: 'message',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('event_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message) => {
                if (message && message.event_id){
                    return (
                        <StyledLink onClick={() => handleEventCellClick(message)}>
                            {message.event_id}
                        </StyledLink>
                    )
                } else {
                    return '-'
                }
            },
        }, {
            title: 'Box',
            dataIndex: 'box',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('box_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (box) => (
                <StyledLink onClick={() => handleBoxCellClick(box)}>
                    {box.id} - {box.name}
                </StyledLink>
            ),
        }, {
            title: 'Show contact me',
            dataIndex: 'show_contact_me',
            render: (_, data) => data && data.message && data.message.show_contact_me ? "Yes" : "No",
        }, {
        }, {
            title: 'Contact me',
            dataIndex: 'contact_me',
            render: (value, data) => {
                if (data && data.message && data.message.show_contact_me) {
                    return value ? "Yes" : "No"
                } else {
                    return "N/A"
                }
            },
        }, {
            title: 'Response',
            dataIndex: 'status',
            render: (status, record) => {
                if(status.id === 5){
                    return status.name
                }else if(status.id === 3){
                    return record && record.message && record.message && record.message.action_one_text
                }else if(status.id === 4) {
                    return record && record.message && record.message && record.message.action_two_text
                }else{
                    return '-'
                }
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('status_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Replied at',
            dataIndex: 'updated_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Submitted at',
            dataIndex: 'message.created_at',
            render: (date) => {
                return moment(date).format(defaultFormatDate)
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('created_at')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
    ]
    useEffect(() => {
        if (messageSuccessMessage) {
            AntdMessage.success(messageSuccessMessage)
            setMembersRequestAPIPropertyValue({property: 'messageSuccessMessage', value: null})
        }
    }, [setMembersRequestAPIPropertyValue, messageSuccessMessage])

    const handleMarkAsHandled = (data) => {
        putMembersRequestAPI({
            id: data.id,
            is_contacted: !data.is_contacted,
        })
    }

    const handleNotificationCellClick = (data) => {
        setShowNotificationModal(true)
        setSelectedNotification(data.message)
    }

    const handleNotificationDoneCallback = () => {
        setShowNotificationModal(false)
        setSelectedNotification(null)
    }

    const handleEventCellClick = (message) => {
        console.log(message.event)
        setShowEventModal(true)
        setSelectedEvent(message.event)
    }

    const handleEventDoneCallback = () => {
        setShowEventModal(false)
        setSelectedEvent(null)
    }

    const handleMessageTemplateCellClick = (message) => {
        setShowMessageTemplateModal(true)
        setSelectedMessageTemplate(message.template)
    }

    const handleMessageTemplateDoneCallback = () => {
        setShowMessageTemplateModal(false)
        setSelectedMessageTemplate(null)
    }

    const handleCampaignCellClick = (data) => {
        setShowCampaignModal(true)
        setSelectedCampaign(data)
    }

    const handleCampaignDoneCallback = () => {
        setShowCampaignModal(false)
        setSelectedCampaign(null)
    }

    const handleBoxCellClick = (data) => {
        setShowBoxModal(true)
        setSelectedBox(data)
    }

    const handleBoxDoneCallBack = () => {
        setShowBoxModal(false)
        setSelectedBox(null)
    }


    const updateTableDimensions = (pageNum, pageS) => {
        if(pageNum!==pageNumber) {
            setPageNumber(pageNum)
        }
        if(pageS!==pageSize) {
            setPageSize(pageS)
        }
    }

    const onSearch = (name, value) => {
        setSearchFilter({ name, value})
    }

    useEffect(() => {
        getMembersRequestAPI({
            queryParams: {limit: pageSize, page: pageNumber, orderKey, orderDirection, filter: searchFilter},
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getMembersRequestAPI, pageSize, pageNumber, orderKey, orderDirection, searchFilter.name, searchFilter.value])

    return (
        <div>
            <Row>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'is_contacted'}
                        placeholder="Is Contacted?"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={isContactedMeOptions.map(d => <Option key={d.id} value={d.value} id={d.id} label={d.id}>{d.id}</Option>)}
                    />
                </Col>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'response_text'}
                        placeholder="Response text"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={responseOptions.map(d => <Option key={d.id} value={d.value} id={d.id} label={d.id}>{d.id}</Option>)}
                    />
                </Col>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'status_id'}
                        placeholder="Response type"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={statusOptions.map(d => <Option key={d.id} value={d.value} id={d.id} label={d.id}>{d.id}</Option>)}
                    />
                </Col>
            </Row>
            <div>
                <Table
                    dataSource={messageRequests}
                    columns={memberRepliesListColumns}
                    loading={messageLoading}
                    rowKey="id"
                    scroll={{ x: 1800 }}
                    pagination={{ total: totalRequests, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
                />
                <MessageModal
                    visible={showNotificationModal}
                    doneCallback={handleNotificationDoneCallback}
                    messageData={selectedNotification}
                />
                <EventModal
                    visible={showEventModal}
                    doneCallback={handleEventDoneCallback}
                    eventData={selectedEvent}
                />
                <MessageTemplateModal
                    visible={showMessageTemplateModal}
                    doneCallback={handleMessageTemplateDoneCallback}
                    messageTemplateData={selectedMessageTemplate}
                />
                <BoxModal
                    visible={showBoxModal}
                    doneCallback={handleBoxDoneCallBack}
                    boxData={selectedBox}
                />
                <CampaignModal
                    visible={showCampaignModal}
                    doneCallback={handleCampaignDoneCallback}
                    campaignData={selectedCampaign}
                />
            </div>
        </div>
    )
}

MessageRequestsTab.propTypes = {
    messageErrorMessage: PropTypes.string,
    messageSuccessMessage: PropTypes.string,
    messageLoading: PropTypes.bool,
}

const mapStateToProps = ({ membersRequestAPI }) => {
    const { messageRequests, messageErrorMessage, messageSuccessMessage, messageLoading, metaData ,totalRequests } = membersRequestAPI

    return {
        messageErrorMessage,
        messageSuccessMessage,
        messageLoading,
        messageRequests,
        responseOptions: metaData.responseOptions,
        totalRequests,
    }
}

export default connect(mapStateToProps, {
    getMembersRequestAPI,
    putMembersRequestAPI,
    setMembersRequestAPIPropertyValue,
})(MessageRequestsTab)

