import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { message } from 'antd'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getMessageTemplateAPI, putMessageTemplateAPI,
  setOriginalMessageTemplateValue, postMessageTemplateAPI, setMessageTemplatePropertyValue,
} from './../../../effects/actions'
import { MessageTemplateForm } from './../../../forms'
import { Util } from './../../../utils'

const MessageTemplateFormPage = ({
  messageTemplateInputs, successMessage, errorMessage, setOriginalMessageTemplateValue,
  getMessageTemplateAPI, setMessageTemplatePropertyValue, putMessageTemplateAPI,
  postMessageTemplateAPI, loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getMessageTemplateAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalMessageTemplateValue(true)
    }
  }, [getMessageTemplateAPI, idParam, setOriginalMessageTemplateValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage !== null && isNew) {
      history.push({
        pathname: `/message`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setMessageTemplatePropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, idParam, setMessageTemplatePropertyValue])

  const onSave = () => {
    if (!isNew) {
      putMessageTemplateAPI(messageTemplateInputs)
    } else {
      postMessageTemplateAPI(messageTemplateInputs)
    }
  }

  const onCancel = () => {
    setOriginalMessageTemplateValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Message Template" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <MessageTemplateForm viewMode={viewMode} />
    </LayoutAdmin>
  )
}

MessageTemplateFormPage.propTypes = {
  getMessageTemplateAPI: PropTypes.func,
  messageTemplateInputs: PropTypes.any,
  loading: PropTypes.bool,
  putMessageTemplateAPI: PropTypes.func,
  setOriginalMessageTemplateValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.string,
  setMessageTemplatePropertyValue: PropTypes.func,
}

const mapStateToProps = ({ messageTemplateAPI }) => {
  const {
    messageTemplateInputs, loading,
    successMessage, id, errorMessage,
  } = messageTemplateAPI
  return {
    messageTemplateInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getMessageTemplateAPI,
  putMessageTemplateAPI,
  setOriginalMessageTemplateValue,
  postMessageTemplateAPI,
  setMessageTemplatePropertyValue,
})(MessageTemplateFormPage)
