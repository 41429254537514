import React from 'react'
import { Breadcrumb, Card } from 'antd'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'

const SpanStyled = styled.span`
  text-transform: capitalize;
`

const BreadcrumbComponent = () => {
  const history = useHistory()

  const { pathname } = history.location

  const paths = pathname.split('/')

  const getLink = (index) => {
    let link = ''
    paths.map((x, i) => {
      if (i <= index) {
        const withSlash = i !== 0 ? '/' : ''
        link += `${withSlash}${x}`
      }
      return true
    })

    return link
  }

  return (
    <Card size="small" style={{ marginBottom: 16, borderRadius: 8 }}>
      <Breadcrumb>
        {paths.map((path, index) => {
          if (path !== '') {
            return (<Breadcrumb.Item key={index}>{(paths.length - 1) === index ? <SpanStyled>{path}</SpanStyled> : <Link to={getLink(index)}><SpanStyled>{path}</SpanStyled></Link>}</Breadcrumb.Item>)
          }
          return null
        })}

      </Breadcrumb>
    </Card>

  )
}

export default BreadcrumbComponent
