import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {Button, message as AntMessage, Modal, Table} from 'antd'
import { useHistory } from 'react-router'
import { LayoutAdmin } from './../../../components'
import { deleteSplashAPI, getSplashesAPI, putSplashAPI, setSplashPropertyValue, repositionSplashAPI} from '../../../effects/actions'
import { Link } from "react-router-dom"
import { EyeOutlined } from "@ant-design/icons"
import { Util } from "../../../utils"
import styled from "styled-components"
import DraggableBodyRow from "../../../components/draggableBodyRow"

const ButtonStyled = styled(Button)`
    margin: 4px
`
const SplashListPage = ({
  splashes, loading, getSplashesAPI, putSplashAPI, deleteSplashAPI, setSplashPropertyValue, successMessage,repositionSplashAPI,
}) => {
  const [orderKey, setOrderKey] = useState('order')
  const [orderDirection, setOrderDirection] = useState('asc')

  const [showSplashDeleteModal, setShowSplashDeleteModal] = useState(false)
  const [showSplashStatusModal, setShowSplashStatusModal] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [selectedSplash, setSelectedSplash] = useState({})

  const history = useHistory()

  const splashListColumns = [
    {
      title: 'View',
      key: 'id',
      width: 50,
      render: (splash) => {
        return (
            <Link to={`/splash/${splash.id}`} key={splash.id}>
              <Button>
                <EyeOutlined />
              </Button>
            </Link>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('type')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('message')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('order')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Time (secs)',
      dataIndex: 'time_length',
      key: 'time_length',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('time_length')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (time_length) => time_length / 1000,
    },
    {
      title: 'Status',
      dataIndex: 'is_enabled',
      key: 'is_enabled',
      render: (is_enabled) => {
        return is_enabled ? 'Enabled' : 'Disabled'
      },
    },
    {
      title: 'Media',
      dataIndex: 'media',
      width: 200,
      render: (media) => {
        return media !== null && Util.renderMedia(media, "auto")
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <div>
            <ButtonStyled danger /*disabled={record.active}*/ onClick={() => handleDeleteConfirmation(record)}>Delete Splash</ButtonStyled>
            <ButtonStyled primary /*disabled={record.active}*/ onClick={() => handleStatusConfirmation(record)}>Toggle Status</ButtonStyled>
          </div>
      ),
    },
  ]

  useEffect(() => {
    getSplashesAPI({orderKey, orderDirection})
  }, [getSplashesAPI, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setSplashPropertyValue('successMessage', null)
    }
  }, [setSplashPropertyValue, successMessage])

  const handleStatusConfirmation = (splash) => {
    setSelectedSplash(splash)
    setShowSplashStatusModal(true)
  }
  const handleDeleteConfirmation = (splash) => {
    setSelectedSplash(splash)
    setShowSplashDeleteModal(true)
  }

  const cancelSplashDelete = () => {
    setSelectedSplash({})
    setShowSplashDeleteModal(false)
  }

  const handleDeleteSplash = () => {
    setShowSplashDeleteModal(false)
    deleteSplashAPI(selectedSplash)
  }

  const cancelSplashStatus = () => {
    setSelectedSplash({})
    setShowSplashStatusModal(false)
  }

  const handleSortingOnOrderCol = () => {
    setShowOrderModal(false)
    setOrderKey('order')
    setOrderDirection('asc')
  }

  const cancelSortingOnOrderCol = () => {
    setShowOrderModal(false)
  }

  const handleStatusSplash = () => {
    setShowSplashStatusModal(false)
      putSplashAPI({
        active: selectedSplash.active,
        id: selectedSplash.id,
        media: selectedSplash.media,
        message: selectedSplash.message,
        order: selectedSplash.order,
        time_length: selectedSplash.time_length / 1000,
        type: selectedSplash.type,
        is_enabled: !selectedSplash.is_enabled,
      })
  }

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  }

  const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        if (orderKey !== 'order') {
          setShowOrderModal(true)
          return
        }
        repositionSplashAPI({
          fromIndex: dragIndex,
          toIndex: hoverIndex,
          orderKey,
          orderDirection,
        })
      },
      [orderDirection, orderKey, repositionSplashAPI],
  )

  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/splash/new')}>New</Button>
      <DndProvider backend={HTML5Backend}>
        <Table
            dataSource={splashes}
            columns={splashListColumns}
            pagination={false}
            loading={loading}
            rowKey="id"
            rowClassName={(record) => {
              if (!record.is_enabled) {
                return 'light-red-colored-row'
              }
            }}
            components={components}
            onRow={(record, index) => ({
              index,
              moveRow,
            })}
        />
      </DndProvider>
      <Modal
          title={<b>Splash list ordering issue</b>}
          visible={showOrderModal}
          onOk={handleSortingOnOrderCol}
          onCancel={cancelSortingOnOrderCol}
          okText={'Sort on order column'}
          okButtonProps={{ type: 'primary' }}
      >The table must be sorted by Order prior to changing the order of elements. Do you want to sort the table by Order?</Modal>
      <Modal
          title={<b>Are you sure?</b>}
          visible={showSplashDeleteModal}
          onOk={handleDeleteSplash}
          onCancel={cancelSplashDelete}
          okText={'Delete'}
          okButtonProps={{ type: 'danger' }}
      >Delete {selectedSplash.type} splash: {selectedSplash.name} - {selectedSplash.message}</Modal>
      <Modal
          title={<b>Are you sure?</b>}
          visible={showSplashStatusModal}
          onOk={handleStatusSplash}
          onCancel={cancelSplashStatus}
          okText={selectedSplash.is_enabled ? 'Disable' : 'Enable'}
          okButtonProps={{ type: selectedSplash.is_enabled ? 'danger' : 'primary' }}
      >{selectedSplash.is_enabled ? 'Disable' : 'Enable'} {selectedSplash.type} splash: {selectedSplash.name} - {selectedSplash.message}</Modal>
    </LayoutAdmin>
  )
}

SplashListPage.propTypes = {
  getSplashesAPI: PropTypes.func,
  splashes: PropTypes.array,
  splashListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ splashAPI }) => {
  const {
    splashes, loading, splashListColumns, totalSplashes, successMessage,
  } = splashAPI
  return {
    splashes,
    splashListColumns,
    totalSplashes,
    successMessage,
    loading,
  }
}

export default
connect(mapStateToProps, {
  getSplashesAPI,
  deleteSplashAPI,
  putSplashAPI,
  setSplashPropertyValue,
  repositionSplashAPI,
})(SplashListPage)
