import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row, Switch} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const MessageModal = ({visible, doneCallback, messageData}) => {
    const history =  useHistory()
    if (_.isEmpty(messageData)) { return null }
    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Message Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Message" icon={<EditOutlined />} onClick={() => history.push(`/notification/${messageData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12,0]}>
                    <Col span={12}>
                        <Form.Item label={'Led Sequence'} labelCol={{span: 24}}>
                            <Input
                                name={'ledSequence'}
                                type={'text'}
                                value={messageData.led_sequence.type}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={4}>
                        <Form.Item label="Show contact me" labelCol={{ span: 24 }}>
                            <Switch name="show_contact_me" size="large" checked={messageData.show_contact_me} disabled />
                        </Form.Item>
                    </Col>
                    {messageData.type_id === "event" && <Col span={4}>
                        <Form.Item label="Show learn more" labelCol={{ span: 24 }}>
                            <Switch name="show_learn_more" size="large" checked={messageData.show_learn_more} disabled />
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={2}>
                        <Form.Item label="Show" labelCol={{ span: 24 }}>
                            <Switch name="action_one_show" size="large" checked={messageData.action_one_show} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Action 1 text" labelCol={{ span: 24 }}>
                            <Input name="action_one_text" type="text" placeholder="Action 1 text" size="large" value={messageData.action_one_text} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Action 1 text color" labelCol={{ span: 24 }}>
                            <Input name="action_one_color" type="color" placeholder="Action 1 text color" size="large" value={messageData.action_one_color} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Action 1 background color" labelCol={{ span: 24 }}>
                            <Input name="action_one_background" type="color" placeholder="Action 1 background color" size="large" value={messageData.action_one_background} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Action 1 confirmation text" labelCol={{ span: 24 }}>
                            <Input name="action_one_confirmation_message" type="text" placeholder="Action 1 confirmation text" size="large" value={messageData.action_one_confirmation_message} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label="Show" labelCol={{ span: 24 }}>
                            <Switch name="action_two_show" size="large" checked={messageData.action_two_show} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Action 2 text" labelCol={{ span: 24 }}>
                            <Input name="action_two_text" type="text" placeholder="Action 2 text" size="large" value={messageData.action_two_text} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Action 2 text color" labelCol={{ span: 24 }}>
                            <Input name="action_two_color" type="color" placeholder="Action 2 text color" size="large" value={messageData.action_two_color} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Action 2 background color" labelCol={{ span: 24 }}>
                            <Input name="action_two_background" type="color" placeholder="Action 2 background color" size="large" value={messageData.action_two_background} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Action 2 confirmation text" labelCol={{ span: 24 }}>
                            <Input name="action_two_confirmation_message" type="text" placeholder="Action 2 confirmation text" size="large" value={messageData.action_two_confirmation_message} disabled />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

MessageModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    messageData: PropTypes.object,
}

export default MessageModal
