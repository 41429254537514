import { REHYDRATE } from 'redux-persist'
import { AuthActionType } from './../../types'

const defaultState = {
  loggedUser: null,
  validToken: false,
  successMessage: null,
  errorMessage: null,
  loading: false,
  token: null,
  signInInputs: {
    email: '',
    password: '',
  },
  verificationCodeSent: false,
  resetPasswordInputs: {
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: '',
  },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        token:
                action.payload && action.payload.authAPI
                  ? action.payload.authAPI.token
                  : defaultState.token,
        loggedUser:
                action.payload && action.payload.authAPI
                  ? action.payload.authAPI.loggedUser
                  : defaultState.loggedUser,
      }

    case AuthActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case AuthActionType.SIGN_IN_API:
      return {
        ...state,
        loading: true,
      }

    case AuthActionType.SIGN_IN_API_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedUser: action.payload.data.user,
        token: action.payload.data.access_token.token,
        signInInputs: defaultState.signInInputs,
        successMessage: 'success',
      }

    case AuthActionType.SIGN_IN_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AuthActionType.CHANGE_SIGN_IN_INPUT_VALUE:
      return { ...state, signInInputs: { ...state.signInInputs, [action.payload.property]: action.payload.value } }

    case AuthActionType.RESET_PASSWORD_API:
      return {
        ...state,
        loading: true,
      }

    case AuthActionType.RESET_PASSWORD_API_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordInputs: defaultState.resetPasswordInputs,
        successMessage: 'success',
      }

    case AuthActionType.RESET_PASSWORD_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AuthActionType.SEND_VERIFICATION_CODE_API:
      return { ...state, loading: true }

    case AuthActionType.SEND_VERIFICATION_CODE_API_SUCCESS:
      return { ...state, loading: false, verificationCodeSent: true }

    case AuthActionType.SEND_VERIFICATION_CODE_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case AuthActionType.CHANGE_RESET_PASSWORD_INPUT_VALUE:
      return { ...state, resetPasswordInputs: { ...state.resetPasswordInputs, [action.payload.property]: action.payload.value } }

    case AuthActionType.SIGN_OUT_API:
      return { ...state, loggedUser: defaultState.loggedUser, accessToken: defaultState.accessToken }

    default: return { ...state }
  }
}
