import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Card, Row, Col, Input, Space, message,
} from 'antd'
import { Button } from './../../components'
import {
  sendVerificationCodeAPI,
  changeResetPasswordInputValue,
  setAuthPropertyValue,
  resetPasswordAPI,
} from './../../effects/actions'
import {Link} from "react-router-dom"

const CardStyled = styled(Card)`
  max-width: 400px;
`

const ForgotContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  margin: 24px 0 0 0;
  height: 40px;
  width: 100%;
`

const LinkStyled = styled.div`
  color: #2f54eb;
  cursor: pointer;
`

const ForgotPasswordForm = ({
  loading, resetPasswordInputs, sendVerificationCodeAPI, changeResetPasswordInputValue, errorMessage, setAuthPropertyValue,
  successMessage, verificationCodeSent, resetPasswordAPI,
}) => {
  const onSubmit = () => {
    if (resetPasswordInputs.email === '') {
      message.warning('Email can\'t be empty')
    } else {
      sendVerificationCodeAPI({
        email: resetPasswordInputs.email,
      })
    }
  }

  const onUpdate = () => {
    if (resetPasswordInputs.verificationCode === '') {
      message.warning('Please enter the verification code')
    } else if (!resetPasswordInputs.password || !resetPasswordInputs.confirmPassword) {
      message.warning('Please enter both password and confirm password')
    } else if (resetPasswordInputs.password !== resetPasswordInputs.confirmPassword) {
      message.error('Passwords doesnt match!')
    } else {
      resetPasswordAPI(resetPasswordInputs)
    }
  }

  const onChangeInput = (e) => {
    const { name, value } = e.target
    changeResetPasswordInputValue(name, value)
  }

  const resendVerificationCode = () => {
    sendVerificationCodeAPI({
      email: resetPasswordInputs.email,
    })
  }

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(errorMessage)
      setAuthPropertyValue('errorMessage', null)
    } else if (successMessage !== null) {
      window.location.replace('/home')
      setAuthPropertyValue('successMessage', null)
    }
  })

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={20} sm={16} md={12} lg={8} xl={5}>
        <CardStyled title={!verificationCodeSent ? "Forgot Password" : "Update Your Password"} bordered={false}>
          {
            !verificationCodeSent ? (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Input name="email" type="text" placeholder="Email" size="large" onChange={onChangeInput} />
                  <StyledButton label="Send verification code" full="true" onClick={onSubmit} loading={loading} disabled={loading} />
                  <ForgotContainer>
                    <Link to={'/sign-in'}>Sign-in</Link>
                  </ForgotContainer>
                </Space>
            ) : (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Input name="verificationCode" type="text" placeholder="Verification Code" size="large" onChange={onChangeInput} />
                  <Input name="password" type="text" placeholder="Password" size="large" onChange={onChangeInput} />
                  <Input name="confirmPassword" type="text" placeholder="Confirm Password" size="large" onChange={onChangeInput} />
                  <StyledButton label="Update" full="true" onClick={onUpdate} loading={loading} disabled={loading} />
                  <ForgotContainer>
                    <LinkStyled onClick={resendVerificationCode}>Resend Verification Code</LinkStyled>
                  </ForgotContainer>
                </Space>
            )
          }
        </CardStyled>
      </Col>
    </Row>
  )
}

ForgotPasswordForm.propTypes = {
  changeResetPasswordInputValue: PropTypes.func,
  sendVerificationCodeAPI: PropTypes.func,
  resetPasswordInputs: PropTypes.object,
  verificationCodeSent: PropTypes.bool,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  setAuthPropertyValue: PropTypes.func,
  successMessage: PropTypes.string,
}

const mapStateToProps = ({ authAPI }) => {
  const {
    errorMessage, successMessage, loading, resetPasswordInputs, verificationCodeSent,
  } = authAPI
  return {
    errorMessage,
    successMessage,
    loading,
    resetPasswordInputs,
    verificationCodeSent,
  }
}

export default
connect(mapStateToProps, {
  sendVerificationCodeAPI,
  changeResetPasswordInputValue,
  setAuthPropertyValue,
  resetPasswordAPI,
})(ForgotPasswordForm)
