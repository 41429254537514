import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout } from 'antd'

const HeaderStyled = styled(Layout.Header)`
    background: ${(props) => props.background}
`

const HeaderComponent = ({ children, ...props }) => {
  return (
    <HeaderStyled {...props}>
      {children}
    </HeaderStyled>
  )
}

HeaderComponent.propTypes = {
  children: PropTypes.any,
  background: PropTypes.string,
}

HeaderComponent.defaultProps = {
  background: '#000',
  children: null,
}

export default HeaderComponent
