import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Row, Col, Form, Select, Input, Switch,
} from 'antd'
import {
  changeMessageInputValue, getEventsAPI,
} from './../../effects/actions'
import { Util } from '../../utils'

const CampaignEventForm = ({
  messageInputs, usedEventIds, changeMessageInputValue, viewMode, events, getEventsAPI,
}) => {
  useEffect(() => {
    getEventsAPI()
  }, [getEventsAPI])

  const onChangeInput = (e) => {
    let { name, value } = e.target
    if(name === 'color_1' || name === 'color_2'){
      value = Util.hexToRgb(value)
    }
    changeMessageInputValue(name, value)
  }

  // Filter the used events which are already used under the given campaign
  const remainingEventToUse = events.filter(e => !usedEventIds.includes(e.id))
  const options = remainingEventToUse.map(d => <Select.Option key={d.id} value={d.id}>{d.headline} - {d.subheadline}</Select.Option>)

  return (
    <Row>
      <Col>
        <Row gutter={[12, 0]}>
          <Col span={12}>
              <Form.Item label="Event" labelCol={{ span: 24 }}>
                <Select
                  showSearch
                  value={messageInputs.event_id}
                  placeholder={"event"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={true}
                  onChange={(value) => {
                    changeMessageInputValue('type_id', "event")
                    changeMessageInputValue('event_id', value)
                  }}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={viewMode}
                  labelInValue={true}
                >
                  {options}
                </Select>
              </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={4}>
            <Form.Item label="Show contact me" labelCol={{ span: 24 }}>
              <Switch name="show_contact_me" size="large" onChange={(value) => changeMessageInputValue('show_contact_me', value)} checked={messageInputs.show_contact_me} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Show learn more" labelCol={{ span: 24 }}>
              <Switch name="show_learn_more" size="large" onChange={(value) => changeMessageInputValue('show_learn_more', value)} checked={messageInputs.show_learn_more} disabled={viewMode} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={2}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_one_show" size="large" onChange={(value) => changeMessageInputValue('action_one_show', value)} checked={messageInputs.action_one_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 1 text" labelCol={{ span: 24 }}>
              <Input name="action_one_text" type="text" placeholder="Action 1 text" size="large" onChange={onChangeInput} value={messageInputs.action_one_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 1 text color" labelCol={{ span: 24 }}>
              <Input name="action_one_color" type="color" placeholder="Action 1 text color" size="large" onChange={onChangeInput} value={messageInputs.action_one_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 1 background color" labelCol={{ span: 24 }}>
              <Input name="action_one_background" type="color" placeholder="Action 1 background color" size="large" onChange={onChangeInput} value={messageInputs.action_one_background} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 1 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_one_confirmation_message" type="text" placeholder="Action 1 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_one_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Show" labelCol={{ span: 24 }}>
              <Switch name="action_two_show" size="large" onChange={(value) => changeMessageInputValue('action_two_show', value)} checked={messageInputs.action_two_show} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 2 text" labelCol={{ span: 24 }}>
              <Input name="action_two_text" type="text" placeholder="Action 2 text" size="large" onChange={onChangeInput} value={messageInputs.action_two_text} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 2 text color" labelCol={{ span: 24 }}>
              <Input name="action_two_color" type="color" placeholder="Action 2 text color" size="large" onChange={onChangeInput} value={messageInputs.action_two_color} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Action 2 background color" labelCol={{ span: 24 }}>
              <Input name="action_two_background" type="color" placeholder="Action 2 background color" size="large" onChange={onChangeInput} value={messageInputs.action_two_background} disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Action 2 confirmation text" labelCol={{ span: 24 }}>
              <Input name="action_two_confirmation_message" type="text" placeholder="Action 2 confirmation text" size="large" onChange={onChangeInput} value={messageInputs.action_two_confirmation_message} disabled={viewMode} />
            </Form.Item>
          </Col>
        </Row>

      </Col>
    </Row>

  )
}

CampaignEventForm.propTypes = {
  changeMessageInputValue: PropTypes.func,
  getMembersAPI: PropTypes.func,
  messageInputs: PropTypes.object,
  usedEventIds: PropTypes.array,
  viewMode: PropTypes.bool,
  isNew: PropTypes.bool,
}

CampaignEventForm.defaultProps = {
  viewMode: false,
  isNew: false,
}
const mapStateToProps = ({ messageAPI, eventAPI }) => {
  const {
    errorMessage, successMessage, messageInputs, messageEvents,
  } = messageAPI
  const { events } = eventAPI
  return {
    errorMessage,
    successMessage,
    messageInputs,
    usedEventIds: messageEvents.map(messageEvent => messageEvent.event_id),
    events,
  }
}

export default
connect(mapStateToProps, {
  changeMessageInputValue,
  getEventsAPI,
})(CampaignEventForm)
