import React from 'react'
import { Container } from './../../components'

const BlankPage = () => {
  return (
    <Container />
  )
}

BlankPage.propTypes = {
}

export default BlankPage
