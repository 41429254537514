import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { message } from 'antd'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getCampaignAPI, putCampaignAPI, setOriginalCampaignValue, postCampaignAPI,
  setCampaignPropertyValue,
} from './../../../effects/actions'
import { CampaignForm } from './../../../forms'
import { Util } from './../../../utils'

const CampaignFormPage = ({
  campaignInputs, successMessage, errorMessage,
  setCampaignPropertyValue, setOriginalCampaignValue, getCampaignAPI, postCampaignAPI,
  id, putCampaignAPI, loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getCampaignAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalCampaignValue(true)
    }
  }, [getCampaignAPI, id, idParam, setOriginalCampaignValue])

  useEffect(() => {
    if (successMessage !== null && isNew) {
      history.push({
        pathname: `/campaign`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setCampaignPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setCampaignPropertyValue])

  const onSave = () => {
    if (!isNew) {
      putCampaignAPI({...campaignInputs, id: idParam})
    } else {
      postCampaignAPI({...campaignInputs})
    }
  }

  const onCancel = () => {
    setOriginalCampaignValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title={`Campaign: ${campaignInputs && campaignInputs.name}`} onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <CampaignForm viewMode={viewMode} newMode={isNew} id={idParam} />
    </LayoutAdmin>
  )
}

CampaignFormPage.propTypes = {
  getCampaignAPI: PropTypes.func,
  campaignInputs: PropTypes.any,
  loading: PropTypes.bool,
  putCampaignAPI: PropTypes.func,
  setOriginalCampaignValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.any,
  errorMessage: PropTypes.string,
  setCampaignPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ campaignAPI }) => {
  const {
    campaignInputs, loading,
    successMessage, id, errorMessage,
  } = campaignAPI
  return {
    campaignInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getCampaignAPI,
  putCampaignAPI,
  setOriginalCampaignValue,
  postCampaignAPI,
  setCampaignPropertyValue,
})(CampaignFormPage)
