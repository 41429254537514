import {MemberActionType} from './../../types'

export const getMembersAPISuccess = (response) => ({
  type: MemberActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getMembersAPIFailure = (
  error,
) => ({
  type: MemberActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getMembersAPI = (queryParams) => ({
  type: MemberActionType.GET_LIST_API,
  params: queryParams,
})

export const getMemberAPISuccess = (response) => ({
  type: MemberActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getMemberAPIFailure = (
  error,
) => ({
  type: MemberActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getMemberAPI = (
  id,
) => ({
  type: MemberActionType.GET_BY_ID_API,
  payload: id,
})

export const changeMemberInputValue = (
  property, value,
) => ({
  type: MemberActionType.CHANGE_MEMBER_INPUT_VALUE,
  payload: { property, value },
})

export const setMemberPropertyValue = (
  property, value,
) => ({
  type: MemberActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const changeMemberUserInputValue = (
  property, value,
) => ({
  type: MemberActionType.CHANGE_MEMBER_USER_INPUT_VALUE,
  payload: { property, value },
})

export const putMemberAPISuccess = (response) => ({
  type: MemberActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putMemberAPIFailure = (
  error,
) => ({
  type: MemberActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putMemberAPI = (
  data,
) => ({
  type: MemberActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalMemberValue = (isNew) => ({
  type: MemberActionType.SET_ORIGINAL_MEMBER_VALUE,
  payload: { isNew },
})

export const postMemberAPISuccess = (response) => ({
  type: MemberActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postMemberAPIFailure = (
  error,
) => ({
  type: MemberActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postMemberAPI = (
  data,
) => ({
  type: MemberActionType.POST_BY_ID_API,
  payload: data,
})

export const deleteMemberAPISuccess = (response) => ({
  type: MemberActionType.DELETE_BY_ID_API_SUCCESS,
  payload: response,
})

export const deleteMemberAPIFailure = (
    error,
) => ({
  type: MemberActionType.DELETE_BY_ID_API_FAILURE,
  payload: error,
})

export const deleteMemberAPI = (
    data,
) => ({
  type: MemberActionType.DELETE_BY_ID_API,
  payload: data,
})

export const detachBoxAPISuccess = (response) => ({
  type: MemberActionType.DETACH_BOX_BY_ID_API_SUCCESS,
  payload: response,
})

export const detachBoxAPIFailure = (
    error,
) => ({
  type: MemberActionType.DETACH_BOX_BY_ID_API_FAILURE,
  payload: error,
})

export const detachBoxAPI = (
    data,
) => ({
  type: MemberActionType.DETACH_BOX_BY_ID_API,
  payload: data,
})

export const toggleDeactivateMemberAPISuccess = (response) => ({
  type: MemberActionType.DEACTIVATE_BY_ID_API_SUCCESS,
  payload: response,
})

export const toggleDeactivateMemberAPIFailure = (
    error,
) => ({
  type: MemberActionType.DEACTIVATE_BY_ID_API_FAILURE,
  payload: error,
})

export const toggleDeactivateMemberAPI = (
    data,
) => ({
  type: MemberActionType.DEACTIVATE_BY_ID_API,
  payload: data,
})

export const getRepliesByMemberIdAPISuccess = (response) => ({
  type: MemberActionType.GET_REPLIES_BY_MEMBER_ID_API_SUCCESS,
  payload: response,
})

export const getRepliesByMemberIdAPIFailure = (
  error,
) => ({
  type: MemberActionType.GET_REPLIES_BY_MEMBER_ID_API_FAILURE,
  payload: error,
})

export const getRepliesByMemberIdAPI = (payload) => ({
  type: MemberActionType.GET_REPLIES_BY_MEMBER_ID_API,
  payload,
})
