import {SplashActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  splashes: [],
  splash: null,
  splashInputs: {
    type: '',
    media: null,
    message: '',
    time_length: 300,
    icons_fill_color: '#ffffff',
    order: 1,
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SplashActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case SplashActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        splashes: action.payload,
      }

    case SplashActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case SplashActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        splash: action.payload.data,
        splashInputs: {
          ...action.payload.data,
          time_length: action.payload.data.time_length / 1000,
        },
      }

    case SplashActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }

    case SplashActionType.CHANGE_SPLASH_INPUT_VALUE:
      return { ...state, splashInputs: { ...state.splashInputs, [action.payload.property]: action.payload.value } }

    case SplashActionType.SET_ORIGINAL_SPLASH_VALUE:
      return { ...state, splashInputs: action.payload.isNew ? defaultState.splashInputs : state.splash }

    case SplashActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        splashes: state.splashes.map(splash => splash.id === action.payload.id ? action.payload : splash),
        // splashInputs: { ...action.payload },
        successMessage: 'message.success',
      }

    case SplashActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case SplashActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        splashInputs: { ...defaultState.splashInputs },
        splashes: state.splashes.filter(splash => splash.id !== action.payload.id),
        successMessage: 'message.success',
      }

    case SplashActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case SplashActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `${action.payload.data.type[0].toUpperCase()}${action.payload.data.type.slice(1)} splash with message '${action.payload.data.message}' created successfully!`,
      }

    case SplashActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case SplashActionType.REPOSITION_SPLASH_ITEM:
      return {
        ...state,
        loading: true,
      }

    case SplashActionType.REPOSITION_SPLASH_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        splashes: action.payload,
      }

    case SplashActionType.REPOSITION_SPLASH_ITEM_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
