import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects'

import {
  getAdminsAPIFailure,
  getAdminsAPISuccess,
  getAdminAPISuccess,
  getAdminAPIFailure,
  putAdminAPISuccess,
  putAdminAPIFailure,
  postAdminAPISuccess,
  postAdminAPIFailure,
  deleteAdminAPISuccess,
  deleteAdminAPIFailure,
} from './../../actions'
import {
  AdminActionType,
} from '../../types'
import { API } from '../../../utils/api'

const getAdminsRequest = async ({limit=10, page=1, orderKey = 'id', orderDirection='desc'}) => {
  return API.get(`admin?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
}
function* getAdminsAPI({params}) {
  try {
    const request = yield call(getAdminsRequest, params)
    yield put(getAdminsAPISuccess(request.data))
  } catch (error) {
    yield put(getAdminsAPIFailure(error))
  }
}

export function* getAdmins() {
  yield takeLatest(AdminActionType.GET_LIST_API, getAdminsAPI)
}

const getAdminRequest = async (id) => API.get(`admin/${id}`)
function* getAdminAPI({ payload }) {
  try {
    const request = yield call(getAdminRequest, payload)
    yield put(getAdminAPISuccess(request))
  } catch (error) {
    yield put(getAdminAPIFailure(error))
  }
}

export function* getAdmin() {
  yield takeLatest(AdminActionType.GET_BY_ID_API, getAdminAPI)
}

const putAdminRequest = async (data) => API.put(`admin/${data.id}`, data)
function* putAdminAPI({ payload }) {
  try {
    const request = yield call(putAdminRequest, payload)
    yield put(putAdminAPISuccess(request))
  } catch (error) {
    yield put(putAdminAPIFailure(error))
  }
}

export function* putAdmin() {
  yield takeLatest(AdminActionType.PUT_BY_ID_API, putAdminAPI)
}

const postAdminRequest = async (data) => API.post('admin', data)
function* postAdminAPI({ payload }) {
  try {
    const payloadData = payload
    delete payloadData.user.confirmPassword
    const request = yield call(postAdminRequest, payloadData)
    yield put(postAdminAPISuccess(request))
  } catch (error) {
    yield put(postAdminAPIFailure(error))
  }
}

export function* postAdmin() {
  yield takeLatest(AdminActionType.POST_BY_ID_API, postAdminAPI)
}

const deleteAdminRequest = async (adminId) => API.delete(`admin/${adminId}`)
function* deleteAdminAPI({ payload }) {
  try {
    if (!payload.id) {
      console.log('No admin id specified!')
      return
    }
    const response = yield call(deleteAdminRequest, payload.id)
    yield put(deleteAdminAPISuccess(response.data))
  } catch (error) {
    yield put(deleteAdminAPIFailure(error))
  }
}

export function* deleteAdmin() {
  yield takeLatest(AdminActionType.DELETE_BY_ID_API, deleteAdminAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getAdmins),
    fork(getAdmin),
    fork(putAdmin),
    fork(postAdmin),
    fork(deleteAdmin),
  ])
}
