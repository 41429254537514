import { MemberSplashActionType } from './../../types'

export const getMemberSplashesAPISuccess = (response) => ({
    type: MemberSplashActionType.GET_LIST_API_SUCCESS,
    payload: response,
})

export const getMemberSplashesAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.GET_LIST_API_FAILURE,
    payload: error,
})

export const getMemberSplashesAPI = (queryParams) => ({
    type: MemberSplashActionType.GET_LIST_API,
    params: queryParams,
})

export const getMemberSplashAPISuccess = (response) => ({
    type: MemberSplashActionType.GET_BY_ID_API_SUCCESS,
    payload: response,
})

export const getMemberSplashAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.GET_BY_ID_API_FAILURE,
    payload: error,
})

export const getMemberSplashAPI = (
    id,
) => ({
    type: MemberSplashActionType.GET_BY_ID_API,
    payload: id,
})

export const changeMemberSplashInputValue = (
    property, value,
) => ({
    type: MemberSplashActionType.CHANGE_SPLASH_INPUT_VALUE,
    payload: { property, value },
})

export const setMemberSplashPropertyValue = (
    property, value,
) => ({
    type: MemberSplashActionType.SET_PROPERTY_VALUE,
    payload: { property, value },
})

export const putMemberSplashAPISuccess = (response) => ({
    type: MemberSplashActionType.PUT_BY_ID_API_SUCCESS,
    payload: response,
})

export const putMemberSplashAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.PUT_BY_ID_API_FAILURE,
    payload: error,
})

export const putMemberSplashAPI = (
    data,
) => ({
    type: MemberSplashActionType.PUT_BY_ID_API,
    payload: data,
})

export const deleteMemberSplashAPI = (
    splash,
) => ({
    type: MemberSplashActionType.DELETE_BY_ID_API,
    payload: splash,
})

export const deleteMemberSplashAPISuccess = (response) => ({
    type: MemberSplashActionType.DELETE_BY_ID_API_SUCCESS,
    payload: response,
})

export const deleteMemberSplashAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.DELETE_BY_ID_API_FAILURE,
    payload: error,
})

export const setOriginalMemberSplashValue = (isNew) => ({
    type: MemberSplashActionType.SET_ORIGINAL_SPLASH_VALUE,
    payload: { isNew },
})

export const postMemberSplashAPISuccess = (response) => ({
    type: MemberSplashActionType.POST_BY_ID_API_SUCCESS,
    payload: response,
})

export const postMemberSplashAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.POST_BY_ID_API_FAILURE,
    payload: error,
})

export const postMemberSplashAPI = (
    data,
) => ({
    type: MemberSplashActionType.POST_BY_ID_API,
    payload: data,
})

export const repositionMemberSplashAPISuccess = (response) => ({
    type: MemberSplashActionType.REPOSITION_SPLASH_ITEM_SUCCESS,
    payload: response,
})

export const repositionMemberSplashAPIFailure = (
    error,
) => ({
    type: MemberSplashActionType.REPOSITION_SPLASH_ITEM_FAILURE,
    payload: error,
})

export const repositionMemberSplashAPI = (
    data,
) => ({
    type: MemberSplashActionType.REPOSITION_SPLASH_ITEM,
    payload: data,
})
