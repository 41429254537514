import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  putBoxLightStateAPIFailure,
  putBoxLightStateAPISuccess,
  getBoxLightStateAPISuccess,
  getBoxLightStateAPIFailure, getBoxLightStatesAPISuccess, getBoxLightStatesAPIFailure,
} from './../../actions'
import {
  BoxLightStateActionType,
} from './../../types'
import { API } from './../../../utils/api'


const getBoxLightStatesRequest = async () => {
  return API.get('box-light-state')
}

function* getBoxLightStatesAPI() {
  try {
    const request = yield call(getBoxLightStatesRequest)
    yield put(getBoxLightStatesAPISuccess(request))
  } catch (error) {
    yield put(getBoxLightStatesAPIFailure(error))
  }
}

export function* getBoxLightStates() {
  yield takeEvery(BoxLightStateActionType.GET_LIST_API, getBoxLightStatesAPI)
}

const getBoxLightStateRequest = async (id) => API.get(`box-light-state/${id}`)
function* getBoxLightStateAPI({ payload }) {
  try {
    const request = yield call(getBoxLightStateRequest, payload)
    yield put(getBoxLightStateAPISuccess(request))
  } catch (error) {
    yield put(getBoxLightStateAPIFailure(error))
  }
}

export function* getBoxLightState() {
  yield takeEvery(BoxLightStateActionType.GET_BY_ID_API, getBoxLightStateAPI)
}

const putBoxLightStateRequest = async (data) => API.put(`box-light-state/${data.id}`, data)
function* putBoxLightStateAPI({ payload }) {
  try {
    const request = yield call(putBoxLightStateRequest, payload)
    yield put(putBoxLightStateAPISuccess(request))
  } catch (error) {
    yield put(putBoxLightStateAPIFailure(error))
  }
}

export function* putBoxLightState() {
  yield takeEvery(BoxLightStateActionType.PUT_BY_ID_API, putBoxLightStateAPI)
}


export default function* rootSaga() {
  yield all([
    fork(getBoxLightStates),
    fork(getBoxLightState),
    fork(putBoxLightState),
  ])
}
