import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

const Center = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 99;
    background: #0000004d;
    margin: 0;
`

const LoaderComponent = ({ props }) => {
    return (
        <Center>
            <Spin {...props} />
        </Center>
    )
}

export default LoaderComponent