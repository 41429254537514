import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import {Button, message as AntMessage, Modal, Table} from 'antd'
import { LayoutAdmin } from './../../../components'
import {deleteMemberAPI, toggleDeactivateMemberAPI, getMembersAPI, detachBoxAPI, setMemberPropertyValue} from './../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"
import styled from "styled-components"

const ButtonStyled = styled(Button)`
    margin: 4px
`

const MemberListPage = ({
  members, totalMembers, loading, getMembersAPI, deleteMemberAPI, toggleDeactivateMemberAPI, detachBoxAPI, setMemberPropertyValue, successMessage,
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const [showMemberDeleteModal, setShowMemberDeleteModal] = useState(false)
  const [showDetachBoxModal, setShowDetachBoxModal] = useState(false)
  const [showDeactivateMemberModal, setShowDeactivateMemberModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState({})

  const history = useHistory()

  useEffect(() => {
    getMembersAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getMembersAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setMemberPropertyValue('successMessage', null)
    }
  }, [setMemberPropertyValue, successMessage])
  const memberListColumns = [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'action',
      width: 50,
      render: (id) => (
          <Link to={`/member/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>
      ),
    },
    {
      title: 'First name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last name',
      dataIndex: 'surname',
      key: 'surname',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('surname')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Membership number',
      dataIndex: 'member_code',
      key: 'member_code',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('member_code')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Box',
      key: 'box',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('box_name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (data) => (data.box_id !== null && data.box_id !== 0) ? `${data.box_name} - ${data.mac_address}` : `-`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <>
            <ButtonStyled danger disabled={record.box_id} onClick={() => handleDeleteConfirmation(record)}>Delete Member</ButtonStyled>
            <ButtonStyled danger disabled={!record.box_id} onClick={() => detachBoxConfirmation(record)}>Detach Box</ButtonStyled>
            <ButtonStyled danger={record.is_activated} onClick={() => deactivateMember(record)}>{record.is_activated? 'Deactivate' : 'Activate'} Member</ButtonStyled>
          </>
      ),
    },
  ]

  const detachBoxConfirmation = (member) => {
    setSelectedMember(member)
    setShowDetachBoxModal(true)
  }

  const deactivateMember = (member) => {
    setSelectedMember(member)
    setShowDeactivateMemberModal(true)
  }

  const handleDeactivateMember = () => {
    setShowDeactivateMemberModal(false)
    toggleDeactivateMemberAPI(selectedMember)
    setSelectedMember({})
  }

  const cancelDeactivateMember = () => {
    setSelectedMember({})
    setShowDeactivateMemberModal(false)
  }

  const handleDeleteConfirmation = (member) => {
    setSelectedMember(member)
    setShowMemberDeleteModal(true)
  }

  const cancelMemberModal = () => {
    setSelectedMember({})
    setShowMemberDeleteModal(false)
    setShowDetachBoxModal(false)
  }

  const handleDeleteMember = () => {
    setShowMemberDeleteModal(false)
    deleteMemberAPI(selectedMember)
  }

  const handleDetachBox = () => {
    setShowDetachBoxModal(false)
    detachBoxAPI(selectedMember)
  }

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }

  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/member/new')}>New</Button>
      <Table
          dataSource={members}
          columns={memberListColumns}
          rowClassName={(record) => {
            if (!record.is_activated) {
              return 'light-red-colored-row'
            } else if (!record.box_id) {
              return 'dark-grey-colored-row'
            }
          }}
          pagination={{ total: totalMembers, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading}
          rowKey="id"
      />
      <Modal
          title={<b>Are you sure?</b>}
          visible={showMemberDeleteModal}
          onOk={handleDeleteMember}
          onCancel={cancelMemberModal}
          okText={'Delete'}
          okButtonProps={{ type: 'danger' }}
      >
        <span>Delete {selectedMember.name} - {selectedMember.id}</span>
        <br/>
        <span>Deleting a member will remove this members' history from the system</span>
      </Modal>
      <Modal
          title={<b>Are you sure?</b>}
          visible={showDetachBoxModal}
          onOk={handleDetachBox}
          onCancel={cancelMemberModal}
          okText={'Detach'}
          okButtonProps={{ type: 'danger' }}
      >
        <span>Detach Box {selectedMember.box ? `${selectedMember.box.name} - ${selectedMember.box.mac_address}`: '-'}</span>
        <br/>
        <span>Removing a box from a member won't change the history of the member with this box</span>
      </Modal>
      <Modal
          title={<b>Are you sure?</b>}
          visible={showDeactivateMemberModal}
          onOk={handleDeactivateMember}
          onCancel={cancelDeactivateMember}
          okText={selectedMember.is_activated ? 'Deactivate' : 'Activate'}
          okButtonProps={{ type: selectedMember.is_activated ? 'danger' : 'primary' }}
      >
        <span>{selectedMember.is_activated ? 'Deactivate' : 'Activate'} member {selectedMember.name} { selectedMember.box ? ` having box ${selectedMember.box.name} - ${selectedMember.box.mac_address}` : ''}</span>
        <br/>
        <span>{selectedMember.is_activated ? 'Deactivated members are only shown the message to activate their box' : 'Activated members have full access to their boxes'}</span>
      </Modal>
    </LayoutAdmin>
  )
}

MemberListPage.propTypes = {
  getMembersAPI: PropTypes.func,
  members: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ memberAPI }) => {
  const {
    members, loading, totalMembers, successMessage,
  } = memberAPI

  return {
    members,
    totalMembers,
    successMessage,
    loading,
  }
}

export default
connect(mapStateToProps, {
  getMembersAPI,
  deleteMemberAPI,
  toggleDeactivateMemberAPI,
  detachBoxAPI,
  setMemberPropertyValue,
})(MemberListPage)
