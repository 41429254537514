import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'

import {
    deleteContactMeRequestAPIFailure,
    deleteContactMeRequestAPISuccess,
    deleteMembersRequestAPIFailure,
    deleteMembersRequestAPISuccess,
    getContactMeRequestAPIFailure,
    getContactMeRequestAPISuccess,
    getMemberRequestsMetaDataAPIFailure, getMemberRequestsMetaDataAPISuccess,
    getMembersRequestAPIFailure,
    getMembersRequestAPISuccess,
    putContactMeRequestAPIFailure,
    putContactMeRequestAPISuccess,
    putMembersRequestAPIFailure,
    putMembersRequestAPISuccess,
} from '../../actions'
import {MembersRequestActionType} from '../../types'
import {API} from '../../../utils/api'

const putMemberRequest = async (data) => API.put(`members-request/${data.id}`, data)
// Payload will be state { id: id of the request, handleStatus: whether handled by the admin or not }
function* putMembersRequestAPI({ payload }) {
    try {
        const request = yield call(putMemberRequest, payload)
        yield put(putMembersRequestAPISuccess(request.data))
    } catch (error) {
        yield put(putMembersRequestAPIFailure(error))
    }
}

export function* putMembersRequest() {
    yield takeEvery(MembersRequestActionType.PUT_BY_ID_API, putMembersRequestAPI)
}

const deleteMemberRequest = async (requestId) => API.delete(`members-request/${requestId}`)
function* deleteMembersRequestAPI({ payload }) {
    try {
        if (!payload.id) {
            console.log('No member request id specified!')
            return
        }
        const response = yield call(deleteMemberRequest, payload.id)
        yield put(deleteMembersRequestAPISuccess(response.data))
    } catch (error) {
        yield put(deleteMembersRequestAPIFailure(error))
    }
}

export function* deleteMembersRequest() {
    yield takeLatest(MembersRequestActionType.DELETE_BY_ID_API, deleteMembersRequestAPI)
}

const getMembersRequestListRequest = async ({queryParams}) => {
    if (!queryParams) {
        return API.get(`members-request/`)
    } else {
        const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc', filter={} } = queryParams
        return API.get(`members-request?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}&filterName=${filter.name}&filterValue=${filter.value}`)
    }
}
function* getMembersRequestListAPI({ payload }) {
    try {
        const request = yield call(getMembersRequestListRequest, payload)
        const responseData = payload.queryParams ? {
            ...request.data,
            paginatedResults: true,
        } : {
            data: request.data,
            paginatedResults: false,
        }
        yield put(getMembersRequestAPISuccess(responseData))
    } catch (error) {
        console.log(error)
        yield put(getMembersRequestAPIFailure(error))
    }
}
export function* getMembersRequests() {
    yield takeEvery(MembersRequestActionType.GET_LIST_API, getMembersRequestListAPI)
}

const putContactMeRequest = async (data) => API.put(`contact/${data.id}`, data)
// Payload will be state { id: id of the request, handleStatus: whether handled by the admin or not }
function* putContactMeRequestAPI({ payload }) {
    try {
        const request = yield call(putContactMeRequest, payload)
        yield put(putContactMeRequestAPISuccess(request.data))
    } catch (error) {
        yield put(putContactMeRequestAPIFailure(error))
    }
}

export function* putContactMe() {
    yield takeEvery(MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API, putContactMeRequestAPI)
}

const deleteContactMeRequest = async (requestId) => API.delete(`contact/${requestId}`)
function* deleteContactMeRequestAPI({ payload }) {
    try {
        if (!payload.id) {
            console.log('No member request id specified!')
            return
        }
        const response = yield call(deleteContactMeRequest, payload.id)
        yield put(deleteContactMeRequestAPISuccess(response.data))
    } catch (error) {
        yield put(deleteContactMeRequestAPIFailure(error))
    }
}

export function* deleteContactMe() {
    yield takeLatest(MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API, deleteContactMeRequestAPI)
}

const getContactMeRequestListRequestAPI = async ({queryParams}) => {
    if (!queryParams) {
        return API.get(`contact/`)
    } else {
        const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc', filter={} } = queryParams
        return API.get(`contact/?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}&filterName=${filter.name}&filterValue=${filter.value}`)
    }
}
function* getContactMeRequestListAPI({ payload }) {
    try {
        const request = yield call(getContactMeRequestListRequestAPI, payload)
        const responseData = payload.queryParams ? {
            ...request.data,
            paginatedResults: true,
        } : {
            data: request.data,
            paginatedResults: false,
        }
        yield put(getContactMeRequestAPISuccess(responseData))
    } catch (error) {
        console.log(error)
        yield put(getContactMeRequestAPIFailure(error))
    }
}
export function* getContactMeList() {
    yield takeEvery(MembersRequestActionType.GET_CONTACT_ME_LIST_API, getContactMeRequestListAPI)
}


const getMessageRequestsMetaDataRequest = async () => API.get(`members-request/meta`)
function* getMessageRequestMetaDataRequestAPI() {
    try {
        const response = yield call(getMessageRequestsMetaDataRequest)
        yield put(getMemberRequestsMetaDataAPISuccess(response.data))
    } catch (error) {
        console.log(error)
        yield put(getMemberRequestsMetaDataAPIFailure(error))
    }
}

export function* getMessageRequestMetaDataRequest() {
    yield takeEvery(MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API, getMessageRequestMetaDataRequestAPI)
}

export default function* rootSaga() {
    yield all([
        fork(getMembersRequests),
        fork(putMembersRequest),
        fork(deleteMembersRequest),
        fork(putContactMe),
        fork(deleteContactMe),
        fork(getContactMeList),
        fork(getMessageRequestMetaDataRequest),
    ])
}
