import {MessageTemplateActionType} from "../../types/message-template"
import {MessageActionType} from "../../types"

export const getMessageTemplatesAPISuccess = (response) => ({
    type: MessageTemplateActionType.GET_LIST_API_SUCCESS,
    payload: response,
})

export const getMessageTemplatesAPIFailure = (
    error,
) => ({
    type: MessageTemplateActionType.GET_LIST_API_FAILURE,
    payload: error,
})

export const getMessageTemplatesAPI = (queryParams) => ({
    type: MessageTemplateActionType.GET_LIST_API,
    payload: queryParams,
})

export const getMessageTemplateAPI = (
    id,
) => ({
    type: MessageTemplateActionType.GET_BY_ID_API,
    payload: id,
})

export const getMessageTemplateAPISuccess = (response) => ({
    type: MessageTemplateActionType.GET_BY_ID_API_SUCCESS,
    payload: response,
})

export const getMessageTemplateAPIFailure = (
    error,
) => ({
    type: MessageTemplateActionType.GET_BY_ID_API_FAILURE,
    payload: error,
})

export const changeMessageTemplateInputValue = (
    property, value,
) => ({
    type: MessageTemplateActionType.CHANGE_MESSAGE_TEMPLATE_INPUT_VALUE,
    payload: { property, value },
})

export const setOriginalMessageTemplateValue = (isNew) => ({
    type: MessageTemplateActionType.SET_ORIGINAL_MESSAGE_TEMPLATE_VALUE,
    payload: { isNew },
})

export const setMessageTemplatePropertyValue = (
    property, value,
) => ({
    type: MessageTemplateActionType.SET_PROPERTY_VALUE,
    payload: { property, value },
})

export const putMessageTemplateAPISuccess = (response) => ({
    type: MessageTemplateActionType.PUT_BY_ID_API_SUCCESS,
    payload: response,
})

export const putMessageTemplateAPIFailure = (
    error,
) => ({
    type: MessageTemplateActionType.PUT_BY_ID_API_FAILURE,
    payload: error,
})

export const putMessageTemplateAPI = (
    data,
) => ({
    type: MessageTemplateActionType.PUT_BY_ID_API,
    payload: data,
})

export const postMessageTemplateAPISuccess = (response) => ({
    type: MessageTemplateActionType.POST_BY_ID_API_SUCCESS,
    payload: response,
})

export const postMessageTemplateAPIFailure = (
    error,
) => ({
    type: MessageTemplateActionType.POST_BY_ID_API_FAILURE,
    payload: error,
})

export const postMessageTemplateAPI = (
    data,
) => ({
    type: MessageTemplateActionType.POST_BY_ID_API,
    payload: data,
})

export const getMessageFilterByCampaignIdAPI = (id) => ({
    type: MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API,
    payload: id,
})

export const getMessageFilterByCampaignIdAPISuccess = (response) => ({
    type: MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API_SUCCESS,
    payload: response,
})

export const getMessageFilterByCampaignIdAPIFailure = (error) => ({
    type: MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API_FAILURE,
    payload: error,
})
