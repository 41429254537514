import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const NUM_LEDS = 32

const LEDPreviewContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 48px);
  grid-row-gap: 20px;
  margin-bottom: 20px;
`

const LEDCellContainer = styled.div`
  display: flex;
  padding: 4px 0;
  background: darkgray;
  &:last-child {
    width: 68px;
  }
`

const LEDCellLightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 1px;
  border: 1px solid darkgray; // Round color ka light
  //border-left: 2px solid black; // Round color ka light
`
const LEDLightCircle = styled.div.attrs(
    ({ color, brightness }) => ({
        style: {
            background:  color && brightness ? color : 'white',
            opacity: brightness / 255,
        },
    }),
)`
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid;
  position: relative;
`

const LEDLightCircleGlow = styled.div.attrs(
    ({ color, brightness }) => ({
        style: {
            background:  color ? color : 'white',
            boxShadow: color ? ( `0 0 15px ` + color) : 'none',
            filter: `blur(${Math.floor((brightness / 255) * 20)})px)`,
        },
    }),
)`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 110%;
  height: 110%;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.2s;
`

const LEDCellInfoContainer = styled.div`
  width: 20px;
  position: relative;
`
let rainbowInterval = null
let customInterval = null
let offset = 0
let deemingDown = 0
let intervalTimer = 0

const colorWheel = (pos) => {
    pos = 255 - pos
    if (pos < 85) {
        return `rgb(${255 - pos * 3}, 0, ${pos * 3})`
    } else if (pos < 170) {
        pos -= 85
        return `rgb(0, ${pos * 3}, ${255 - pos * 3})`
    } else {
        pos -= 170
        return `rgb(${pos * 3}, ${255 - pos * 3}, 0)`
    }
}

const LedPreview = ({led_sequence_type, color1, color2, brightness1, brightness2, speed = 10000}) => {
    const [pixelData, setPixelData] = useState(Array.from(Array(NUM_LEDS).keys()))
    const [currentBrightness, setCurrentBrightness] = useState(brightness1 || 0)

    useEffect(() => {
        setPixelData(Array.from(Array(NUM_LEDS).keys()))
        if (led_sequence_type === 'rainbow') {
            setCurrentBrightness(255)
            updateBrightnessRainbow()
        } else {
            setCurrentBrightness(0)
        }
        return () => {
            clearInterval(rainbowInterval)
            clearInterval(customInterval)
            clearInterval(intervalTimer)
            offset = 0
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [led_sequence_type])

    useEffect(() => {
        if (led_sequence_type === 'custom') {
            setCurrentBrightness(brightness1)
        }
    }, [brightness1, led_sequence_type])

    useEffect(() => {
        updateBrightness()
        updateBrightnessRainbow()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speed])

    useEffect(() => {
        // Atleast one of the two brightness should be positive number
        if (led_sequence_type !== 'custom') {
            return
        }
        const updatedPixelData = Array.from(Array(NUM_LEDS).keys())
        if (+currentBrightness === brightness1) {
            const colorToBeSet = pixelData[0] === color1 ? color2 : color1
            for (let i = 0; i < NUM_LEDS; i++) {
                updatedPixelData[i] = colorToBeSet
            }
            setPixelData(updatedPixelData)
            deemingDown = false
            updateBrightness(false)
        }
        if (+currentBrightness === brightness2) {
            deemingDown = true
            updateBrightness(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBrightness, led_sequence_type])

    const updateBrightness = () => {
        clearInterval(intervalTimer)
        if (led_sequence_type !== 'custom') {
            return
        }
        intervalTimer = setInterval(() => {
            if (deemingDown) {
                setCurrentBrightness(prev => {
                    return +prev - 1
                })
            } else {
                setCurrentBrightness(prev => {
                    return +prev + 1
                })
            }
        }, speed / 30)
    }

    const updateBrightnessRainbow = () => {
        if (led_sequence_type !== 'rainbow') {
            return
        }
        clearInterval(rainbowInterval)
        rainbowInterval = setInterval(() => {
            const updatedPixelData = Array.from(Array(NUM_LEDS).keys())
            for (let i = 0; i < NUM_LEDS; i++) {
                updatedPixelData[i] = colorWheel((offset + i) % 256)
            }
            offset = (offset + 1) % 256
            setPixelData(updatedPixelData)
        }, speed / 30)
    }

    return (
        <LEDPreviewContainer>
            {
                [...Array(NUM_LEDS).keys()].map((led, index) => (
                    <LEDCellContainer>
                        <LEDCellInfoContainer>
                            <span/><span/><span/>
                        </LEDCellInfoContainer>
                        <LEDCellLightContainer>
                            <LEDLightCircle color={pixelData[index]} brightness={currentBrightness < 0 ? 255 : currentBrightness}>
                                <LEDLightCircleGlow color={pixelData[index]} brightness={currentBrightness < 0 ? 255 : currentBrightness}/>
                            </LEDLightCircle>
                        </LEDCellLightContainer>
                    </LEDCellContainer>
                ))
            }
        </LEDPreviewContainer>
    )
}

LedPreview.propTypes = {
    led_sequence_type: PropTypes.string,
    color1: PropTypes.string,
    color2: PropTypes.string,
    brightness1: PropTypes.number,
    brightness2: PropTypes.number,
    speed: PropTypes.number,
}

export default LedPreview
