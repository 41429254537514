export const EventActionType = {
  SET_PROPERTY_VALUE: 'EVENT.SET_PROPERTY_VALUE',
  GET_LIST_API: 'EVENT.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'EVENT.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'EVENT.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'EVENT.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'EVENT.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'EVENT.GET_BY_ID_API_FAILURE',
  CHANGE_EVENT_INPUT_VALUE: 'EVENT.CHANGE_EVENT_INPUT_VALUE',
  PUT_BY_ID_API: 'EVENT.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'EVENT.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'EVENT.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_EVENT_VALUE: 'EVENT.SET_ORIGINAL_EVENT_VALUE',
  POST_BY_ID_API: 'EVENT.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'EVENT.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'EVENT.POST_BY_ID_API_SUCCESS',
}
