import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import {defaultFormatDate} from "../../constants/date"
import DatePickerPst from "../../components/date-picker-pst"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const CampaignModal = ({visible, doneCallback, campaignData}) => {
    const history =  useHistory()
    console.log('data passed to campaign modal is', campaignData, _.isEmpty(campaignData))

    if (_.isEmpty(campaignData)) {
        return null
    }

    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Box Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Campaign" icon={<EditOutlined />} onClick={() => history.push(`/campaign/${campaignData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={12}>
                        <Form.Item label="Name" labelCol={{ span: 24 }}>
                            <Input name="name" type="text" placeholder="Name" size="large" value={campaignData.name} disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Start Date" labelCol={{ span: 24 }}>
                            <DatePickerPst
                                value={campaignData.start_date}
                                defaultValue={campaignData.start_date}
                                disabled={true}
                                size="large"
                                format={defaultFormatDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="End Date" labelCol={{ span: 24 }}>
                            <DatePickerPst
                                value={campaignData.end_date}
                                defaultValue={campaignData.end_date}
                                disabled={true}
                                size="large"
                                format={defaultFormatDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

CampaignModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    campaignData: PropTypes.object,
}

export default CampaignModal
