import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select, Switch} from 'antd'
import {changeLedSequenceInputValue, setLedSequencePropertyValue} from '../../effects/actions'
import {Util} from "../../utils"
import {Text} from "../../components"
import LedPreview from "../../components/led-preview"

const { Option } = Select

const LedSequenceForm = ({ledSequenceInput, changeLedSequenceInputValue, viewMode, ledSequenceOptions, showLedPreview, setLedSequencePropertyValue}) => {

  const ledSequencesOptions = ledSequenceOptions.map(d => <Option key={d} value={d}>{d}</Option>)

  const onChangeInput = (e) => {
    let { name, value } = e.target
    if(name === 'color_1' || name === 'color_2'){
      value = Util.hexToRgb(value)
    }
    changeLedSequenceInputValue(name, value)
  }

  const onBrightnessChange = (e) => {
    let { name, value } = e.target
    value = parseInt(value)
    console.log(name, value)
    if (isNaN(value)) {
      value = 0
    }

    if (value < 0) value = 0
    if (value > 255) value = 255
    changeLedSequenceInputValue(name, value)
  }

  return (
      <div>
        <Row gutter={[12, 0]}>
          <Col span={10}>
            <Form.Item label="Name" labelCol={{ span: 24 }}>
              <Input
                  size="large"
                  type="text"
                  name="name"
                  disabled={viewMode}
                  placeholder="Name"
                  onChange={onChangeInput}
                  value={ledSequenceInput.name}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Led Sequence" labelCol={{ span: 24 }}>
              <Select
                  showSearch
                  value={ledSequenceInput.type}
                  placeholder={"Led Sequence"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={true}
                  onChange={(value) => changeLedSequenceInputValue('type', value)}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={viewMode}
              >
                {ledSequencesOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={10}>
            <Form.Item label="Color 1" labelCol={{ span: 24 }}>
              <Input
                  name="color_1"
                  type="color"
                  placeholder="Color 1"
                  size="large"
                  onChange={onChangeInput}
                  value={Util.rgbToHex(ledSequenceInput.color_1)}
                  disabled={viewMode || ledSequenceInput.type === 'rainbow'}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Color 2" labelCol={{ span: 24 }}>
              <Input
                  name="color_2"
                  type="color"
                  placeholder="Color 2"
                  size="large"
                  onChange={onChangeInput}
                  value={Util.rgbToHex(ledSequenceInput.color_2)}
                  disabled={viewMode || ledSequenceInput.type === 'rainbow'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col>
            <Text>Brightness is a value between 0 and 255</Text>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={5}>
            <Form.Item label="Min brightness" labelCol={{ span: 24 }}>
              <Input
                  name="brightness_1"
                  type="number"
                  placeholder="Min brightness"
                  size="large"
                  onChange={onBrightnessChange}
                  value={Number(ledSequenceInput.brightness_1).toString()}
                  disabled={viewMode || ledSequenceInput.type === 'rainbow'}
                  min={0}
                  max={255}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Max brightness" labelCol={{ span: 24 }}>
              <Input
                  name="brightness_2"
                  type="number"
                  placeholder="Max brightness"
                  size="large"
                  onChange={onBrightnessChange}
                  value={Number(ledSequenceInput.brightness_2).toString()}
                  disabled={viewMode || ledSequenceInput.type === 'rainbow'}
                  min={0}
                  max={255}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Speed" labelCol={{ span: 24 }}>
              <Input
                  name="speed"
                  type="number"
                  placeholder="Speed"
                  size="large"
                  onChange={onChangeInput}
                  value={ledSequenceInput.speed}
                  disabled={viewMode}
                  min={0}
                  max={255}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item label={"Display LED Preview"} labelCol={{ span: 24 }}>
              <Switch size="large" onChange={(value) => setLedSequencePropertyValue('showLedPreview', value)} checked={showLedPreview} />
            </Form.Item>
          </Col>
        </Row>
        {
          showLedPreview && (
              <Row >
                <LedPreview
                    led_sequence_type={ledSequenceInput.type}
                    color1={ledSequenceInput.color_1}
                    color2={ledSequenceInput.color_2}
                    brightness1={+ledSequenceInput.brightness_1}
                    brightness2={+ledSequenceInput.brightness_2}
                    speed={+ledSequenceInput.speed}
                />
              </Row>
          )
        }
      </div>
  )
}

LedSequenceForm.propTypes = {
  changeLedSequenceInputValue: PropTypes.func,
  ledSequenceInput: PropTypes.object,
  viewMode: PropTypes.bool,
  id: PropTypes.any,
}

LedSequenceForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ ledSequenceAPI }) => {
  const {
    errorMessage, successMessage, ledSequenceInput, ledSequenceOptions, showLedPreview,
  } = ledSequenceAPI
  return {
    errorMessage,
    successMessage,
    ledSequenceInput,
    ledSequenceOptions,
    showLedPreview,
  }
}

export default
connect(mapStateToProps, {
  changeLedSequenceInputValue,
  setLedSequencePropertyValue,
})(LedSequenceForm)
