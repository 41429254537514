import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select, Switch} from 'antd'
import {changeBoxLightStateInputValue, getLedSequenceListAPI, setBoxLightStatePropertyValue} from '../../effects/actions'
import LedPreview from "../../components/led-preview"

const { Option } = Select

const BoxLightStateForm = ({changeBoxLightStateInputValue, viewMode, ledSequences, showLedPreview, boxLightStateInputs, getLedSequenceListAPI, setBoxLightStatePropertyValue}) => {
  const onChangeInput = (e) => {
    let { name, value } = e.target
    changeBoxLightStateInputValue(name, value)
  }

  const onLedChange = (value) => {
    const selectedLedSequence = ledSequences.find(ls => +ls.id === +value)
    changeBoxLightStateInputValue('ledSequence', selectedLedSequence)
    changeBoxLightStateInputValue('led_sequence_id', value)
  }

  useEffect(() => {
    getLedSequenceListAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <div>
        <Row gutter={[12, 0]}>
          <Col span={10}>
            <Form.Item label="Name" labelCol={{ span: 24 }}>
              <Input
                  size="large"
                  type="text"
                  name="name"
                  disabled={viewMode}
                  placeholder="Name"
                  onChange={onChangeInput}
                  value={boxLightStateInputs.name}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Description" labelCol={{ span: 24 }}>
              <Input
                  size="large"
                  type="description"
                  name="description"
                  disabled={viewMode}
                  placeholder="Description"
                  onChange={onChangeInput}
                  value={boxLightStateInputs.description}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={10}>
            <Form.Item label="Led Sequence" labelCol={{ span: 24 }}>
              <Select
                  showSearch
                  value={boxLightStateInputs.led_sequence_id}
                  placeholder={"Led Sequence"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={true}
                  onChange={onLedChange}
                  notFoundContent={<>0 results found</>}
                  size="large"
                  disabled={viewMode}
              >
                {ledSequences.map(d => <Option key={d.id} value={d.id}>{d.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item label={"Display LED Preview"} labelCol={{ span: 24 }}>
              <Switch size="large" onChange={(value) => setBoxLightStatePropertyValue('showLedPreview', value)} checked={showLedPreview} />
            </Form.Item>
          </Col>
        </Row>
        {
          showLedPreview && (
              <Row >
                <LedPreview
                    led_sequence_type={boxLightStateInputs.ledSequence.type}
                    color1={boxLightStateInputs.ledSequence.color_1}
                    color2={boxLightStateInputs.ledSequence.color_2}
                    brightness1={+boxLightStateInputs.ledSequence.brightness_1}
                    brightness2={+boxLightStateInputs.ledSequence.brightness_2}
                    speed={+boxLightStateInputs.ledSequence.speed}
                />
              </Row>
          )
        }
      </div>
  )
}

BoxLightStateForm.propTypes = {
  changeBoxLightStateInputValue: PropTypes.func,
  viewMode: PropTypes.bool,
  id: PropTypes.any,
}

BoxLightStateForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ boxLightStateAPI, ledSequenceAPI }) => {
  const {
    boxLightStateInputs, errorMessage, successMessage, showLedPreview,
  } = boxLightStateAPI

  const {ledSequences} = ledSequenceAPI
  return {
    errorMessage,
    successMessage,
    boxLightStateInputs,
    ledSequences,
    showLedPreview,
  }
}

export default
connect(mapStateToProps, {
  changeBoxLightStateInputValue,
  setBoxLightStatePropertyValue,
  getLedSequenceListAPI,
})(BoxLightStateForm)
