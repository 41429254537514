import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getAdminAPI, putAdminAPI, setOriginalAdminValue, postAdminAPI, setAdminPropertyValue,
} from '../../../effects/actions'
import { AdminForm } from '../../../forms'
import { message } from 'antd'
import { Util } from '../../../utils'

const AdminFormPage = ({
  adminInputs, successMessage, errorMessage, getAdminAPI,
  setOriginalAdminValue, setAdminPropertyValue, id, putAdminAPI,
  postAdminAPI,  loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getAdminAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalAdminValue(true)
    }
  }, [getAdminAPI, idParam, setOriginalAdminValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage && isNew) {
      history.push({
        pathname: `/admin`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setAdminPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setAdminPropertyValue])

  const onSave = () => {
    // Basic Error checking
    if (adminInputs.user.password !== adminInputs.user.confirmPassword) {
      message.error('Passwords doesnt match!')
      return
    }
    if (!isNew) {
      putAdminAPI(adminInputs)
    } else {
      postAdminAPI(adminInputs)
    }
  }

  const onCancel = () => {
    setOriginalAdminValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Admin" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <AdminForm viewMode={viewMode} id={id} isNew={isNew}/>
    </LayoutAdmin>
  )
}

AdminFormPage.propTypes = {
  getAdminAPI: PropTypes.func,
  adminInputs: PropTypes.any,
  loading: PropTypes.bool,
  putAdminAPI: PropTypes.func,
  setOriginalAdminValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.any,
  setAdminPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ adminAPI }) => {
  const {
    adminInputs, loading,
    successMessage, id, errorMessage,
  } = adminAPI
  return {
    adminInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getAdminAPI,
  putAdminAPI,
  setOriginalAdminValue,
  postAdminAPI,
  setAdminPropertyValue,
})(AdminFormPage)
