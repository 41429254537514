import {LedSequenceActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  removed: null,
  ledSequences: [],
  totalLedSequences: 0,
  showLedPreview: false,
  ledSequence: {
    name: '',
    type: 'rainbow',
    color_1: null,
    color_2: null,
    brightness_1: 0,
    brightness_2: 0,
    speed: 1000,
  },
  ledSequenceOptions: ['rainbow', 'custom'],
  ledSequenceInput: {
    name: '',
    type: 'rainbow',
    color_1: null,
    color_2: null,
    brightness_1: 0,
    brightness_2: 0,
    speed: 1000,
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LedSequenceActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case LedSequenceActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
        removed: false,
      }

    case LedSequenceActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        ledSequences: action.payload.data,
        totalLedSequences: action.payload.total,
      }

    case LedSequenceActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case LedSequenceActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case LedSequenceActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        ledSequence: action.payload.data,
        ledSequenceInput: action.payload.data,
      }

    case LedSequenceActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case LedSequenceActionType.CHANGE_LED_SEQUENCE_INPUT_VALUE:
      return { ...state, ledSequenceInput: { ...state.ledSequenceInput, [action.payload.property]: action.payload.value } }

    case LedSequenceActionType.SET_ORIGINAL_LED_SEQUENCE_VALUE:
      return { ...state, ledSequenceInput: action.payload.isNew ? defaultState.ledSequenceInput : state.ledSequence }

    case LedSequenceActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case LedSequenceActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
        showLedPreview: false,
      }

    case LedSequenceActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case LedSequenceActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case LedSequenceActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `Led Sequence with name: '${action.payload.data.name}' and type: '${action.payload.data.type}' created successfully!`,
        showLedPreview: false,
      }

    case LedSequenceActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case LedSequenceActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case LedSequenceActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        ledSequences: state.ledSequences.filter(ledSequence => ledSequence.id !== action.payload.id),
      }

    case LedSequenceActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
