import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import MaskedInput from "antd-mask-input"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const BoxModal = ({visible, doneCallback, boxData}) => {
    const history =  useHistory()
    console.log('data passed to event modal is', boxData, _.isEmpty(boxData))

    if (_.isEmpty(boxData)) {
        return null
    }

    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Box Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Box" icon={<EditOutlined />} onClick={() => history.push(`/box/${boxData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                   <Col span={10}>
                        <Form.Item label="Name" labelCol={{ span: 24 }}>
                            <Input
                                size="large"
                                type="text"
                                name="name"
                                disabled={true}
                                placeholder="Name"
                                value={boxData.name}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Mac Address" labelCol={{ span: 24 }}>
                            <MaskedInput
                                mask="**:**:**:**:**:**"
                                size="large"
                                type="text"
                                name="mac_address"
                                disabled={true}
                                placeholder="Mac Address"
                                value={boxData.mac_address}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

BoxModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    boxData: PropTypes.object,
}

export default BoxModal
