import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getBoxAPI, putBoxAPI, setOriginalBoxValue, postBoxAPI, setBoxPropertyValue,
} from './../../../effects/actions'
import { BoxForm } from './../../../forms'
import { message } from 'antd'
import { Util } from './../../../utils'

const BoxFormPage = ({
  boxInputs, successMessage, errorMessage, getBoxAPI,
  setOriginalBoxValue, setBoxPropertyValue, id, putBoxAPI,
  postBoxAPI,  loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getBoxAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalBoxValue(true)
    }
  }, [getBoxAPI, idParam, setOriginalBoxValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage && isNew) {
      history.push({
        pathname: `/box`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setBoxPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setBoxPropertyValue])

  const onSave = () => {
    if (!isNew) {
      putBoxAPI(boxInputs)
    } else {
      postBoxAPI(boxInputs)
    }
  }

  const onCancel = () => {
    setOriginalBoxValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Box" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <BoxForm viewMode={viewMode} id={id} />
    </LayoutAdmin>
  )
}

BoxFormPage.propTypes = {
  getBoxAPI: PropTypes.func,
  boxInputs: PropTypes.any,
  loading: PropTypes.bool,
  putBoxAPI: PropTypes.func,
  setOriginalBoxValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.any,
  setBoxPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ boxAPI }) => {
  const {
    boxInputs, loading,
    successMessage, id, errorMessage,
  } = boxAPI
  return {
    boxInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getBoxAPI,
  putBoxAPI,
  setOriginalBoxValue,
  postBoxAPI,
  setBoxPropertyValue,
})(BoxFormPage)
