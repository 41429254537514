import { combineReducers } from 'redux'
import authReducer from './api/auth'
import memberReducer from './api/member'
import boxReducer from './api/box'
import eventReducer from './api/event'
import campaignReducer from './api/campaign'
import messageReducer from './api/message'
import messageTemplateReducer from './api/message-template'
import statusReducer from './api/status'
import splashReducer from './api/splash'
import membersRequestReducer from './api/members-request'
import ledSequenceReducer from './api/led-sequence'
import adminReducer from './api/admin'
import memberSplashReducer from './api/member-splash'
import boxLightStateReducer from './api/box-light-state'

export const rootReducers = combineReducers({
  authAPI: authReducer,
  memberAPI: memberReducer,
  boxAPI: boxReducer,
  eventAPI: eventReducer,
  campaignAPI: campaignReducer,
  messageAPI: messageReducer,
  messageTemplateAPI: messageTemplateReducer,
  statusAPI: statusReducer,
  splashAPI: splashReducer,
  membersRequestAPI: membersRequestReducer,
  ledSequenceAPI: ledSequenceReducer,
  adminAPI: adminReducer,
  boxLightStateAPI: boxLightStateReducer,
  memberSplashAPI: memberSplashReducer,
})
