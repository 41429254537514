import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { message } from 'antd'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getMemberAPI, putMemberAPI, setOriginalMemberValue, postMemberAPI, setMemberPropertyValue,
  getRepliesByMemberIdAPI,
} from './../../../effects/actions'
import { MemberForm } from './../../../forms'
import { Util } from './../../../utils'

const MemberFormPage = ({
  memberInputs, successMessage, errorMessage, getMemberAPI, getRepliesByMemberIdAPI,
  setOriginalMemberValue, setMemberPropertyValue, id, putMemberAPI, postMemberAPI,
  loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getMemberAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalMemberValue(true)
    }
  }, [getMemberAPI, getRepliesByMemberIdAPI, idParam, setOriginalMemberValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage !== null && isNew) {
      history.push({
        pathname: `/member`,
      })
    }
  }, [history, id, isNew, successMessage])

  useEffect(() => {
    if (errorMessage) {
      message.error(Util.renderMessage(errorMessage))
      setMemberPropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setMemberPropertyValue])

  const onSave = () => {
    if (!isNew) {
      putMemberAPI(memberInputs)
    } else {
      postMemberAPI(memberInputs)
    }
  }

  const onCancel = () => {
    setOriginalMemberValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Member" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <MemberForm viewMode={viewMode} newMode={isNew} id={idParam}/>
    </LayoutAdmin>
  )
}

MemberFormPage.propTypes = {
  getMemberAPI: PropTypes.func,
  memberInputs: PropTypes.any,
  loading: PropTypes.bool,
  putMemberAPI: PropTypes.func,
  setOriginalMemberValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.string,
  setMemberPropertyValue: PropTypes.func,
}

const mapStateToProps = ({ memberAPI }) => {
  const {
    memberInputs, loading,
    successMessage, id, errorMessage,
  } = memberAPI
  return {
    memberInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getMemberAPI,
  putMemberAPI,
  setOriginalMemberValue,
  postMemberAPI,
  setMemberPropertyValue,
  getRepliesByMemberIdAPI,
})(MemberFormPage)
