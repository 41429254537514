import {MemberSplashActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  memberSplashes: [],
  memberSplash: null,
  memberSplashInputs: {
    type: '',
    media: null,
    message: '',
    time_length: 300,
    icons_fill_color: '#ffffff',
    order: 1,
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case MemberSplashActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case MemberSplashActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        memberSplashes: action.payload,
      }

    case MemberSplashActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberSplashActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        memberSplash: action.payload.data,
        memberSplashInputs: {
          ...action.payload.data,
          time_length: action.payload.data.time_length / 1000,
        },
      }

    case MemberSplashActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }

    case MemberSplashActionType.CHANGE_SPLASH_INPUT_VALUE:
      return { ...state, memberSplashInputs: { ...state.memberSplashInputs, [action.payload.property]: action.payload.value } }

    case MemberSplashActionType.SET_ORIGINAL_SPLASH_VALUE:
      return { ...state, memberSplashInputs: defaultState.memberSplashInputs}

    case MemberSplashActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        memberSplashes: state.memberSplashes.map(memberSplash => memberSplash.id === action.payload.id ? action.payload : memberSplash),
        // memberSplashInputs: { ...action.payload },
        successMessage: 'message.success',
      }

    case MemberSplashActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberSplashActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        memberSplashInputs: { ...defaultState.memberSplashInputs },
        memberSplashes: state.memberSplashes.filter(memberSplash => memberSplash.id !== action.payload.id),
        successMessage: 'message.success',
      }

    case MemberSplashActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberSplashActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `${action.payload.data.type[0].toUpperCase()}${action.payload.data.type.slice(1)} member splash with message '${action.payload.data.message}' created successfully!`,
        memberSplashes: [action.payload.data, ...state.memberSplashes],
        memberSplashInputs: defaultState.memberSplashInputs,
      }

    case MemberSplashActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MemberSplashActionType.REPOSITION_SPLASH_ITEM:
      return {
        ...state,
        loading: true,
      }

    case MemberSplashActionType.REPOSITION_SPLASH_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        memberSplashes: action.payload,
      }

    case MemberSplashActionType.REPOSITION_SPLASH_ITEM_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
