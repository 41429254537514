import { AuthActionType } from './../../types'

export const signInAPISuccess = (response) => ({
  type: AuthActionType.SIGN_IN_API_SUCCESS,
  payload: response,
})

export const signInAPIFailure = (
  error,
) => ({
  type: AuthActionType.SIGN_IN_API_FAILURE,
  payload: error,
})

export const signInAPI = (
  data,
) => ({
  type: AuthActionType.SIGN_IN_API,
  payload: data,
})

export const changeSignInInputValue = (
  property, value,
) => ({
  type: AuthActionType.CHANGE_SIGN_IN_INPUT_VALUE,
  payload: { property, value },
})

export const resetPasswordAPISuccess = (response) => ({
  type: AuthActionType.RESET_PASSWORD_API_SUCCESS,
  payload: response,
})

export const resetPasswordAPIFailure = (
    error,
) => ({
  type: AuthActionType.RESET_PASSWORD_API_FAILURE,
  payload: error,
})

export const resetPasswordAPI = (
    data,
) => ({
  type: AuthActionType.RESET_PASSWORD_API,
  payload: data,
})

export const sendVerificationCodeAPI = (
    data,
) => ({
  type: AuthActionType.SEND_VERIFICATION_CODE_API,
  payload: data,
})

export const sendVerificationCodeAPISuccess = (response) => ({
  type: AuthActionType.SEND_VERIFICATION_CODE_API_SUCCESS,
  payload: response,
})

export const sendVerificationCodeAPIFailure = (
    error,
) => ({
  type: AuthActionType.SEND_VERIFICATION_CODE_API_FAILURE,
  payload: error,
})

export const changeResetPasswordInputValue = (
    property, value,
) => ({
  type: AuthActionType.CHANGE_RESET_PASSWORD_INPUT_VALUE,
  payload: { property, value },
})

export const setAuthPropertyValue = (
  property, value,
) => ({
  type: AuthActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const signOutAPI = (
) => ({
  type: AuthActionType.SIGN_OUT_API,
})
