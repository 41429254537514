import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select, Switch, Tabs} from 'antd'
import {changeMemberInputValue, changeMemberUserInputValue, getAvailableBoxesAPI} from './../../effects/actions'
import {defaultFormatDate} from '../../constants/date'
import UploadMedia from "../../components/upload-media"
import {Util} from "../../utils"
import MemberMessageRepliesTable from "../../components/member/member-message-replies-table"
import {useParams} from "react-router-dom"
import {useHistory} from "react-router"
import {MemberFormTabs} from "../../pages/member/constants"
import MemberSplashListPage from "../../components/member/member-splash-table"

const MemberForm = ({
  memberInputs, changeMemberInputValue, viewMode, changeMemberUserInputValue,
  availableBoxes, newMode, getAvailableBoxesAPI, id,
}) => {
  const [activeTabPane, setActiveTabPane] = useState(useParams().tabName || MemberFormTabs.MEMBER_INFO)
  const history = useHistory()

  useEffect(() => {
    getAvailableBoxesAPI()
  },[getAvailableBoxesAPI])

  const onChangeInput = (e) => {
    const { name, value } = e.target
    if (name.includes('user.')) {
      changeMemberUserInputValue(name.substring(5, name.length), value)
    } else {
      changeMemberInputValue(name, value)
    }
  }

  const options = availableBoxes.map(d => <Select.Option key={d.id} value={d.id}>{d.name} - {d.mac_address}</Select.Option>)

  const renderMember = (
      <Row gutter={[12, 0]}>
        <Col span={10}>
          <Form.Item label="First name" labelCol={{ span: 24 }}>
            <Input name="name" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={memberInputs.name} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Last name" labelCol={{ span: 24 }}>
            <Input name="surname" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={memberInputs.surname} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Membership number" labelCol={{ span: 24 }}>
            <Input name="member_code" type="text" placeholder="Membership number" size="large" onChange={onChangeInput} value={memberInputs.member_code} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="E-mail" labelCol={{ span: 24 }}>
            <Input name="user.email" type="text" placeholder="Email" size="large" onChange={onChangeInput} value={memberInputs.user.email} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={10}>
            <Form.Item label="Is Active Member?" labelCol={{ span: 24 }}>
                <Switch
                    size="large"
                    onChange={(value) => changeMemberInputValue('is_activated', value)}
                    checked={memberInputs.is_activated}
                />
            </Form.Item>
        </Col>
        <Col span={10}>
            <Form.Item label="Deactivated member message" labelCol={{ span: 24 }}>
                <Input
                    name="deactivated_message"
                    type="text"
                    placeholder="Deactivated member message"
                    size="large"
                    onChange={onChangeInput}
                    value={memberInputs.deactivated_message}
                    disabled={viewMode || memberInputs.is_activated}
                />
            </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Box" labelCol={{ span: 24 }}>
            <Select
                showSearch
                value={memberInputs.box_id}
                placeholder={"Box"}
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={true}
                onChange={(value) => {
                  changeMemberInputValue('box_id', value)
                }}
                onClear={() => {
                    changeMemberInputValue('box_id', null)
                }}
                notFoundContent={<>0 results found</>}
                size="large"
                allowClear
                disabled={viewMode}
                labelInValue={true}
              >
              {options}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Welcome message" labelCol={{ span: 24 }}>
            <Input name="message" type="text" placeholder="Welcome message" size="large" onChange={onChangeInput} value={memberInputs.message} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Welcome time length (seconds)" labelCol={{ span: 24 }}>
            <Input name="message_length" type="text" placeholder="Welcome time length (seconds)" size="large" onChange={onChangeInput} value={memberInputs.message_length} disabled={viewMode} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Welcome media" labelCol={{ span: 24 }}>
              <UploadMedia
                  media={memberInputs.message_media}
                  changeHandler={(value) => changeMemberInputValue('message_media', value)}
                  viewMode={viewMode}
              />
          </Form.Item>
        </Col>
      </Row>
    )

  const renderBox = (
    <Row gutter={[12, 0]}>
        <Col span={10}>
          <Form.Item label="First Connected" labelCol={{ span: 24 }}>
            <Input
              name="first_connected_at"
              type="text"
              placeholder="First Connected"
              size="large"
              value={memberInputs.box.first_connected_at ? Util.convertDateToPST(memberInputs.box.first_connected_at).format(defaultFormatDate) : "Not connected yet"}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Last Connected" labelCol={{ span: 24 }}>
            <Input
              name="last_connected_at"
              type="text"
              placeholder="Last Connected"
              size="large"
              value={memberInputs.box.last_connected_at ? Util.convertDateToPST(memberInputs.box.last_connected_at).format(defaultFormatDate) : "Not connected yet"}
              disabled={true}
            />
          </Form.Item>
        </Col>
    </Row>
  )

  return (
    <Tabs defaultActiveKey={activeTabPane} activeKey={activeTabPane} onChange={(tabId) => {
        setActiveTabPane(tabId)
        history.push(`/member/${id}/${tabId}`)
    }}>
      <Tabs.TabPane tab="Member Info" key={MemberFormTabs.MEMBER_INFO}>
        { renderMember}
      </Tabs.TabPane>
      {!newMode && <Tabs.TabPane tab="Box" key={MemberFormTabs.BOX}>
        {renderBox}
      </Tabs.TabPane>}
      {!newMode && <Tabs.TabPane tab="Notifications" key={MemberFormTabs.NOTIFICATIONS}>
          { activeTabPane === MemberFormTabs.NOTIFICATIONS && <MemberMessageRepliesTable id={id}/>}
      </Tabs.TabPane>}
      {!newMode && <Tabs.TabPane tab="Splashes" key={MemberFormTabs.SPLASHES}>
          { activeTabPane === MemberFormTabs.SPLASHES && <MemberSplashListPage memberId={id}/>}
      </Tabs.TabPane>}
    </Tabs>

  )
}

MemberForm.propTypes = {
  changeMemberInputValue: PropTypes.func,
  changeMemberUserInputValue: PropTypes.func,
  memberInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  availableBoxes: PropTypes.array,
  newMode: PropTypes.bool,
}

MemberForm.defaultProps = {
  viewMode: false,
  newMode: false,
}
const mapStateToProps = ({ memberAPI, boxAPI }) => {
  const {
    memberInputs,
  } = memberAPI

  const { availableBoxes } = boxAPI
  return {
    memberInputs,
    availableBoxes,
  }
}

export default
connect(mapStateToProps, {
  changeMemberInputValue,
  changeMemberUserInputValue,
  getAvailableBoxesAPI,
})(MemberForm)
