import React from 'react'
import moment from "moment-timezone"

export const Util = {
  renderMessage: (err) => {
    if (err && err.message && err.message.error) {
      if (err.message.error === 'error.user_exists') {
        return 'This email is already been used by another member!'
      }
    }
    switch (err) {
      case 'internal_error':
        return 'Internal error, try again.'

      case 'email_or_password_invalid':
        return 'Invalid email or password.'

      case 'email_invalid':
        return 'Invalid email.'

      case 'password_invalid':
        return 'Invalid password.'

      case 'error.user_exists':
        return 'Email already exists.'

      default:
        return `Error occurred, ${JSON.stringify(err)}`
    }
  },
  getErrorLogin(error) {
    const errorResponse = error
    errorResponse.data = 'internal_error'
    if (error.data !== undefined) {
      if (error.data.uidField === undefined) {
        errorResponse.data = 'email_invalid'
      } else {
        errorResponse.data = 'password_invalid'
      }
    }
    return errorResponse
  },
  getError(error) {
    const errorResponse = error
    errorResponse.response.data = 'internal_error'
    return errorResponse
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
  componentFromStr(numStr, percent) {
    var num = Math.max(0, parseInt(numStr, 10))
    return percent ?
        Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num)
  },
  hexToRgb(hex){
    return "rgb(" + hex.match(/[A-Za-z0-9]{2}/g).map(function(v) { return parseInt(v, 16) }).join(",") + ")"
  },
  rgbToHex(rgb) {
    var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/
    var result, r, g, b, hex = ""
    if ( (result = rgbRegex.exec(rgb)) ) {
        r = this.componentFromStr(result[1], result[2])
        g = this.componentFromStr(result[3], result[4])
        b = this.componentFromStr(result[5], result[6])

        hex = "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)
    }
    return hex
  },
  decimalToHex(d, padding) {
    var hex = Number(d).toString(16)
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding

    while (hex.length < padding) {
        hex = "0" + hex
    }

    return hex
  },

  getExtension(fname) {
    let name = typeof fname !== 'string' ? fname.name : fname
    let pos = name.lastIndexOf(".")
    let strlen = name.length
    let extension = null
    if (pos !== -1 && strlen !== pos + 1) {
      const ext = name.split(".")
      extension = ext[ext.length - 1].toLowerCase()
    } else {
      // Check if the given data is base64
      extension = name.substring(name.indexOf('/') + 1, name.indexOf(';base64')).toLowerCase()
    }
    return extension
  },

  getFileType(file){
    const extension = this.getExtension(file)
    if(extension !== null){
      if(['mp4', 'mpeg'].includes(extension)){
        return 'video'
      }else if(['jpg', 'png', 'jpeg'].includes(extension)){
        return 'image'
      }
    }
  },

  renderMedia(media, height) {
    if(media !== null){
      const type = this.getFileType(media)
      const ext = this.getExtension(media)
      if(type === 'video'){
        return (
          <video width="100%" height={height || "100%"} controls>
            <source src={media} type={`video/${ext}`} />
            Your browser does not support the video tag.
          </video>
        )
      } else if(type === 'image'){
        return <img alt="" src={media} style={{ width: '100%' }} />
      }
    }
    return null
  },

  convertDateToPST(date) {
    if(!date) {
      return moment().tz("America/Los_Angeles")
    }
    return moment(date).tz("America/Los_Angeles")
  },
}

// export const getColumnSearchProps = (dataIndex, reduxName) => ({
//   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//     <div style={{ padding: 8 }}>
//       <Input
//         ref={node => {
//           this.searchInput = node
//         }}
//         placeholder={`Search ${dataIndex}`}
//         value={selectedKeys[0]}
//         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//         onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
//         style={{ width: 188, marginBottom: 8, display: 'block' }}
//       />
//       <Space>
//         <Button
//           type="primary"
//           onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
//           icon={<SearchOutlined />}
//           size="small"
//           style={{ width: 90 }}
//         >
//           Search
//         </Button>
//         <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//           Reset
//         </Button>
//       </Space>
//     </div>
//   ),
//   filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
//   onFilter: (value, record) =>
//     record[dataIndex]
//       ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
//       : '',
//   onFilterDropdownVisibleChange: visible => {
//     if (visible) {
//       setTimeout(() => this.searchInput.select(), 100)
//     }
//   },
//   render: text =>
//   store.getState()[reduxName] === dataIndex ? (
//       <Highlighter
//         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//         searchWords={[this.state.searchText]}
//         autoEscape
//         textToHighlight={text ? text.toString() : ''}
//       />
//     ) : (
//       text
//     ),
// })
