export const StatusActionType = {
  SET_PROPERTY_VALUE: 'STATUS.SET_PROPERTY_VALUE',
  GET_LIST_API: 'STATUS.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'STATUS.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'STATUS.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'STATUS.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'STATUS.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'STATUS.GET_BY_ID_API_FAILURE',
  CHANGE_STATUS_INPUT_VALUE: 'STATUS.CHANGE_STATUS_INPUT_VALUE',
  PUT_BY_ID_API: 'STATUS.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'STATUS.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'STATUS.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_STATUS_VALUE: 'STATUS.SET_ORIGINAL_STATUS_VALUE',
  POST_BY_ID_API: 'STATUS.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'STATUS.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'STATUS.POST_BY_ID_API_SUCCESS',
}
