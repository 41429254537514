export const MessageActionType = {
  SET_PROPERTY_VALUE: 'MESSAGE.SET_PROPERTY_VALUE',
  GET_LIST_API: 'MESSAGE.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'MESSAGE.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'MESSAGE.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'MESSAGE.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'MESSAGE.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'MESSAGE.GET_BY_ID_API_FAILURE',
  CHANGE_MESSAGE_INPUT_VALUE: 'MESSAGE.CHANGE_MESSAGE_INPUT_VALUE',
  PUT_BY_ID_API: 'MESSAGE.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'MESSAGE.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'MESSAGE.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_MESSAGE_VALUE: 'MESSAGE.SET_ORIGINAL_MESSAGE_VALUE',
  POST_BY_ID_API: 'MESSAGE.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'MESSAGE.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'MESSAGE.POST_BY_ID_API_SUCCESS',
  GET_RECEIVER_REPLIES_API: 'MESSAGE.GET_RECEIVER_REPLIES_API',
  GET_RECEIVER_REPLIES_API_SUCCESS: 'MESSAGE.GET_RECEIVER_REPLIES_API_SUCCESS',
  GET_RECEIVER_REPLIES_API_FAILURE: 'MESSAGE.GET_RECEIVER_REPLIES_API_FAILURE',
  GET_BY_CAMPAIGN_ID_API: 'MESSAGE.GET_BY_CAMPAIGN_ID_API',
  GET_BY_CAMPAIGN_ID_API_SUCCESS: 'MESSAGE.GET_BY_CAMPAIGN_ID_API_SUCCESS',
  GET_BY_CAMPAIGN_ID_API_FAILURE: 'MESSAGE.GET_BY_CAMPAIGN_ID_API_FAILURE',
  GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API: 'MESSAGE.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API',
  GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_SUCCESS: 'MESSAGE.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_SUCCESS',
  GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_FAILURE: 'MESSAGE.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_FAILURE',
  GET_MESSAGE_EVENTS_API: 'MESSAGE.GET_MESSAGE_EVENTS_API',
  GET_MESSAGE_EVENTS_API_SUCCESS: 'MESSAGE.GET_MESSAGE_EVENTS_API_SUCCESS',
  GET_MESSAGE_EVENTS_API_FAILURE: 'MESSAGE.GET_MESSAGE_EVENTS_API_FAILURE',
  GET_RECEIVER_REPLIES_BY_EVENT_ID_API: 'MESSAGE.GET_RECEIVER_REPLIES_BY_EVENT_ID_API',
  GET_RECEIVER_REPLIES_BY_EVENT_ID_API_SUCCESS: 'MESSAGE.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_SUCCESS',
  GET_RECEIVER_REPLIES_BY_EVENT_ID_API_FAILURE: 'MESSAGE.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_FAILURE',
  REMOVE_EVENT_FROM_MESSAGE_API: 'MESSAGE.REMOVE_EVENT_FROM_MESSAGE_API',
  REMOVE_EVENT_FROM_MESSAGE_API_SUCCESS: 'MESSAGE.REMOVE_EVENT_FROM_MESSAGE_API_SUCCESS',
  REMOVE_EVENT_FROM_MESSAGE_API_FAILURE: 'MESSAGE.REMOVE_EVENT_FROM_MESSAGE_API_FAILURE',
  PATCH_INFO_BY_ID: 'MESSAGE.PATCH_INFO_BY_ID',
  PATCH_INFO_BY_ID_SUCCESS: 'MESSAGE.PATCH_INFO_BY_ID_SUCCESS',
  PATCH_INFO_BY_ID_FAILURE: 'MESSAGE.PATCH_INFO_BY_ID_FAILURE',
  SET_MESSAGE_DATA_UPDATED: 'MESSAGE.SET_MESSAGE_DATA_UPDATED',

  GET_FILTER_BY_CAMPAIGN_ID_API: 'MESSAGE_TEMPLATE.GET_FILTER_BY_CAMPAIGN_ID_API',
  GET_FILTER_BY_CAMPAIGN_ID_API_SUCCESS: 'MESSAGE_TEMPLATE.GET_FILTER_BY_CAMPAIGN_ID_API_SUCCESS',
  GET_FILTER_BY_CAMPAIGN_ID_API_FAILURE: 'MESSAGE_TEMPLATE.GET_FILTER_BY_CAMPAIGN_ID_API_FAILURE',
}
