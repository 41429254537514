import {DatePicker} from "antd"
import React from "react"
import {Util} from "../../utils"

const DatePickerPst = ({value, onChange, ...rest}) => {
    return (
        <DatePicker
            value={Util.convertDateToPST(value)}
            showTime={true}
            showToday={false}
            onChange={(date) => {
                const dateInPST = Util.convertDateToPST(date)
                onChange(dateInPST)
            }}
            {...rest}
        />
    )
}

export default DatePickerPst
