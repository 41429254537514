import {Select} from "antd"
import React, {useState} from "react"
import styled from "styled-components"
import {SearchOutlined} from "@ant-design/icons"

const StyledDiv = styled.div`
  display: flex;
  gap: 4px;
  margin: 4px 2px 16px;
  .ant-select {
    flex: 1;
  }
`
const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 12px;
  background: aliceblue;
`
const SearchWithOption = ({ fieldValue, fieldName, onSearch, disabled, options, placeholder}) => {
    const [value, setValue] = useState(fieldValue)
    return (
        <StyledDiv>
        <Select
            showSearch
            className={'multi-line-option-select'}
            value={value || null}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption
            optionFilterProp={'label'}
            onChange={(value) => setValue(value)}
            onClear={() => setValue(null)}
            notFoundContent={<>0 results found</>}
            size="large"
            allowClear
            style={{ minWidth: 0 }}
            disabled={disabled}
        >
            {options}
        </Select>
            <StyledIconContainer onClick={() => onSearch(fieldName, value || "")}>
                <SearchOutlined />
            </StyledIconContainer>
        </StyledDiv>
    )
}
export default SearchWithOption


