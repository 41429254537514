import {BoxActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  removed: null,
  boxes: [],
  totalBoxes: 0,
  box: {
    mac_address: '',
    name: '',
  },
  boxInputs: {
    mac_address: '',
    name: '',
  },
  availableBoxes: [],
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BoxActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case BoxActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
        removed: false,
      }

    case BoxActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        boxes: action.payload.data,
        totalBoxes: action.payload.total,
      }

    case BoxActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        box: action.payload.data,
        boxInputs: action.payload.data,
      }

    case BoxActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxActionType.CHANGE_BOX_INPUT_VALUE:
      return { ...state, boxInputs: { ...state.boxInputs, [action.payload.property]: action.payload.value } }

    case BoxActionType.SET_ORIGINAL_BOX_VALUE:
      return { ...state, boxInputs: action.payload.isNew ? defaultState.boxInputs : state.box }

    case BoxActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case BoxActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: null,
        successMessage: `Box with mac-address: '${action.payload.data.mac_address}' created successfully!`,
      }

    case BoxActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }


    case BoxActionType.GET_AVAILABLES_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.GET_AVAILABLES_API_SUCCESS:
      return {
        ...state,
        loading: false,
        availableBoxes: action.payload.data,
      }

    case BoxActionType.GET_AVAILABLES_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxActionType.PATCH_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.PATCH_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        removed: true,
      }

    case BoxActionType.PATCH_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxActionType.DELETE_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxActionType.DELETE_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        boxes: state.boxes.filter(box => box.id !== action.payload.id),
      }

    case BoxActionType.DELETE_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
