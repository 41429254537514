import { CampaignActionType } from './../../types'

export const getCampaignsAPISuccess = (response) => ({
  type: CampaignActionType.GET_LIST_API_SUCCESS,
  payload: response.data,
})

export const getCampaignsAPIFailure = (
  error,
) => ({
  type: CampaignActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getCampaignsAPI = (queryParams) => ({
  type: CampaignActionType.GET_LIST_API,
  params: queryParams,
})

export const getCampaignAPISuccess = (response) => ({
  type: CampaignActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getCampaignAPIFailure = (
  error,
) => ({
  type: CampaignActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getCampaignAPI = (
  id,
) => ({
  type: CampaignActionType.GET_BY_ID_API,
  payload: id,
})

export const changeCampaignInputValue = (
  property, value,
) => ({
  type: CampaignActionType.CHANGE_CAMPAIGN_INPUT_VALUE,
  payload: { property, value },
})

export const setCampaignPropertyValue = (
  property, value,
) => ({
  type: CampaignActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putCampaignAPISuccess = (response) => ({
  type: CampaignActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putCampaignAPIFailure = (
  error,
) => ({
  type: CampaignActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putCampaignAPI = (
  data,
) => ({
  type: CampaignActionType.PUT_BY_ID_API,
  payload: {
    created_at:data.created_at,
    updated_at:data.updated_at,
    end_date:data.end_date,
    id: data.id,
    name: data.name,
    start_date: data.start_date,
  },
})

export const setOriginalCampaignValue = (isNew) => ({
  type: CampaignActionType.SET_ORIGINAL_CAMPAIGN_VALUE,
  payload: { isNew },
})

export const postCampaignAPISuccess = (response) => ({
  type: CampaignActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postCampaignAPIFailure = (
  error,
) => ({
  type: CampaignActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postCampaignAPI = (
  data,
) => ({
  type: CampaignActionType.POST_BY_ID_API,
  payload: data,
})
