import {Tabs} from "antd"
import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {LayoutAdmin} from "../../../components"
import {useHistory, useParams} from "react-router"
import {MessageRequestsTabs} from "../constants/constant"
import MessageRequestsTab from "../component/message-requests"
import ContactMeRequestsTab from "../component/contact-me-requests"
import {getMemberRequestsMetaDataAPI} from "../../../effects/actions"

const MembersRequestListPage = ({messageRequestsToHandle, contactMeRequestsToHandle, getMemberRequestsMetaDataAPI}) => {
    const [activeTabPane, setActiveTabPane] = useState(useParams().tabName || MessageRequestsTabs.MESSAGE_REQUESTS)
    const history = useHistory()

    const onTabChange = (tabId) => {
        setActiveTabPane(tabId)
        history.push(`/members-request/${tabId}`)
    }

    useEffect(() => {
        // get the messages metaData
        getMemberRequestsMetaDataAPI()
    }, [getMemberRequestsMetaDataAPI])

    return (
        <LayoutAdmin>
            <div>
                <Tabs defaultActiveKey={activeTabPane} activeKey={activeTabPane} onChange={onTabChange}>
                    <Tabs.TabPane tab={messageRequestsToHandle ? `Message Requests (${messageRequestsToHandle})`: `Message Requests`} key={MessageRequestsTabs.MESSAGE_REQUESTS}>
                        {activeTabPane === MessageRequestsTabs.MESSAGE_REQUESTS && <MessageRequestsTab />}
                    </Tabs.TabPane>
                    {<Tabs.TabPane tab={contactMeRequestsToHandle ? `Contact Me Requests (${contactMeRequestsToHandle})` : `Contact Me Requests`} key={MessageRequestsTabs.CONTACT_ME_REQUESTS}>
                        {activeTabPane === MessageRequestsTabs.CONTACT_ME_REQUESTS && <ContactMeRequestsTab />}
                    </Tabs.TabPane>}
                </Tabs>
            </div>
            </LayoutAdmin>
    )
}
const mapStateToProps = ({ membersRequestAPI }) => {
    const {metaData} = membersRequestAPI
    const {messageRequestsToHandle, contactMeRequestsToHandle} = metaData

    return {
        messageRequestsToHandle,
        contactMeRequestsToHandle,
    }
}
export default connect(mapStateToProps, {
    getMemberRequestsMetaDataAPI,
})(MembersRequestListPage)

