import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Button, message as AntMessage, Table} from 'antd'
import { useHistory } from 'react-router'
import { LayoutAdmin } from './../../../components'
import {getCampaignsAPI, setCampaignPropertyValue} from './../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"
import {defaultFormatDate} from "../../../constants/date"
import {Util} from "../../../utils"

const CampaignListPage = ({
  campaigns, loading, getCampaignsAPI, totalCampaigns, successMessage,
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const history =  useHistory()
  const campaignListColumns =  [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
          <Link to={`/campaign/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>

      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('start_date')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (date) => {
        return date ? Util.convertDateToPST(date).format(defaultFormatDate) : '-'
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('end_date')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      key: 'end_date',
      render: (date) => {
        return date ? Util.convertDateToPST(date).format(defaultFormatDate) : '-'
      },
    },
  ]

  useEffect(() => {
    getCampaignsAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getCampaignsAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setCampaignPropertyValue('successMessage', null)
    }
  }, [successMessage])

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }
  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/campaign/new')}>New</Button>
      <Table
          dataSource={campaigns}
          columns={campaignListColumns}
          pagination={{ total: totalCampaigns, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading} rowKey="id" />
    </LayoutAdmin>
  )
}

CampaignListPage.propTypes = {
  getCampaignsAPI: PropTypes.func,
  campaigns: PropTypes.array,
  campaignListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ campaignAPI }) => {
  const {
    campaigns, loading, totalCampaigns, successMessage,
  } = campaignAPI
  return {
    campaigns,
    totalCampaigns,
    loading,
    successMessage,
  }
}

export default
connect(mapStateToProps, {
  getCampaignsAPI,
  setCampaignPropertyValue,
})(CampaignListPage)
