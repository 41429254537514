import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import UploadMedia from "../../components/upload-media"
import {defaultFormatDate} from "../../constants/date"
import DatePickerPst from "../../components/date-picker-pst"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const EventModal = ({visible, doneCallback, eventData}) => {
    const history =  useHistory()
    console.log('data passed to event modal is', eventData)

    if (_.isEmpty(eventData)) {
        return null
    }

    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Event Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Event" icon={<EditOutlined />} onClick={() => history.push(`/event/${eventData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={12}>
                        <Form.Item label="Headline" labelCol={{ span: 24 }}>
                            <Input name="headline" type="text" placeholder="Headline" size="large" value={eventData.headline} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Subheadline" labelCol={{ span: 24 }}>
                            <Input name="subheadline" type="text" placeholder="Subheadline" size="large" value={eventData.subheadline} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Start Date" labelCol={{ span: 24 }}>
                            <DatePickerPst
                                value={eventData.start_date}
                                disabled
                                size="large"
                                format={defaultFormatDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="End Date" labelCol={{ span: 24 }}>
                            <DatePickerPst
                                value={eventData.end_date}
                                disabled
                                size="large"
                                format={defaultFormatDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Location" labelCol={{ span: 24 }}>
                            <Input name="location" type="text" placeholder="Location" size="large" value={eventData.location} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Status" labelCol={{ span: 24 }}>
                            <Input name="status" type="text" placeholder="Status" size="large" value={eventData.status.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Description" labelCol={{ span: 24 }}>
                            <Input.TextArea name="description" type="textarea" placeholder="Description" size="large" value={eventData.description} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Landing image" labelCol={{ span: 24 }}>
                            <UploadMedia
                                media={eventData.media}
                                viewMode={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Details image" labelCol={{ span: 24 }}>
                            <UploadMedia
                                media={eventData.media_detail}
                                viewMode={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

EventModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    eventData: PropTypes.object,
}

export default EventModal
