import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getLedSequenceAPI, putLedSequenceAPI, setOriginalLedSequenceValue, postLedSequenceAPI, setLedSequencePropertyValue,
} from '../../../effects/actions'
import { LedSequenceForm } from './../../../forms'
import { message } from 'antd'
import { Util } from '../../../utils'

const LedSequenceFormPage = ({
  ledSequenceInput, successMessage, errorMessage, getLedSequenceAPI,
  setOriginalLedSequenceValue, setLedSequencePropertyValue, id, putLedSequenceAPI,
  postLedSequenceAPI,  loading,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    if (idParam !== 'new') {
      getLedSequenceAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalLedSequenceValue(true)
    }
  }, [getLedSequenceAPI, idParam, setOriginalLedSequenceValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage && isNew) {
      history.push({
        pathname: `/led-sequence`,
      })
    }
  }, [history, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setLedSequencePropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setLedSequencePropertyValue])

  const onSave = () => {
    if (!isNew) {
      putLedSequenceAPI(ledSequenceInput)
    } else {
      postLedSequenceAPI(ledSequenceInput)
    }
  }

  const onCancel = () => {
    setOriginalLedSequenceValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="LedSequence" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} />
      <LedSequenceForm viewMode={viewMode} id={id} />
    </LayoutAdmin>
  )
}

LedSequenceFormPage.propTypes = {
  getLedSequenceAPI: PropTypes.func,
  ledSequenceInput: PropTypes.any,
  loading: PropTypes.bool,
  putLedSequenceAPI: PropTypes.func,
  setOriginalLedSequenceValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.any,
  setLedSequencePropertyValue: PropTypes.func,
}

const mapStateToProps = ({ ledSequenceAPI }) => {
  const {
    ledSequenceInput, loading,
    successMessage, id, errorMessage,
  } = ledSequenceAPI
  return {
    ledSequenceInput,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getLedSequenceAPI,
  putLedSequenceAPI,
  setOriginalLedSequenceValue,
  postLedSequenceAPI,
  setLedSequencePropertyValue,
})(LedSequenceFormPage)
