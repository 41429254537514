import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Link, useHistory} from 'react-router-dom'
import {Card, Layout, Menu} from 'antd'
import {Breadcrumb, Container, Loader, Logo} from './../../'
import {menuItems} from './../../../constants/menu'
import {signOutAPI} from './../../../effects/actions'

const LayoutContainer = styled(Layout)`
  min-height: 100vh
`

const MenuMatcherRegex = /^\/([a-zA-Z-]+)/
const LayoutAdminComponent = ({ children, signOutAPI, loading }) => {
  const history = useHistory()

  const onSignOut = () => {
    signOutAPI()
  }

  const selectedNavKey = MenuMatcherRegex.exec(history.location.pathname) ? MenuMatcherRegex.exec(history.location.pathname)[1] : ''

  return (
    <LayoutContainer>
      <Layout.Sider>
        <div><Logo /></div>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[selectedNavKey]}
          openKeys={['templates']}
        >
            {menuItems.map((item) => {
              if (item.hasSubItems) {
                return (
                  <Menu.SubMenu key={item.key} title={item.title} expandIcon={<></>}>
                    {item.children.map((subItem) => (
                        <Menu.Item key={subItem.key}>
                          <Link to={subItem.path}>{subItem.title}</Link>
                        </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )
              }
              return (
                <Menu.Item key={item.key}>
                  <Link to={item.path}>{item.title}</Link>
                </Menu.Item>
              )
            })}
          <Menu.Item key="sign-out" onClick={onSignOut}>
            Sign out
          </Menu.Item>
        </Menu>
      </Layout.Sider>

      <Layout>
        <Layout.Content>
          <Container>
            <Breadcrumb />
            <Card>{children}</Card>

          </Container>
        </Layout.Content>
      </Layout>
      {loading && <Loader spinning={true} />}
    </LayoutContainer>
  )
}

LayoutAdminComponent.propTypes = {
  children: PropTypes.any.isRequired,
  signOutAPI: PropTypes.func,
  loading: PropTypes.bool,
}

LayoutAdminComponent.defaultProps = {
  loading: false,
}

export default
connect(null, {
  signOutAPI,
})(LayoutAdminComponent)
