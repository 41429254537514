import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getSplashesAPIFailure,
  getSplashesAPISuccess,
  getSplashAPISuccess,
  getSplashAPIFailure,
  putSplashAPISuccess,
  putSplashAPIFailure,
  postSplashAPISuccess,
  postSplashAPIFailure,
  deleteSplashAPIFailure,
  deleteSplashAPISuccess, repositionSplashAPISuccess, repositionSplashAPIFailure,
} from '../../actions'
import { SplashActionType } from '../../types'
import { API } from '../../../utils/api'


const getSplashesRequest = async ({orderKey = 'order', orderDirection='desc'}) => {
  return API.get(`splash?orderKey=${orderKey}&orderDirection=${orderDirection}`)
}

function* getSplashesAPI({params}) {
  try {
    const request = yield call(getSplashesRequest, params)
    yield put(getSplashesAPISuccess(request.data))
  } catch (error) {
    yield put(getSplashesAPIFailure(error))
  }
}

export function* getSplashes() {
  yield takeEvery(SplashActionType.GET_LIST_API, getSplashesAPI)
}

const getSplashRequest = async (id) => API.get(`splash/${id}`)
function* getSplashAPI({ payload }) {
  try {
    const request = yield call(getSplashRequest, payload)
    yield put(getSplashAPISuccess(request))
  } catch (error) {
    yield put(getSplashAPIFailure(error))
  }
}

export function* getSplash() {
  yield takeEvery(SplashActionType.GET_BY_ID_API, getSplashAPI)
}

const putSplashRequest = async (data) => API.put(`splash/${data.id}`, data)
function* putSplashAPI({ payload }) {
  try {
    let media =  typeof payload.media !== 'string' ? null : payload.media
    const request = yield call(putSplashRequest, {
      ...payload,
      media,
      time_length: payload.time_length * 1000,
    })

    if(payload && payload.id && typeof payload.media !== 'string'){
      let data = new FormData()
      data.append('media', payload.media)
      yield call(putMediaSplash, payload.id, data)
    }
    yield put(putSplashAPISuccess(request.data))
  } catch (error) {
    yield put(putSplashAPIFailure(error))
  }
}

const deleteSplashRequest = async (splashId) => API.delete(`splash/${splashId}`)
function* deleteSplashAPI({payload}) {
  try {
    const request = yield call(deleteSplashRequest, payload.id)
    yield put(deleteSplashAPISuccess(request.data))
  } catch (error) {
    yield put(deleteSplashAPIFailure(error))
  }
}

export function* putSplash() {
  yield takeEvery(SplashActionType.PUT_BY_ID_API, putSplashAPI)
}

export function* deleteSplash() {
  yield takeEvery(SplashActionType.DELETE_BY_ID_API, deleteSplashAPI)
}

const putMediaSplash = async (id, data) => API.put(`splashes/${id}/media`, data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

const postSplashRequest = async (data) => API.post('splash', data)
function* postSplashAPI({ payload }) {
  try {
    const request = yield call(postSplashRequest, {
      ...payload,
      media: null,
      time_length: payload.time_length * 1000,
    })
    if(request && request.data && request.data.id){
      let data = new FormData()
      data.append('media', payload.media)
      yield call(putMediaSplash, request.data.id, data)
    }

    yield put(postSplashAPISuccess(request))
  } catch (error) {
    yield put(postSplashAPIFailure(error))
  }
}

export function* postSplash() {
  yield takeEvery(SplashActionType.POST_BY_ID_API, postSplashAPI)
}

const repositionSplashRequest = async (data) => API.post('splashes/reposition-item', data)
function* repositionSplashAPI({ payload }) {
  try {
    const request = yield call(repositionSplashRequest, {
      ...payload,
    })
    yield put(repositionSplashAPISuccess(request.data))
  } catch (error) {
    console.log('Error is, ', error)
    yield put(repositionSplashAPIFailure(error))
  }
}

export function* repositionSplash() {
  yield takeEvery(SplashActionType.REPOSITION_SPLASH_ITEM, repositionSplashAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getSplashes),
    fork(getSplash),
    fork(putSplash),
    fork(deleteSplash),
    fork(postSplash),
    fork(repositionSplash),
  ])
}
