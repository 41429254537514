import {Util} from "../../utils"
import PropTypes from 'prop-types'
import {Upload, message as AntMessage} from "antd"
import React, {useEffect, useState} from "react"
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons"
import styled from 'styled-components'

export const SupportedFileTypes = {
    IMAGE_PNG: {type: 'image/png', name: 'png'},
    IMAGE_JPG: {type: 'image/jpeg', name: 'jpeg'},
    VIDEO_MP4: {type: 'video/mp4', name: 'mp4'},
    VIDEO_MPEG: {type: 'video/mpeg', name: 'mpeg'},
}

const DefaultFileTypeList = Object.values(SupportedFileTypes)

const SupportedText = styled.div`
  width: 100%;
  font-size: 12px;
`

const UploadContainer = styled.div`
  width: 180px;
  & .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
  }
  & img {
    height: 100%
  }
`

const UploadMedia = ({ viewMode, media, changeHandler, allowedFileTypes, imageDimensions }) => {
    const [loadingMedia, setLoadingMedia] = useState(false)
    const [loadedMedia, setLoadedMedia] = useState()
    const [supportedFileTypes, setSupportedFileTypes] = useState(DefaultFileTypeList)

    useEffect(() => {
        if (allowedFileTypes && allowedFileTypes.length) {
            setSupportedFileTypes(allowedFileTypes)
        }
    }, [allowedFileTypes])

    const skipXHRRequest = ({onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }

    const handleMediaChange = info => {
        if (info.file.status === 'uploading') {
            setLoadingMedia(true)
            return
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            Util.getBase64(info.file.originFileObj).then(imgUrl => {
                setLoadingMedia(false)
                setLoadedMedia(imgUrl)
            })
        }
    }

    const uploadButton = (
        <div>
            {loadingMedia ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    )

    const beforeUploadMethod = async (file) => {
        if (supportedFileTypes.map(file => file.type).indexOf(file.type) === -1) {
            AntMessage.error(`Uploaded file '${file.name}' is not supported, please upload files from this list '${supportedFileTypes.map(file => file.name).join(', ')}'!`)
            return Upload.LIST_IGNORE
        } else {
            changeHandler(file)
            return true
        }
    }

    return (
        <UploadContainer>
            <Upload
                listType="picture-card"
                disabled={viewMode}
                showUploadList={false}
                customRequest={skipXHRRequest}
                beforeUpload={beforeUploadMethod}
                onChange={handleMediaChange}
            >
                {loadedMedia || (media && typeof media !== "object")
                    ? Util.renderMedia(loadedMedia || media)
                    : uploadButton
                }
            </Upload>
            {(!loadedMedia || (media && typeof media !== "object")) ? (
                <SupportedText>
                    <b>Supported files:</b>  {supportedFileTypes.map(file => file.name).join(', ')}
                </SupportedText>
            ) : null}
            {imageDimensions && imageDimensions.length ? (
                <SupportedText>
                    <b>Suggested image dimension:</b> {imageDimensions[0]}px &#x2715; {imageDimensions[1]}px
                </SupportedText>
            ) : null}
        </UploadContainer>
    )
}

UploadMedia.propTypes = {
    viewMode: PropTypes.bool,
    changeHandler: PropTypes.func,
    allowedFileTypes: PropTypes.any,
    media: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
}

export default UploadMedia
