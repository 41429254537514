import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {store} from '../../store'

import {
  getMessageAPIFailure,
  getMessageAPISuccess,
  getMessageEventsAPIFailure,
  getMessageEventsAPISuccess,
  getMessageFilterByCampaignIdAPIFailure,
  getMessageFilterByCampaignIdAPISuccess,
  getMessageReceiverRepliesAPIFailure,
  getMessageReceiverRepliesAPISuccess,
  getMessagesAPIFailure,
  getMessagesAPISuccess,
  getMessagesByCampaignIdAPIFailure,
  getMessagesByCampaignIdAPISuccess,
  getReceiverRepliesByCampaignIdAPIFailure,
  getReceiverRepliesByCampaignIdAPISuccess,
  getReceiverRepliesByEventIdAPIFailure,
  getReceiverRepliesByEventIdAPISuccess,
  patchMessageInfoAPIFailure,
  patchMessageInfoAPISuccess,
  postMessageAPIFailure,
  postMessageAPISuccess,
  putMessageAPIFailure,
  putMessageAPISuccess,
  removeEventFromMessageAPIFailure,
  removeEventFromMessageAPISuccess,
} from '../../actions'
import {MessageActionType} from '../../types'
import {API} from '../../../utils/api'

const getMessagesRequest = async (queryParams) => {
  if (!queryParams) {
    return API.get('message')
  } else {
    const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc' } = queryParams
    return API.get(`message?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
  }
}

function* getMessagesAPI({params}) {
  try {
    const request = yield call(getMessagesRequest, params)
    const payload = params ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getMessagesAPISuccess(payload))
  } catch (error) {
    yield put(getMessagesAPIFailure(error))
  }
}

export function* getMessages() {
  yield takeEvery(MessageActionType.GET_LIST_API, getMessagesAPI)
}

const getMessageRequest = async (id) => API.get(`message/${id}`)
function* getMessageAPI({ payload }) {
  try {
    const request = yield call(getMessageRequest, payload)
    yield put(getMessageAPISuccess(request))
  } catch (error) {
    yield put(getMessageAPIFailure(error))
  }
}

export function* getMessage() {
  yield takeEvery(MessageActionType.GET_BY_ID_API, getMessageAPI)
}

const putMessageRequest = async (data) => API.put(`message/${data.id}`, data)
function* putMessageAPI({ payload }) {
  try {
    const request = yield call(putMessageRequest, {
      ...payload,
      message_template_id: payload.message_template_id.value,
      event_id: payload.event_id.value,
      status_id: payload.status_id.value,
    })
    yield put(putMessageAPISuccess(request))
  } catch (error) {
    yield put(putMessageAPIFailure(error))
  }
}

export function* putMessage() {
  yield takeEvery(MessageActionType.PUT_BY_ID_API, putMessageAPI)
}

const postMessageRequest = async (data) => API.post('message', data)
function* postMessageAPI({ payload }) {
  try {
    let messageTemplateId= payload.message_template_id.value
    let eventId = payload.event_id.value
    const members = store.getState().memberAPI.members.filter(m => payload.members.includes(m.id))
    let requestPayload = {
      ...payload,
      campaign_id: payload.campaign_id,
      status_id: 2,
      message_template_id: messageTemplateId === 0 ? null : messageTemplateId, // TODO update this check!
      event_id: eventId === 0 ? null : eventId,
      members,
    }
    delete requestPayload.status
    delete requestPayload.campaign
    const response =  yield call(postMessageRequest, requestPayload)
    yield put(postMessageAPISuccess(response.data))
  } catch (error) {
    yield put(postMessageAPIFailure(error))
  }
}

export function* postMessage() {
  yield takeEvery(MessageActionType.POST_BY_ID_API, postMessageAPI)
}

const getMessageReceiverRepliesRequest = async () => API.get(`message-receiver`)
function* getMessageReceiverRepliesAPI() {
  try {
    const request = yield call(getMessageReceiverRepliesRequest)
    yield put(getMessageReceiverRepliesAPISuccess(request))
  } catch (error) {
    yield put(getMessageReceiverRepliesAPIFailure(error))
  }
}

export function* getMessageReceiverReplies() {
  yield takeEvery(MessageActionType.GET_RECEIVER_REPLIES_API, getMessageReceiverRepliesAPI)
}

const getMessagesByCampaignIdRequest = async (id) => API.get(`message/campaign/${id}`)
function* getMessagesByCampaignIdAPI({ payload }) {
  try {
    const request = yield call(getMessagesByCampaignIdRequest, payload)
    yield put(getMessagesByCampaignIdAPISuccess(request))
  } catch (error) {
    yield put(getMessagesByCampaignIdAPIFailure(error))
  }
}

export function* getMessagesByCampaignId() {
  yield takeEvery(MessageActionType.GET_BY_CAMPAIGN_ID_API, getMessagesByCampaignIdAPI)
}

const getReceiverRepliesByCampaignIdRequest = async ({id, queryParams}) => {
  if (!queryParams) {
    return API.get(`message-receiver/campaign/${id}`)
  } else {
    const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc', filter={} } = queryParams
    return API.get(`message-receiver/campaign/${id}?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}&filterName=${filter.name}&filterValue=${filter.value}`)
  }
}
function* getReceiverRepliesByCampaignIdAPI({payload}) {
  try {
    const request = yield call(getReceiverRepliesByCampaignIdRequest, payload)
    const responseData = payload.queryParams ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getReceiverRepliesByCampaignIdAPISuccess(responseData))
  } catch (error) {
    yield put(getReceiverRepliesByCampaignIdAPIFailure(error))
  }
}

export function* getReceiverRepliesByCampaignId() {
  yield takeEvery(MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API, getReceiverRepliesByCampaignIdAPI)
}

const getMessageEventsRequest = async ({limit=10, page=1, id}) => {
  return API.get(`messages/${id}/events?limit=${limit}&page=${page}`)
}
function* getMessageEventsAPI({payload}) {
  try {
    const request = yield call(getMessageEventsRequest, payload)
    yield put(getMessageEventsAPISuccess(request.data))
  } catch (error) {
    yield put(getMessageEventsAPIFailure(error))
  }
}

export function* getMessageEvents() {
  yield takeEvery(MessageActionType.GET_MESSAGE_EVENTS_API, getMessageEventsAPI)
}

const getReceiverRepliesByEventIdRequest = async (id) => API.get(`message-receiver/event/${id}`)
function* getReceiverRepliesByEventIdAPI({payload}) {
  try {
    const request = yield call(getReceiverRepliesByEventIdRequest, payload)
    yield put(getReceiverRepliesByEventIdAPISuccess(request))
  } catch (error) {
    yield put(getReceiverRepliesByEventIdAPIFailure(error))
  }
}

export function* getReceiverRepliesByEventId() {
  yield takeEvery(MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API, getReceiverRepliesByEventIdAPI)
}


const removeEventFromMessageRequest = async (data) => API.put(`messages/${data.id}/remove-event`)
function* removeEventFromMessageAPI({ payload }) {
  try {
    const request = yield call(removeEventFromMessageRequest, payload)
    yield put(removeEventFromMessageAPISuccess(request.data))
  } catch (error) {
    yield put(removeEventFromMessageAPIFailure(error))
  }
}

export function* removeEventFromMessage() {
  yield takeEvery(MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API, removeEventFromMessageAPI)
}

const patchMessageInfoRequest = async (data) => API.patch(`messages/${data.id}/info`, data)
function* patchMessageInfoAPI({ payload }) {
  try {
    let messageTemplateId= payload.message_template_id.value
    let eventId = payload.event_id.value
    let requestPayload = {
      ...payload,
      message_template_id: !messageTemplateId ? null : messageTemplateId, // TODO update this check!
      event_id: !eventId ? null : eventId,
    }
    const request = yield call(patchMessageInfoRequest, requestPayload)
    yield put(patchMessageInfoAPISuccess, request.data)
  } catch (error) {
    yield put(patchMessageInfoAPIFailure(error))
  }
}

export function* patchMessageInfo() {
  yield takeEvery(MessageActionType.PATCH_INFO_BY_ID, patchMessageInfoAPI)
}

const getMessageFilterByCampaignIdRequest = async (id) => API.get(`message-receiver/filters/campaign/${id}`)
function* getMessageFilterByCampaignIdAPI({ payload }) {
  try {
    const request = yield call(getMessageFilterByCampaignIdRequest, payload)
    yield put(getMessageFilterByCampaignIdAPISuccess(request.data))
  } catch (error) {
    yield put(getMessageFilterByCampaignIdAPIFailure(error))
  }
}

export function* getMessageFilterByCampaignId() {
  yield takeEvery(MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API, getMessageFilterByCampaignIdAPI)

}

export default function* rootSaga() {
  yield all([
    fork(getMessages),
    fork(getMessage),
    fork(putMessage),
    fork(postMessage),
    fork(getMessageReceiverReplies),
    fork(getReceiverRepliesByCampaignId),
    fork(getMessagesByCampaignId),
    fork(getMessageEvents),
    fork(getReceiverRepliesByEventId),
    fork(removeEventFromMessage),
    fork(patchMessageInfo),
    fork(getMessageFilterByCampaignId),
  ])
}
