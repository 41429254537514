import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Button, message as AntMessage, Table} from 'antd'
import { useHistory } from 'react-router'
import { LayoutAdmin } from './../../../components'
import {getEventsAPI, setEventPropertyValue} from '../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"
import {Util} from "../../../utils"
import {defaultFormatDate} from "../../../constants/date"

const EventListPage = ({events, loading, getEventsAPI,totalEvents, setEventPropertyValue, successMessage}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const history = useHistory()
  const eventListColumns =  [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
          <Link to={`/event/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>

      ),
    },
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('headline')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Subheadline',
      dataIndex: 'subheadline',
      key: 'subheadline',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('subheadline')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('start_date')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (date) => date ? Util.convertDateToPST(date).format(defaultFormatDate) : '-',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('end_date')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (date) => date ? Util.convertDateToPST(date).format(defaultFormatDate) : '-',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('location')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('status')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (status) => status && status.name,
    },
    {
      title: 'Media',
      dataIndex: 'media',
      width: 200,
      render: (media) => {
        return media !== null && Util.renderMedia(media)
      },
    },
  ]

  useEffect(() => {
    getEventsAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getEventsAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setEventPropertyValue('successMessage', null)
    }
  }, [setEventPropertyValue, successMessage])

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }

  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/event/new')}>New</Button>
      <Table
          dataSource={events}
          columns={eventListColumns}
          pagination={{ total: totalEvents, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading} rowKey="id" />
    </LayoutAdmin>
  )
}

EventListPage.propTypes = {
  getEventsAPI: PropTypes.func,
  events: PropTypes.array,
  eventListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ eventAPI }) => {
  const {
    events, loading, totalEvents, successMessage,
  } = eventAPI
  return {
    events,
    totalEvents,
    loading,
    successMessage,
  }
}

export default
connect(mapStateToProps, {
  getEventsAPI,
  setEventPropertyValue,
})(EventListPage)
