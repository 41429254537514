import {all, call, fork, put, takeEvery} from 'redux-saga/effects'

import {
    getMessageTemplateAPIFailure,
    getMessageTemplateAPISuccess,
    getMessageTemplatesAPIFailure,
    getMessageTemplatesAPISuccess,
    postMessageTemplateAPIFailure,
    postMessageTemplateAPISuccess,
    putMessageTemplateAPIFailure,
    putMessageTemplateAPISuccess,
} from '../../actions'
import {API} from '../../../utils/api'
import {MessageTemplateActionType} from "../../types/message-template"

const getMessageTemplatesRequest = async (queryParams) => {
    if (!queryParams) {
        return API.get('message-template')
    } else {
        const { limit = 10, page = 1, orderKey = 'id', orderDirection='desc' } = queryParams
        return API.get(`message-template?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
    }
}

function* getMessageTemplatesAPI({params}) {
    try {
        const request = yield call(getMessageTemplatesRequest, params)
        const payload = params ? {
            ...request.data,
            paginatedResults: true,
        } : {
            data: request.data,
            paginatedResults: false,
        }
        yield put(getMessageTemplatesAPISuccess(payload))
    } catch (error) {
        yield put(getMessageTemplatesAPIFailure(error))
    }
}

export function* getMessageTemplates() {
    yield takeEvery(MessageTemplateActionType.GET_LIST_API, getMessageTemplatesAPI)
}

const getMessageTemplateRequest = async (id) => API.get(`message-template/${id}`)
function* getMessageTemplateAPI({ payload }) {
    try {
        const request = yield call(getMessageTemplateRequest, payload)
        yield put(getMessageTemplateAPISuccess(request))
    } catch (error) {
        yield put(getMessageTemplateAPIFailure(error))
    }
}

export function* getMessageTemplate() {
    yield takeEvery(MessageTemplateActionType.GET_BY_ID_API, getMessageTemplateAPI)
}

const putMediaMessageTemplate = async (id, data) => API.put(`message-templates/${id}/media`, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

const putMessageTemplateRequest = async (data) => API.put(`message-template/${data.id}`, data)
function* putMessageTemplateAPI({ payload }) {
    try {

        let media =  typeof payload.media !== 'string' ? null : payload.media
        const request = yield call(putMessageTemplateRequest, {...payload, media })

        if(payload && payload.id && payload.media && typeof payload.media !== 'string'){
            let data = new FormData()
            data.append('media', payload.media)
            yield call(putMediaMessageTemplate, payload.id, data)
        }

        yield put(putMessageTemplateAPISuccess(request))
    } catch (error) {
        yield put(putMessageTemplateAPIFailure(error))
    }
}

export function* putMessageTemplate() {
    yield takeEvery(MessageTemplateActionType.PUT_BY_ID_API, putMessageTemplateAPI)
}

const postMessageTemplateRequest = async (data) => API.post('message-template', data)
function* postMessageTemplateAPI({ payload }) {
    try {
        const request = yield call(postMessageTemplateRequest, {...payload, media: null})

        if(request && request.data && request.data.id && payload.media){
            let data = new FormData()
            data.append('media', payload.media)
            yield call(putMediaMessageTemplate, request.data.id, data)
        }

        yield put(postMessageTemplateAPISuccess(request))
    } catch (error) {
        yield put(postMessageTemplateAPIFailure(error))
    }
}

export function* postMessageTemplate() {
    yield takeEvery(MessageTemplateActionType.POST_BY_ID_API, postMessageTemplateAPI)
}

export default function* rootSaga() {
    yield all([
        fork(getMessageTemplates),
        fork(getMessageTemplate),
        fork(putMessageTemplate),
        fork(postMessageTemplate),
    ])
}
