import PropTypes from 'prop-types'
import {Col, Form, Input, Modal, Row} from "antd"
import _ from 'lodash'
import React from "react"
import {EditOutlined} from "@ant-design/icons"
import {Button} from "../../components"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import UploadMedia from "../../components/upload-media"

const StyledHeading = styled.h3`
  font-weight: 600;
`

const MessageTemplateModal = ({visible, doneCallback, messageTemplateData}) => {
    const history =  useHistory()
    if (_.isEmpty(messageTemplateData)) {
        return null
    }
    return (
        <Modal
            visible={visible}
            onOk={doneCallback}
            onCancel={doneCallback}
            footer={[
                <Row justify="end">
                    <Col>
                        <Button label="Done" type="primary" onClick={doneCallback} />
                    </Col>
                </Row>,
            ]}
            width={980}
        >
            <div>
                <Row justify="space-between" style={{marginTop: '24px'}}>
                    <Col>
                        <StyledHeading>Message Template Detail</StyledHeading>
                    </Col>
                    <Col>
                        <Button label="Go to Message Template" icon={<EditOutlined />} onClick={() => history.push(`/message/${messageTemplateData.id}`)} />
                    </Col>
                </Row>
                <Row gutter={[12, 0]}>
                    <Col span={24}>
                        <Form.Item label="Subject" labelCol={{ span: 24 }}>
                            <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                size="large"
                                value={messageTemplateData.subject}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={20}>
                        <Form.Item label="Text" labelCol={{ span: 24 }}>
                            <Input.TextArea
                                name="text"
                                type="text"
                                placeholder="Text"
                                size="large"
                                value={messageTemplateData.text}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Media" labelCol={{ span: 24 }}>
                            <UploadMedia
                                media={messageTemplateData.media}
                                viewMode={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

MessageTemplateModal.propTypes = {
    visible: PropTypes.bool,
    doneCallback: PropTypes.func,
    messageTemplateData: PropTypes.object,
}

export default MessageTemplateModal
