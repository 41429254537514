import React from 'react'
import { Link } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { StatusActionType } from './../../types'
import { Button } from './../../../components'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  statuses: [
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Sent' },
  ],
  statusListColumns: [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (id) => (
        <Link to={`/status/${id}`} key={id}>
          <Button>
            <EyeOutlined />
          </Button>
        </Link>

      ),
    },
    {
      title: 'Mac Address',
      dataIndex: 'mac_address',
      key: 'mac_address',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => active ? 'Yes' : 'No',
    },
  ],
  status: null,
  statusInputs: {
    mac_address: '',
  },
  id: 0,
  eventStatuses: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case StatusActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case StatusActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case StatusActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        eventStatuses: action.payload.data,
      }

    case StatusActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case StatusActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case StatusActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.data,
        statusInputs: action.payload.data,
      }

    case StatusActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case StatusActionType.CHANGE_STATUS_INPUT_VALUE:
      return { ...state, statusInputs: { ...state.statusInputs, [action.payload.property]: action.payload.value } }

    case StatusActionType.SET_ORIGINAL_STATUS_VALUE:
      return { ...state, statusInputs: action.payload.isNew ? defaultState.statusInputs : state.status }

    case StatusActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case StatusActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case StatusActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case StatusActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case StatusActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case StatusActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
