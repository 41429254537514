export const AuthActionType = {
  SIGN_IN_API: 'AUTH.SIGN_IN_API',
  SIGN_IN_API_SUCCESS: 'AUTH.SIGN_IN_API_SUCCESS',
  SIGN_IN_API_FAILURE: 'AUTH.SIGN_IN_API_FAILURE',
  CHANGE_SIGN_IN_INPUT_VALUE: 'AUTH.CHANGE_SIGN_IN_INPUT_VALUE',
  SEND_VERIFICATION_CODE_API: 'AUTH.SEND_VERIFICATION_CODE_API',
  SEND_VERIFICATION_CODE_API_SUCCESS: 'AUTH.SEND_VERIFICATION_CODE_API_SUCCESS',
  SEND_VERIFICATION_CODE_API_FAILURE: 'AUTH.SEND_VERIFICATION_CODE_API_FAILURE',
  RESET_PASSWORD_API: 'AUTH.RESET_PASSWORD_API',
  RESET_PASSWORD_API_SUCCESS: 'AUTH.RESET_PASSWORD_API_SUCCESS',
  RESET_PASSWORD_API_FAILURE: 'AUTH.RESET_PASSWORD_API_FAILURE',
  CHANGE_RESET_PASSWORD_INPUT_VALUE: 'AUTH.CHANGE_RESET_PASSWORD_INPUT_VALUE',
  SET_PROPERTY_VALUE: 'AUTH.SET_PROPERTY_VALUE',
  SIGN_OUT_API: 'AUTH.SIGN_OUT_API',
}
