import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { LayoutAdmin, ButtonForm } from './../../../components'
import {
  getMessageAPI, putMessageAPI, setOriginalMessageValue, postMessageAPI, setMessagePropertyValue, getLedSequenceListAPI,
} from '../../../effects/actions'
import { MessageForm } from './../../../forms'
import { message } from 'antd'
import { Util } from '../../../utils'

const MessageFormPage = ({
  messageInputs, loading, successMessage, errorMessage, setOriginalMessageValue,
  getMessageAPI, setMessagePropertyValue, id, putMessageAPI, postMessageAPI, getLedSequenceListAPI,
}) => {
  const [viewMode, setViewMode] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const idParam = useParams().id
  const history = useHistory()

  useEffect(() => {
    getLedSequenceListAPI()
    if (idParam !== 'new') {
      getMessageAPI(idParam)
      setIsNew(false)
      setViewMode(true)
    } else {
      setViewMode(false)
      setIsNew(true)
      setOriginalMessageValue(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMessageAPI, idParam, setOriginalMessageValue])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (successMessage !== null && isNew) {
      history.push({
        pathname: `/message`,
      })
    }
  }, [history, id, isNew, successMessage])

  useEffect(() => {
    if (errorMessage !== null) {
      message.error(Util.renderMessage(errorMessage))
      setMessagePropertyValue('errorMessage', null)
      setViewMode(false)
    }
  }, [errorMessage, setMessagePropertyValue])

  const onSave = () => {
    if (!isNew) {
      putMessageAPI(messageInputs)
    } else {
      postMessageAPI(messageInputs)
    }
  }

  const onCancel = () => {
    setOriginalMessageValue(isNew)
  }

  return (
    <LayoutAdmin loading={loading}>
      <ButtonForm title="Message" onSave={onSave} onCancel={onCancel} viewMode={viewMode} setViewMode={setViewMode} isNew={isNew} hideEdit={messageInputs.is_notification} />
      <MessageForm viewMode={viewMode} />
    </LayoutAdmin>
  )
}

MessageFormPage.propTypes = {
  getMessageAPI: PropTypes.func,
  messageInputs: PropTypes.any,
  loading: PropTypes.bool,
  putMessageAPI: PropTypes.func,
  setOriginalMessageValue: PropTypes.func,
  successMessage: PropTypes.string,
  id: PropTypes.number,
  errorMessage: PropTypes.string,
  setMessagePropertyValue: PropTypes.func,
}

const mapStateToProps = ({ messageAPI }) => {
  const {
    messageInputs, loading,
    successMessage, id, errorMessage,
  } = messageAPI
  return {
    messageInputs,
    loading,
    successMessage,
    id,
    errorMessage,
  }
}

export default
connect(mapStateToProps, {
  getMessageAPI,
  putMessageAPI,
  setOriginalMessageValue,
  postMessageAPI,
  setMessagePropertyValue,
  getLedSequenceListAPI,
})(MessageFormPage)
