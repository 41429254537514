import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  getMemberSplashesAPIFailure,
  getMemberSplashesAPISuccess,
  getMemberSplashAPISuccess,
  getMemberSplashAPIFailure,
  putMemberSplashAPISuccess,
  putMemberSplashAPIFailure,
  postMemberSplashAPISuccess,
  postMemberSplashAPIFailure,
  deleteMemberSplashAPIFailure,
  deleteMemberSplashAPISuccess, repositionMemberSplashAPISuccess, repositionMemberSplashAPIFailure,
} from '../../actions'
import { MemberSplashActionType } from '../../types'
import { API } from '../../../utils/api'


const getMemberSplashesRequest = async ({member_id, orderKey = 'order', orderDirection='desc'}) => {
  return API.get(`member-splashes/list/${member_id}?orderKey=${orderKey}&orderDirection=${orderDirection}`)
}

function* getMemberSplashesAPI({params}) {
  try {
    const request = yield call(getMemberSplashesRequest, params)
    yield put(getMemberSplashesAPISuccess(request.data))
  } catch (error) {
    yield put(getMemberSplashesAPIFailure(error))
  }
}

export function* getMemberSplashes() {
  yield takeEvery(MemberSplashActionType.GET_LIST_API, getMemberSplashesAPI)
}

const getMemberSplashRequest = async (id) => API.get(`member-splash/${id}`)
function* getMemberSplashAPI({ payload }) {
  try {
    const request = yield call(getMemberSplashRequest, payload)
    yield put(getMemberSplashAPISuccess(request))
  } catch (error) {
    yield put(getMemberSplashAPIFailure(error))
  }
}

export function* getMemberSplash() {
  yield takeEvery(MemberSplashActionType.GET_BY_ID_API, getMemberSplashAPI)
}

const putMemberSplashRequest = async (data) => API.put(`member-splash/${data.id}`, data)
function* putMemberSplashAPI({ payload }) {
  try {
    let media =  typeof payload.media !== 'string' ? null : payload.media
    const request = yield call(putMemberSplashRequest, {
      ...payload,
      media,
      time_length: payload.time_length * 1000,
    })

    if(payload && payload.id && typeof payload.media !== 'string'){
      let data = new FormData()
      data.append('media', payload.media)
      yield call(putMediaSplash, payload.id, data)
    }
    yield put(putMemberSplashAPISuccess(request.data))
  } catch (error) {
    yield put(putMemberSplashAPIFailure(error))
  }
}

const deleteMemberSplashRequest = async (splashId) => API.delete(`member-splash/${splashId}`)
function* deleteMemberSplashAPI({payload}) {
  try {
    const request = yield call(deleteMemberSplashRequest, payload.id)
    yield put(deleteMemberSplashAPISuccess(request.data))
  } catch (error) {
    yield put(deleteMemberSplashAPIFailure(error))
  }
}

export function* putMemberSplash() {
  yield takeEvery(MemberSplashActionType.PUT_BY_ID_API, putMemberSplashAPI)
}

export function* deleteMemberSplash() {
  yield takeEvery(MemberSplashActionType.DELETE_BY_ID_API, deleteMemberSplashAPI)
}

const putMediaSplash = async (id, data) => API.put(`member-splashes/${id}/media`, data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

const postMemberSplashRequest = async (data) => API.post('member-splash', data)
function* postMemberSplashAPI({ payload }) {
  try {
    let request = yield call(postMemberSplashRequest, {
      ...payload,
      media: null,
      time_length: payload.time_length * 1000,
    })
    if(request && request.data && request.data.id){
      let data = new FormData()
      data.append('media', payload.media)
      request = yield call(putMediaSplash, request.data.id, data)
    }

    yield put(postMemberSplashAPISuccess(request))
  } catch (error) {
    yield put(postMemberSplashAPIFailure(error))
  }
}

export function* postMemberSplash() {
  yield takeEvery(MemberSplashActionType.POST_BY_ID_API, postMemberSplashAPI)
}

const repositionMemberSplashRequest = async (data) => API.post('member-splashes/reposition-item', data)
function* repositionMemberSplashAPI({ payload }) {
  try {
    const request = yield call(repositionMemberSplashRequest, {
      ...payload,
    })
    yield put(repositionMemberSplashAPISuccess(request.data))
  } catch (error) {
    console.log('Error is, ', error)
    yield put(repositionMemberSplashAPIFailure(error))
  }
}

export function* repositionMemberSplash() {
  yield takeEvery(MemberSplashActionType.REPOSITION_SPLASH_ITEM, repositionMemberSplashAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getMemberSplashes),
    fork(getMemberSplash),
    fork(putMemberSplash),
    fork(deleteMemberSplash),
    fork(postMemberSplash),
    fork(repositionMemberSplash),
  ])
}
