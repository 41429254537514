import React from 'react'
import {Container} from './../../components'
import ForgotPasswordForm from "../../forms/forgot-password"

const ForgotPasswordPage = () => {
  return (
    <Container>
      <ForgotPasswordForm />
    </Container>
  )
}

export default ForgotPasswordPage
