import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Tabs, message as AntMessage} from 'antd'
import {
  changeCampaignInputValue,
  getEventsAPI, getLedSequenceListAPI,
  patchMessageInfoAPI,
  postMessageAPI,
  setMessagePropertyValue,
} from './../../effects/actions'
import {Util} from './../../utils'
import {Button} from '../../components'
import {defaultFormatDate} from '../../constants/date'
import {MessageForm} from '..'
import DatePickerPst from "../../components/date-picker-pst"
import CampaignMessageRepliesTable from "../../components/campaign/campaign-message-replies-table"
import CampaignEventsTable from "../../components/campaign/campaign-events-table"
import {CampaignFormTabs} from "../../pages/campaign/constants"
import {useHistory, useParams} from "react-router"

const CampaignForm = ({
  campaignInputs, changeCampaignInputValue, viewMode, newMode,
  messageInputs, setOriginalMessageValue, postMessageAPI,
  id, patchMessageInfoAPI, getLedSequenceListAPI,
}) => {
  const [activeTabPane, setActiveTabPane] = useState(useParams().tabName || CampaignFormTabs.CAMPAIGN_FORM_INFO)
  const history = useHistory()

  const onChangeInput = (e) => {
    let { name, value } = e.target
    if(name === 'color_1' || name === 'color_2'){
      value = Util.hexToRgb(value)
    }
    changeCampaignInputValue(name, value)
  }

  const onSave = (isNew = true) => {
    if(isNew){
      if (!(messageInputs.event_id && messageInputs.event_id.value) && !(messageInputs.message_template_id && messageInputs.message_template_id.value)) {
        AntMessage.error("Please select a 'Event' or 'Message' to send the notification!")
      } else if (!(messageInputs.members && messageInputs.members.length)) {
        AntMessage.error("Select atleast one member to send notification")
      } else if (!(messageInputs.led_sequence_id)) {
        AntMessage.error("Led sequence is required to send a notification")
      } else {
        postMessageAPI({...messageInputs, campaign_id: id, is_notification: true})
      }
    }else{
      patchMessageInfoAPI({...messageInputs, campaign_id: id })
    }
  }

  const onCancel = () => {
    setOriginalMessageValue(true)
  }

  useEffect(() => {
    getLedSequenceListAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCampaignForm = (
    <Row gutter={[12, 0]}>
      <Col span={12}>
        <Form.Item label="Name" labelCol={{ span: 24 }}>
          <Input name="name" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={campaignInputs.name} disabled={viewMode} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Start Date" labelCol={{ span: 24 }}>
          <DatePickerPst
              value={campaignInputs.start_date}
              defaultValue={campaignInputs.start_date}
              onChange={(date) => {changeCampaignInputValue('start_date', date)}}
              disabled={viewMode}
              size="large"
              format={defaultFormatDate}
              allowClear={false}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="End Date" labelCol={{ span: 24 }}>
          <DatePickerPst
              value={campaignInputs.end_date}
              defaultValue={campaignInputs.end_date}
              onChange={(date) => changeCampaignInputValue('end_date', date)}
              disabled={viewMode}
              size="large"
              format={defaultFormatDate}
              allowClear={true}
          />
        </Form.Item>
      </Col>
    </Row>
  )

  const renderSendMessage = (
    <Row>
      <Col>
        <Row>
          <Col>
            <MessageForm viewMode={false} isNew={true} />
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button label="Cancel" onClick={() => onCancel()} />
          </Col>
          <Col>
            <Button label="Send" onClick={() => onSave()} />
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <div>
      <Tabs defaultActiveKey={activeTabPane} activeKey={activeTabPane} onChange={(tabId) => {
        setActiveTabPane(tabId)
        history.push(`/campaign/${id}/${tabId}`)
      }}>
        <Tabs.TabPane tab="Campaign" key={CampaignFormTabs.CAMPAIGN_FORM_INFO}>
          {renderCampaignForm}
        </Tabs.TabPane>
        {!newMode && <Tabs.TabPane tab="Events" key={CampaignFormTabs.EVENTS}>
          {activeTabPane === CampaignFormTabs.EVENTS && <CampaignEventsTable id={id}/>}
        </Tabs.TabPane>}
        {!newMode && <Tabs.TabPane tab="Send Notifications" key={CampaignFormTabs.SEND_NOTIFICATIONS}>
          {activeTabPane === CampaignFormTabs.SEND_NOTIFICATIONS && renderSendMessage}
        </Tabs.TabPane>}
        {!newMode && <Tabs.TabPane tab="Notifications" key={CampaignFormTabs.NOTIFICATIONS}>
          {activeTabPane === CampaignFormTabs.NOTIFICATIONS && <CampaignMessageRepliesTable id={id} />}
        </Tabs.TabPane>}
      </Tabs>
    </div>
  )
}

CampaignForm.propTypes = {
  changeCampaignInputValue: PropTypes.func,
  campaignInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  events: PropTypes.array,
  getEventsAPI: PropTypes.func,
  newMode: PropTypes.bool,
  loading: PropTypes.bool,
  messageInputs: PropTypes.object,
  setOriginalMessageValue: PropTypes.func,
  postMessageAPI: PropTypes.func,
  successSendMessage: PropTypes.string,
  errorSendMessage: PropTypes.string,
  setMessagePropertyValue: PropTypes.func,
  id: PropTypes.any,
  patchMessageInfoAPI: PropTypes.func,
}

CampaignForm.defaultProps = {
  viewMode: false,
  newMode: false,
}
const mapStateToProps = ({ campaignAPI, messageAPI }) => {
  const { campaignInputs, loading, types } = campaignAPI
  const { messageInputs } = messageAPI

  return {
    loading,
    campaignInputs,
    messageInputs,
    types,
  }
}

export default
connect(mapStateToProps, {
  changeCampaignInputValue,
  getEventsAPI,
  postMessageAPI,
  setMessagePropertyValue,
  patchMessageInfoAPI,
  getLedSequenceListAPI,
})(CampaignForm)
