import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects'

import {
  getLedSequenceListAPIFailure,
  getLedSequenceListAPISuccess,
  getLedSequenceAPISuccess,
  getLedSequenceAPIFailure,
  putLedSequenceAPISuccess,
  putLedSequenceAPIFailure,
  postLedSequenceAPISuccess,
  postLedSequenceAPIFailure,
  deleteLedSequenceAPISuccess,
  deleteLedSequenceAPIFailure,
} from '../../actions'
import {
  LedSequenceActionType,
} from '../../types'
import { API } from '../../../utils/api'

const getLedSequenceListRequest = async (queryParams) => {
  if (!queryParams) {
    return API.get(`led-sequence`)
  } else {
    const {limit, page, orderKey = 'id', orderDirection='desc'} = queryParams
    return API.get(`led-sequence?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
  }
}

function* getLedSequenceListAPI({params}) {
  try {
    const request = yield call(getLedSequenceListRequest, params)
    const responseData = params ? {
      ...request.data,
      paginatedResults: true,
    } : {
      data: request.data,
      paginatedResults: false,
    }
    yield put(getLedSequenceListAPISuccess(responseData))
  } catch (error) {
    yield put(getLedSequenceListAPIFailure(error))
  }
}

export function* getLedSequenceList() {
  yield takeLatest(LedSequenceActionType.GET_LIST_API, getLedSequenceListAPI)
}

const getLedSequenceRequest = async (id) => API.get(`led-sequence/${id}`)
function* getLedSequenceAPI({ payload }) {
  try {
    const request = yield call(getLedSequenceRequest, payload)
    yield put(getLedSequenceAPISuccess(request))
  } catch (error) {
    yield put(getLedSequenceAPIFailure(error))
  }
}

export function* getLedSequence() {
  yield takeLatest(LedSequenceActionType.GET_BY_ID_API, getLedSequenceAPI)
}

const putLedSequenceRequest = async (data) => API.put(`led-sequence/${data.id}`, data)
function* putLedSequenceAPI({ payload }) {
  try {
    const request = yield call(putLedSequenceRequest, payload)
    yield put(putLedSequenceAPISuccess(request))
  } catch (error) {
    yield put(putLedSequenceAPIFailure(error))
  }
}

export function* putLedSequence() {
  yield takeLatest(LedSequenceActionType.PUT_BY_ID_API, putLedSequenceAPI)
}

const postLedSequenceRequest = async (data) => API.post('led-sequence', data)
function* postLedSequenceAPI({ payload }) {
  try {
    const request = yield call(postLedSequenceRequest, payload)
    yield put(postLedSequenceAPISuccess(request))
  } catch (error) {
    yield put(postLedSequenceAPIFailure(error))
  }
}

export function* postLedSequence() {
  yield takeLatest(LedSequenceActionType.POST_BY_ID_API, postLedSequenceAPI)
}

const deleteLedSequenceRequest = async (ledSequenceId) => API.delete(`led-sequence/${ledSequenceId}`)
function* deleteLedSequenceAPI({ payload }) {
  try {
    if (!payload.id) {
      console.log('No led sequence id specified!')
      return
    }
    const response = yield call(deleteLedSequenceRequest, payload.id)
    yield put(deleteLedSequenceAPISuccess(response.data))
  } catch (error) {
    yield put(deleteLedSequenceAPIFailure(error))
  }
}

export function* deleteLedSequence() {
  yield takeLatest(LedSequenceActionType.DELETE_BY_ID_API, deleteLedSequenceAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getLedSequenceList),
    fork(getLedSequence),
    fork(putLedSequence),
    fork(postLedSequence),
    fork(deleteLedSequence),
  ])
}
