import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Button, Modal, Table, message as AntMessage} from 'antd'
import PropTypes from 'prop-types'
import {LayoutAdmin} from './../../../components'
import {deleteBoxAPI, getBoxesAPI, setBoxPropertyValue} from '../../../effects/actions'
import {EyeOutlined} from "@ant-design/icons"
import {defaultFormatDate} from "../../../constants/date"
import MemberModal from "../../../modals/member"
import styled from "styled-components"
import {Util} from "../../../utils"

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const BoxListPage = ({boxes, loading, totalBoxes, getBoxesAPI, deleteBoxAPI, successMessage}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const [showBoxDeleteModal, setShowBoxDeleteModal] = useState(false)
  const [boxToDelete, setBoxToDelete] = useState({})

  const [showMemberModal, setShowMemberModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState()

  const history = useHistory()

  useEffect(() => {
    getBoxesAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getBoxesAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setBoxPropertyValue('successMessage', null)
    }
  }, [successMessage])

  const boxListColumnsCustom = [
    {
      title: 'View',
      dataIndex: 'id',
      key: 'action',
      width: 50,
      render: (id) => (
          <Link to={`/box/${id}`} key={id}>
            <Button>
              <EyeOutlined />
            </Button>
          </Link>
      ),
    },
    {
      title: 'Box name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('name')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Mac Address',
      dataIndex: 'mac_address',
      key: 'mac_address',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('mac_address')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'First Connected At',
      dataIndex: 'first_connected_at',
      key: 'first_connected_at',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('first_connected_at')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (date) => {
        if(date) return Util.convertDateToPST(date).format(defaultFormatDate)
        return "No info yet"
      },
    },
    {
      title: 'Last Connected At',
      dataIndex: 'last_connected_at',
      key: 'last_connected_at',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('last_connected_at')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (date) => {
        if(date) return Util.convertDateToPST(date).format(defaultFormatDate)
        return "No info yet"
      },
    },
    {
      title: 'Assigned',
      dataIndex: 'member',
      key: 'member',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('member')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (member) => member ? (
          <StyledLink onClick={() => handleMemberCellClick(member)}>
            {member.id} - {member.name}
          </StyledLink>
      ) : '-',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <Button danger disabled={record.member} onClick={() => handleDeleteConfirmation(record)}>Delete Box</Button>
      ),
    },
  ]

  const handleDeleteConfirmation = (box) => {
    setBoxToDelete(box)
    setShowBoxDeleteModal(true)
  }

  const cancelBoxDelete = () => {
    setBoxToDelete({})
    setShowBoxDeleteModal(false)
  }

  const handleDeleteBox = () => {
    setShowBoxDeleteModal(false)
    deleteBoxAPI(boxToDelete)
  }

  const handleMemberCellClick = (data) => {
    setShowMemberModal(true)
    setSelectedMember(data)
  }

  const handleMemberDoneCallback = () => {
    setShowMemberModal(false)
    setSelectedMember(null)
  }

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }
  return (
    <LayoutAdmin>
      <Button style={{ marginBottom: 8 }} onClick={() => history.push('/box/new')}>New</Button>
      <Table
          dataSource={boxes}
          columns={boxListColumnsCustom}
          pagination={{ total: totalBoxes, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
          loading={loading}
          rowKey="id"
      />
      <Modal
          title={<b>Are you sure?</b>}
          visible={showBoxDeleteModal}
          onOk={handleDeleteBox}
          onCancel={cancelBoxDelete}
          okText={'Delete'}
          okButtonProps={{ type: 'danger' }}
      >Delete {boxToDelete.name} - {boxToDelete.mac_address}</Modal>
      <MemberModal
          visible={showMemberModal}
          doneCallback={handleMemberDoneCallback}
          memberData={selectedMember}
      />
    </LayoutAdmin>
  )
}

BoxListPage.propTypes = {
  getBoxesAPI: PropTypes.func,
  boxes: PropTypes.array,
  loading: PropTypes.bool,
  removed: PropTypes.any,
  deleteBoxAPI: PropTypes.func,
}

const mapStateToProps = ({ boxAPI }) => {
  const {
    boxes, loading, totalBoxes, removed, successMessage,
  } = boxAPI
  return {
    boxes,
    loading,
    totalBoxes,
    successMessage,
    removed,
  }
}

export default
connect(mapStateToProps, {
  getBoxesAPI,
  deleteBoxAPI,
  setBoxPropertyValue,
})(BoxListPage)
