import React from 'react'
import styled from 'styled-components'
import image from './index.svg'

const Center = styled.div`
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 24px;
    margin-bottom: 8px;
`

const Logo = () => {
    return <Center><img src={image} alt="logo bl" /></Center>
}

export default Logo