import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select, Table, Tabs} from 'antd'
import {changeEventInputValue, getStatusesAPI} from './../../effects/actions'
import {defaultFormatDate} from '../../constants/date'
import UploadMedia, {SupportedFileTypes} from "../../components/upload-media"
import DatePickerPst from "../../components/date-picker-pst"
import {useHistory, useParams} from "react-router"
import {EventsFormTabs} from "../../pages/event/constants"

const EventForm = ({
  eventInputs, changeEventInputValue, viewMode, getStatusesAPI, eventStatuses,
  messageReceiverRepliesListColumns, messageReceiverReplies, loading, id,
}) => {
  const [activeTabPane, setActiveTabPane] = useState(useParams().tabName || EventsFormTabs.EVENT_INFO)
  const history = useHistory()

    // TODO why are we doing this check ?
  let columns = messageReceiverRepliesListColumns
  const columnIndex = messageReceiverRepliesListColumns.findIndex((x) => x && x.title === 'Event')
  if(columnIndex !== -1){
    delete columns[columnIndex]
  }
  useEffect(() => {
    getStatusesAPI()
  }, [getStatusesAPI])

  const onChangeInput = (e) => {
    const { name, value } = e.target
    changeEventInputValue(name, value)
  }

  const options = eventStatuses.map(d => <Select.Option key={d.id}>{d.name}</Select.Option>)

  const renderReplies = (
    <Table dataSource={messageReceiverReplies} columns={columns} loading={loading} rowKey="id" />
  )

  const renderForm = (
    <Row gutter={[12, 0]}>
      <Col span={12}>
        <Form.Item label="Headline" labelCol={{ span: 24 }}>
          <Input name="headline" type="text" placeholder="Headline" size="large" onChange={onChangeInput} value={eventInputs.headline} disabled={viewMode} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Subheadline" labelCol={{ span: 24 }}>
          <Input name="subheadline" type="text" placeholder="Subheadline" size="large" onChange={onChangeInput} value={eventInputs.subheadline} disabled={viewMode} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Start Date" labelCol={{ span: 24 }}>
          <DatePickerPst
              onChange={(date) => changeEventInputValue('start_date', date)}
              value={eventInputs.start_date}
              disabled={viewMode}
              size="large"
              format={defaultFormatDate}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="End Date" labelCol={{ span: 24 }}>
          <DatePickerPst
              onChange={(date) => changeEventInputValue('end_date', date)}
              value={eventInputs.end_date}
              disabled={viewMode}
              size="large"
              format={defaultFormatDate}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Location" labelCol={{ span: 24 }}>
          <Input name="location" type="text" placeholder="Location" size="large" onChange={onChangeInput} value={eventInputs.location} disabled={viewMode} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Status" labelCol={{ span: 24 }}>
          <Select
            showSearch
            value={eventInputs.status_id}
            placeholder={"Status"}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={true}
            onChange={(value) => {
              changeEventInputValue('status_id', value)
            }}
            notFoundContent={<>0 results found</>}
            size="large"
            disabled={viewMode}
            labelInValue={true}
          >
            {options}
          </Select>
        </Form.Item>

      </Col>
      <Col span={24}>
        <Form.Item label="Description" labelCol={{ span: 24 }}>
          <Input.TextArea name="description" type="textarea" placeholder="Description" size="large" onChange={(e) => changeEventInputValue('description', e.target.value)} value={eventInputs.description} disabled={viewMode} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Landing image" labelCol={{ span: 24 }}>
            <UploadMedia
                media={eventInputs.media}
                allowedFileTypes={[SupportedFileTypes.IMAGE_JPG, SupportedFileTypes.IMAGE_PNG]}
                changeHandler={(value) => changeEventInputValue('media', value)}
                viewMode={viewMode}
            />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Details image" labelCol={{ span: 24 }}>
            <UploadMedia
                media={eventInputs.media_detail}
                allowedFileTypes={[SupportedFileTypes.IMAGE_JPG, SupportedFileTypes.IMAGE_PNG]}
                changeHandler={(value) => changeEventInputValue('media_detail', value)}
                viewMode={viewMode}
            />
        </Form.Item>
      </Col>
    </Row>
  )
  return (
    <Tabs defaultActiveKey={activeTabPane} activeKey={activeTabPane} onChange={(tabId) => {
        setActiveTabPane(tabId)
        history.push(`/event/${id}/${tabId}`)
    }}>
      <Tabs.TabPane tab="Event" key={EventsFormTabs.EVENT_INFO}>
        {renderForm}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Replies" key={EventsFormTabs.REPLIES}>
          {activeTabPane === EventsFormTabs.REPLIES && renderReplies}
      </Tabs.TabPane>
    </Tabs>
  )
}

EventForm.propTypes = {
  changeEventInputValue: PropTypes.func,
  eventInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  eventStatuses: PropTypes.array,
  getStatusesAPI: PropTypes.func,
}

EventForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ eventAPI, statusAPI, messageAPI }) => {
  const {
    errorMessage, successMessage, eventInputs,
  } = eventAPI
  const {
    eventStatuses,
  } = statusAPI

  const {
    messageReceiverRepliesListColumns,
    messageReceiverReplies,
    loading,
  } = messageAPI

  return {
    errorMessage,
    successMessage,
    eventInputs,
    eventStatuses,
    messageAPI,
    messageReceiverRepliesListColumns,
    messageReceiverReplies,
    loading,
  }
}

export default
connect(mapStateToProps, {
  changeEventInputValue,
  getStatusesAPI,
})(EventForm)
