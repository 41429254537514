import {MembersRequestActionType} from '../../types'

const defaultState = {
    successMessage: null,
    errorMessage: null,
    loading: false,
    metaData: {
        messageRequestsToHandle: 0,
        contactMeRequestsToHandle: 0,
        responseOptions: [],
    },
    messageSuccessMessage: null,
    messageErrorMessage: null,
    messageLoading: false,
    messageRequests: [],
    totalRequests: 0,
    contactMeSuccessMessage: null,
    contactMeErrorMessage: null,
    contactMeLoading: false,
    contactMeList: [],
    totalContactMe: 0,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case MembersRequestActionType.SET_PROPERTY_VALUE:
            return {
                ...state,
                [action.payload.property]: action.payload.value,
            }

        case MembersRequestActionType.GET_LIST_API:
            return {
                ...state,
                messageLoading: true,
            }
        case MembersRequestActionType.GET_LIST_API_SUCCESS:
            return {
                ...state,
                messageLoading: false,
                messageRequests: action.payload.data,
                messageSuccessMessage: 'Successfully fetched members response list!',
                totalRequests: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
                metaData: {
                    ...state.metaData,
                    messageRequestsToHandle: action.payload.requestsToHandle,
                },
            }

        case MembersRequestActionType.GET_LIST_API_FAILURE:
            return { ...state, messageLoading: false, errorMessage: action.payload.data }

        case MembersRequestActionType.PUT_BY_ID_API:
            return {
                ...state,
                messageLoading: true,
            }

        case MembersRequestActionType.PUT_BY_ID_API_SUCCESS:
            return {
                ...state,
                messageLoading: false,
                messageRequests: state.messageRequests.map(request => request.id === action.payload.id ? {...request, is_contacted: action.payload.is_contacted}: request),
                messageSuccessMessage: `Member request with id: "${action.payload.id}", has been updated successfully!`,
                metaData: {
                    ...state.metaData,
                    messageRequestsToHandle: action.payload.is_contacted ? state.metaData.messageRequestsToHandle - 1 : state.metaData.messageRequestsToHandle + 1,
                },
            }

        case MembersRequestActionType.PUT_BY_ID_API_FAILURE:
            return { ...state, messageLoading: false, errorMessage: action.payload.data }

        case MembersRequestActionType.DELETE_BY_ID_API:
            return {
                ...state,
                messageLoading: true,
            }

        case MembersRequestActionType.DELETE_BY_ID_API_SUCCESS:
            return {
                ...state,
                messageLoading: false,
                messageRequests: state.messageRequests.filter(request => request.id !== action.payload.id),
                messageSuccessMessage: 'message.success',
            }

        case MembersRequestActionType.DELETE_BY_ID_API_FAILURE:
            return { ...state, messageLoading: false, errorMessage: action.payload.data }

        case MembersRequestActionType.GET_CONTACT_ME_LIST_API:
            return {
                ...state,
                contactMeLoading: true,
            }

        case MembersRequestActionType.GET_CONTACT_ME_LIST_API_SUCCESS:
            return {
                ...state,
                contactMeLoading: false,
                contactMeList: action.payload.data,
                contactMeSuccessMessage: 'Fetched contact me list!',
                totalContactMe: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
                metaData: {
                    ...state.metaData,
                    contactMeRequestsToHandle: action.payload.requestsToHandle,
                },
            }

        case MembersRequestActionType.GET_CONTACT_ME_LIST_API_FAILURE:
            return { ...state, contactMeLoading: false, contactMeErrorMessage: action.payload.data }

        case MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API:
            return {
                ...state,
                contactMeLoading: true,
            }

        case MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API_SUCCESS:
            return {
                ...state,
                contactMeLoading: false,
                contactMeList: state.contactMeList.map(request => request.id === action.payload.id ? {...request, is_contacted: action.payload.is_contacted}: request),
                contactMeSuccessMessage: `Member request with id: "${action.payload.id}", has been updated successfully!`,
                metaData: {
                    ...state.metaData,
                    contactMeRequestsToHandle: action.payload.is_contacted ? state.metaData.contactMeRequestsToHandle - 1 : state.metaData.contactMeRequestsToHandle + 1,
                },
            }

        case MembersRequestActionType.PUT_CONTACT_ME_BY_ID_API_FAILURE:
            return { ...state, contactMeLoading: false, contactMeErrorMessage: action.payload.data }

        case MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API:
            return {
                ...state,
                contactMeLoading: true,
            }

        case MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API_SUCCESS:
            return {
                ...state,
                contactMeLoading: false,
                contactMeList: state.contactMeList.filter(request => request.id !== action.payload.id),
                contactMeSuccessMessage: 'message.success',
            }

        case MembersRequestActionType.DELETE_CONTACT_ME_BY_ID_API_FAILURE:
            return { ...state, contactMeLoading: false, contactMeErrorMessage: action.payload.data }

        case MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API:
            return {
                ...state,
                loading: true,
            }

        case MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: 'message.success',
                metaData: action.payload,
            }

        case MembersRequestActionType.GET_MESSAGE_REQUEST_META_DATA_API_FAILURE:
            return { ...state, loading: false, errorMessage: action.payload.data }

        default: return { ...state }
    }
}
