import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects'

import {
  getBoxesAPIFailure,
  getBoxesAPISuccess,
  getBoxAPISuccess,
  getBoxAPIFailure,
  putBoxAPISuccess,
  putBoxAPIFailure,
  postBoxAPISuccess,
  postBoxAPIFailure,
  getAvailableBoxesAPIFailure,
  getAvailableBoxesAPISuccess,
  patchBoxAPISuccess,
  patchBoxAPIFailure, deleteBoxAPISuccess, deleteBoxAPIFailure,
} from './../../actions'
import {
  BoxActionType,
} from '../../types'
import { API } from '../../../utils/api'

const getBoxesRequest = async ({limit=10, page=1, orderKey = 'id', orderDirection='desc'}) => {
  return API.get(`box?limit=${limit}&page=${page}&orderKey=${orderKey}&orderDirection=${orderDirection}`)
}
function* getBoxesAPI({params}) {
  try {
    const request = yield call(getBoxesRequest, params)
    yield put(getBoxesAPISuccess(request.data))
  } catch (error) {
    yield put(getBoxesAPIFailure(error))
  }
}

export function* getBoxes() {
  yield takeLatest(BoxActionType.GET_LIST_API, getBoxesAPI)
}

const getBoxRequest = async (id) => API.get(`box/${id}`)
function* getBoxAPI({ payload }) {
  try {
    const request = yield call(getBoxRequest, payload)
    yield put(getBoxAPISuccess(request))
  } catch (error) {
    yield put(getBoxAPIFailure(error))
  }
}

export function* getBox() {
  yield takeLatest(BoxActionType.GET_BY_ID_API, getBoxAPI)
}

const putBoxRequest = async (data) => API.put(`box/${data.id}`, data)
function* putBoxAPI({ payload }) {
  try {
    let box = payload
    delete box.member
    const request = yield call(putBoxRequest, box)
    yield put(putBoxAPISuccess(request))
  } catch (error) {
    yield put(putBoxAPIFailure(error))
  }
}

export function* putBox() {
  yield takeLatest(BoxActionType.PUT_BY_ID_API, putBoxAPI)
}

const postBoxRequest = async (data) => API.post('box', data)
function* postBoxAPI({ payload }) {
  try {
    const request = yield call(postBoxRequest, payload)
    yield put(postBoxAPISuccess(request))
  } catch (error) {
    yield put(postBoxAPIFailure(error))
  }
}

export function* postBox() {
  yield takeLatest(BoxActionType.POST_BY_ID_API, postBoxAPI)
}


const getAvailableBoxesRequest = async () => API.get('boxes/available')
function* getAvailableBoxesAPI() {
  try {
    const request = yield call(getAvailableBoxesRequest)
    yield put(getAvailableBoxesAPISuccess(request))
  } catch (error) {
    yield put(getAvailableBoxesAPIFailure(error))
  }
}

export function* getAvailableBoxes() {
  yield takeLatest(BoxActionType.GET_AVAILABLES_API, getAvailableBoxesAPI)
}

const patchBoxRequest = async (data) => API.patch(`box/${data.id}`, data)
function* patchBoxAPI({ payload }) {
  try {
    let box = payload
    if(box.member) delete box.member
    const request = yield call(patchBoxRequest, box)
    yield put(patchBoxAPISuccess(request))
  } catch (error) {
    yield put(patchBoxAPIFailure(error))
  }
}

export function* patchBox() {
  yield takeLatest(BoxActionType.PATCH_BY_ID_API, patchBoxAPI)
}

const deleteBoxRequest = async (boxId) => API.delete(`box/${boxId}`)
function* deleteBoxAPI({ payload }) {
  try {
    if (!payload.id) {
      console.log('No box id specified!')
      return
    }
    const response = yield call(deleteBoxRequest, payload.id)
    yield put(deleteBoxAPISuccess(response.data))
  } catch (error) {
    yield put(deleteBoxAPIFailure(error))
  }
}

export function* deleteBox() {
  yield takeLatest(BoxActionType.DELETE_BY_ID_API, deleteBoxAPI)
}

export default function* rootSaga() {
  yield all([
    fork(getBoxes),
    fork(getBox),
    fork(putBox),
    fork(postBox),
    fork(getAvailableBoxes),
    fork(patchBox),
    fork(deleteBox),
  ])
}
