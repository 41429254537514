import React from 'react'
import { LayoutAdmin } from './../../components'

const HomePage = () => {
  return (
    <LayoutAdmin>home</LayoutAdmin>
  )
}

HomePage.propTypes = {
}

export default HomePage
