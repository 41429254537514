import {AdminActionType} from './../../types'

export const getAdminsAPISuccess = (response) => ({
  type: AdminActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getAdminsAPIFailure = (
  error,
) => ({
  type: AdminActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getAdminsAPI = (queryParams) => ({
  type: AdminActionType.GET_LIST_API,
  params: queryParams,
})

export const getAdminAPISuccess = (response) => ({
  type: AdminActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getAdminAPIFailure = (
  error,
) => ({
  type: AdminActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getAdminAPI = (
  id,
) => ({
  type: AdminActionType.GET_BY_ID_API,
  payload: id,
})

export const changeAdminInputValue = (
  property, value,
) => ({
  type: AdminActionType.CHANGE_ADMIN_INPUT_VALUE,
  payload: { property, value },
})

export const changeAdminUserInputValue = (
    property, value,
) => ({
  type: AdminActionType.CHANGE_ADMIN_USER_INPUT_VALUE,
  payload: { property, value },
})

export const setAdminPropertyValue = (
  property, value,
) => ({
  type: AdminActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putAdminAPISuccess = (response) => ({
  type: AdminActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putAdminAPIFailure = (
  error,
) => ({
  type: AdminActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putAdminAPI = (
  data,
) => ({
  type: AdminActionType.PUT_BY_ID_API,
  payload: data,
})

export const setOriginalAdminValue = (isNew) => ({
  type: AdminActionType.SET_ORIGINAL_ADMIN_VALUE,
  payload: { isNew },
})

export const postAdminAPISuccess = (response) => ({
  type: AdminActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postAdminAPIFailure = (
  error,
) => ({
  type: AdminActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postAdminAPI = (
  data,
) => ({
  type: AdminActionType.POST_BY_ID_API,
  payload: data,
})

export const deleteAdminAPISuccess = (response) => ({
  type: AdminActionType.DELETE_BY_ID_API_SUCCESS,
  payload: response,
})

export const deleteAdminAPIFailure = (
    error,
) => ({
  type: AdminActionType.DELETE_BY_ID_API_FAILURE,
  payload: error,
})

export const deleteAdminAPI = (
    data,
) => ({
  type: AdminActionType.DELETE_BY_ID_API,
  payload: data,
})
