import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, h1 } from 'antd'
import { Button } from './../..'

const ButtonFormComponent = ({
  title, viewMode, isNew, hideEdit, setViewMode, onSave, onEdit, onCancel,
}) => {
  const handleSave = () => {
    setViewMode(!viewMode)
    onSave()
  }

  const handleCancel = () => {
    setViewMode(!viewMode)
    onCancel()
  }

  const handleEdit = () => {
    setViewMode(!viewMode)
    onEdit()
  }

  return (
    <Row>
      <Col span={20}><h1>{title}</h1></Col>
      {(!viewMode && !isNew) && <Col span={2}><Button marginh={String(true)} onClick={handleCancel} label="Cancel" /></Col>}
      {(!viewMode || isNew) && <Col span={isNew ? 4 : 2}><Button marginh={String(true)} onClick={handleSave} label="Save" /></Col>}
      {(viewMode && !isNew && !hideEdit) && (
        <Col span={4}><Button full="true" onClick={handleEdit} label="Edit" /></Col>
      )}
    </Row>
  )
}

ButtonFormComponent.propTypes = {
  title: PropTypes.any,
  viewMode: PropTypes.bool,
  isNew: PropTypes.bool,
  hideEdit: PropTypes.bool,
  setViewMode: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
}

ButtonFormComponent.defaultProps = {
  onSave: () => {},
  onEdit: () => {},
  onCancel: () => {},
  setViewMode: () => {},
}

export default ButtonFormComponent
