export const MessageTemplateActionType = {
    SET_PROPERTY_VALUE: 'MESSAGE_TEMPLATE.SET_PROPERTY_VALUE',
    GET_LIST_API: 'MESSAGE_TEMPLATE.GET_LIST_API',
    GET_LIST_API_SUCCESS: 'MESSAGE_TEMPLATE.GET_LIST_API_SUCCESS',
    GET_LIST_API_FAILURE: 'MESSAGE_TEMPLATE.GET_LIST_API_FAILURE',
    GET_BY_ID_API: 'MESSAGE_TEMPLATE.GET_BY_ID_API',
    GET_BY_ID_API_SUCCESS: 'MESSAGE_TEMPLATE.GET_BY_ID_API_SUCCESS',
    GET_BY_ID_API_FAILURE: 'MESSAGE_TEMPLATE.GET_BY_ID_API_FAILURE',
    CHANGE_MESSAGE_TEMPLATE_INPUT_VALUE: 'MESSAGE_TEMPLATE.CHANGE_MESSAGE_TEMPLATE_INPUT_VALUE',
    PUT_BY_ID_API: 'MESSAGE_TEMPLATE.PUT_BY_ID_API',
    PUT_BY_ID_API_FAILURE: 'MESSAGE_TEMPLATE.PUT_BY_ID_API_FAILURE',
    PUT_BY_ID_API_SUCCESS: 'MESSAGE_TEMPLATE.PUT_BY_ID_API_SUCCESS',
    SET_ORIGINAL_MESSAGE_TEMPLATE_VALUE: 'MESSAGE_TEMPLATE.SET_ORIGINAL_MESSAGE_TEMPLATE_VALUE',
    POST_BY_ID_API: 'MESSAGE_TEMPLATE.POST_BY_ID_API',
    POST_BY_ID_API_FAILURE: 'MESSAGE_TEMPLATE.POST_BY_ID_API_FAILURE',
    POST_BY_ID_API_SUCCESS: 'MESSAGE_TEMPLATE.POST_BY_ID_API_SUCCESS',
}
