export const CampaignActionType = {
  SET_PROPERTY_VALUE: 'CAMPAIGN.SET_PROPERTY_VALUE',
  GET_LIST_API: 'CAMPAIGN.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'CAMPAIGN.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'CAMPAIGN.GET_LIST_API_FAILURE',
  GET_BY_ID_API: 'CAMPAIGN.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'CAMPAIGN.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'CAMPAIGN.GET_BY_ID_API_FAILURE',
  CHANGE_CAMPAIGN_INPUT_VALUE: 'CAMPAIGN.CHANGE_CAMPAIGN_INPUT_VALUE',
  PUT_BY_ID_API: 'CAMPAIGN.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'CAMPAIGN.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'CAMPAIGN.PUT_BY_ID_API_SUCCESS',
  SET_ORIGINAL_CAMPAIGN_VALUE: 'CAMPAIGN.SET_ORIGINAL_CAMPAIGN_VALUE',
  POST_BY_ID_API: 'CAMPAIGN.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'CAMPAIGN.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'CAMPAIGN.POST_BY_ID_API_SUCCESS',

}
