import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {MessageActionType} from './../../types'
import {defaultFormatDate} from '../../../constants/date'
import {Util} from "../../../utils"

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  types: ["message", "event"],
  messages: [],
  totalMessages: 0,
  messageEvents: [],
  totalMessageEvents: 0,
  messageDataUpdated: false,
  messageReceiverRepliesListColumns: [
    {
      title: 'Is notification?',
      dataIndex: 'isNotification',
      width: 20,
      render: (message, data) => {
          return data && data.message && data.message.is_notification ? "Yes" : "No"
      },
    },
    {
      title: 'Notification',
      dataIndex: 'notification',
      width: 50,
      sorter: (a, b) => ((a.message && a.message.message_template_id) && (b.message && b.message.message_template_id)) && a.message.message_template_id.toString().localeCompare(b.message.message_template_id),
      sortDirections: ['descend', 'ascend'],
      render: (message, data) => {
          return data.id
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width: 50,
      sorter: (a, b) => ((a.message && a.message.message_template_id) && (b.message && b.message.message_template_id)) && a.message.message_template_id.toString().localeCompare(b.message.message_template_id),
      sortDirections: ['descend', 'ascend'],
      render: (message) => {
        if(message && message.message_template_id){
          let id = message.message_template_id
          return (
            <Link to={`/message/${id}`} key={id}>
              {id}
            </Link>
          )
        }
        return '-'
      },
    },
    {
      title: 'Event',
      dataIndex: 'event',
      width: 50,
      sorter: (a, b) => ((a.message && a.message.event_id) && (b.message && b.message.event_id))  && a.message.event_id.toString().localeCompare(b.message.event_id),
      sortDirections: ['descend', 'ascend'],
      render: (record, data) => {
        if(data && data.message && data.message.event_id){
          let id = data.message.event_id
          return (
            <Link to={`/event/${id}`} key={id}>
              {id}
            </Link>
          )
        } else{
          return '-'
        }

      },
    },
    {
      title: 'Member',
      dataIndex: 'member',
      sorter: (a, b) => ((a && a.member) && (b.member)) && a.member.name.toString().localeCompare(b.member.name),
      sortDirections: ['descend', 'ascend'],
      render: (member) => member ? (
        <Link to={`/member/${member.id}`} key={member.id}>
          {member.id} - {member.name}`
        </Link>
      ) : '-',
    },
    {
      title: 'Response',
      dataIndex: 'status',
      render: (status, record) => {
        if(status.id === 5){
          return status.name
        }else if(status.id === 3){
          return record && record.message && record.message && record.message.action_one_text
        }else if(status.id === 4){
          return record && record.message && record.message && record.message.action_two_text
        }else{
          return null
        }
      },
      sorter: (a, b) => a.status.name.toString().localeCompare(b.status.name),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Replied at',
      dataIndex: 'updated_at',
      render: (date) => {
        return Util.convertDateToPST(date).format(defaultFormatDate)
      },
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Submitted at',
      dataIndex: 'message.created_at',
      render: (date) => {
        return Util.convertDateToPST(date).format(defaultFormatDate)
      },
      sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },
  ],
  message: null,
  ledSequenceForPreview: {
    type: '',
    id: '',
    name: '',
    color_1: null,
    color_2: null,
    brightness_1: null,
    brightness_2: null,
    speed: '',
  },
  messageInputs: {
    campaign: {},
    status: {},
    message_template_id: { key: null, label: null, value: 0},
    event_id: { key: null, label: null, value: 0},
    members: [],
    action_one_text: 'I will go',
    action_one_color: '#FFFFFF',
    action_one_background: '#696969',
    action_one_show: false,
    action_one_confirmation_message: 'Successful',
    action_two_text: "I wont go",
    action_two_color: '#FFFFFF',
    action_two_background: '#FF4500',
    action_two_confirmation_message: 'Successful',
    action_two_show: false,
    show_contact_me: false,
    show_learn_more: false,
    type_id: "message",
    led_sequence_id: '',
    is_notification: false,
  },
  id: 0,
  messageReceiverReplies: [],
  totalMessageReceiverReplies: 0,
  filters: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case MessageActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case MessageActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.data,
        totalMessages: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case MessageActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.data,
        ledSequenceForPreview: action.payload.data.led_sequence,
        messageInputs: {
          ...action.payload.data,
          members:  action.payload.data.members.map((member) => member.id),
          message_template_id: action.payload.data.template ? { key: action.payload.data.template.id, value: action.payload.data.template.id, label: `${action.payload.data.template.id} - ${action.payload.data.template.subject}` } : {},
          event_id: action.payload.data.event ? { key: action.payload.data.event.id, value: action.payload.data.event.id, label: `${action.payload.data.event.headline} - ${action.payload.data.event.subheadline}` } : {},
        },
      }

    case MessageActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }

    case MessageActionType.CHANGE_MESSAGE_INPUT_VALUE:
      return { ...state, messageInputs: { ...state.messageInputs, [action.payload.property]: action.payload.value } }

    case MessageActionType.SET_ORIGINAL_MESSAGE_VALUE:
      return { ...state, messageInputs: action.payload.isNew ? defaultState.messageInputs : state.messageInputs }

    case MessageActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case MessageActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.POST_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.POST_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: 'message.success',
        messageInputs: defaultState.messageInputs,
        messageDataUpdated: true,
      }

    case MessageActionType.POST_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_RECEIVER_REPLIES_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageReceiverReplies: action.payload.data,
        totalMessageReceiverReplies: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }


    case MessageActionType.GET_BY_CAMPAIGN_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_BY_CAMPAIGN_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.data,
      }

    case MessageActionType.GET_BY_CAMPAIGN_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageReceiverReplies: action.payload.data,
        totalMessageReceiverReplies: action.payload.paginatedResults ? parseInt(action.payload.total) : action.payload.data.length,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_CAMPAIGN_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }

    case MessageActionType.GET_MESSAGE_EVENTS_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_MESSAGE_EVENTS_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageEvents: action.payload.data,
        totalMessageEvents: action.payload.total,
      }

    case MessageActionType.GET_MESSAGE_EVENTS_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageReceiverReplies: action.payload.data,
      }

    case MessageActionType.GET_RECEIVER_REPLIES_BY_EVENT_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data.message }


    case MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API_SUCCESS:
      return {
        ...state,
        loading: false,
        messageEvents: state.messageEvents.filter(messageEvent => messageEvent.id !== action.payload.id),
        successMessage: 'message.success',
        messageDataUpdated: true,
      }

    case MessageActionType.REMOVE_EVENT_FROM_MESSAGE_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.PATCH_INFO_BY_ID:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.PATCH_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case MessageActionType.PATCH_INFO_BY_ID_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API:
      return {
        ...state,
        loading: true,
      }

    case MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: action.payload,
      }

    case MessageActionType.GET_FILTER_BY_CAMPAIGN_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }


    case MessageActionType.SET_MESSAGE_DATA_UPDATED:
      return { ...state, messageDataUpdated: action.payload.data }

    default: return { ...state }
  }
}
