import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Button, message as AntMessage, Table} from 'antd'
import {LayoutAdmin} from './../../../components'
import {getMessagesAPI, setMessagePropertyValue} from './../../../effects/actions'
import {Link} from "react-router-dom"
import {EyeOutlined} from "@ant-design/icons"
import {defaultFormatDate} from "../../../constants/date"
import styled from "styled-components"
import EventModal from "../../../modals/event"
import MessageTemplateModal from "../../../modals/message-template"
import CampaignModal from "../../../modals/campaign"
import {Util} from "../../../utils"

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const MessageListPage = ({
  messages, loading,totalMessages, getMessagesAPI, setMessagePropertyValue, successMessage,
}) => {
  const [showEventModal, setShowEventModal] = useState(false)
  const [showMessageTemplateModal, setShowMessageTemplateModal] = useState(false)
  const [showCampaignModal, setShowCampaignModal] = useState(false)

  const [selectedEvent, setSelectedEvent] = useState()
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState()
  const [selectedCampaign, setSelectedCampaign] = useState()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [orderKey, setOrderKey] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const updateTableDimensions = (pageNum, pageS) => {
    if(pageNum!==pageNumber) {
      setPageNumber(pageNum)
    }
    if(pageS!==pageSize) {
      setPageSize(pageS)
    }
  }

  const messageListColumns = [
    {
      title: 'View',
      key: 'id',
      width: 80,
      dataIndex: 'id',
      fixed: 'left',
      render: (id) => {
        return (
            <Link to={`/notification/${id}`} key={id}>
              <Button>
                <EyeOutlined />
              </Button>
            </Link>
        )
      },
    },
    {
      title: 'Id',
      key: 'id',
      width: 80,
      dataIndex: 'id',
      fixed: 'left',
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      width: 140,
      render: (campaign) =>  (
          <StyledLink onClick={() => handleCampaignCellClick(campaign)}>
            {campaign.id} - {campaign.name}
          </StyledLink>
      ),
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('campaign_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Is Notification?',
      dataIndex: 'is_notification',
      width: 140,
      render: (value) => value ? "Yes" : "No",
    },
    {
      title: 'Message template id',
      dataIndex: 'message_template_id',
      width: 140,
      render: (message_template_id, data) => {
        if(message_template_id){
          return (
              <StyledLink onClick={() => handleMessageTemplateCellClick(data.template)}>
                {message_template_id}
              </StyledLink>
          )
        }
        return '-'
      },
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('message_template_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Event id',
      dataIndex: 'event_id',
      width: 140,
      render: (event_id, data) => {
        if (event_id){
          return (
              <StyledLink onClick={() => handleEventCellClick(data.event)}>
                {event_id}
              </StyledLink>
          )
        } else {
          return '-'
        }
      },
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('event_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Show action 1',
      dataIndex: 'action_one_show',
      key: 'action_one_show',
      width: 120,
      render: (value) => value ? "Yes" : "No",
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_one_show')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action 1 text',
      dataIndex: 'action_one_text',
      key: 'action_one_text',
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_one_text')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action 1 confirmation text',
      dataIndex: 'action_one_confirmation_message',
      key: 'action_one_confirmation_message',
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_one_confirmation_message')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Show action 2',
      dataIndex: 'action_two_show',
      key: 'action_two_show',
      render: (value) => value ? "Yes" : "No",
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_two_show')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action 2 text',
      dataIndex: 'action_two_text',
      key: 'action_two_text',
      width: 150,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_two_text')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action 2 confirmation text',
      dataIndex: 'action_two_confirmation_message',
      key: 'action_two_confirmation_message',
      width: 150,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('action_two_confirmation_message')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type_id',
      key: 'type_id',
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('type_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Show contact me',
      dataIndex: 'show_contact_me',
      key: 'show_contact_me',
      render: (value) => value ? "Yes" : "No",
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('show_contact_me')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Show Learn More',
      dataIndex: 'show_learn_more',
      key: 'show_learn_more',
      render: (value) => value ? "Yes" : "No",
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('show_learn_more')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'LED sequence',
      dataIndex: 'led_sequence',
      key: 'led_sequence',
      width: 120,
      render: (val) => val ? val.type : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => status && status.name,
      width: 120,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('status_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Submitted on',
      dataIndex: 'created_id',
      render: (date) => {
        return Util.convertDateToPST(date).format(defaultFormatDate)
      },
      width: 150,
      sorter: {
        compare: (a, b, sortDirection) => {
          setOrderKey('created_id')
          if(sortDirection==='descend') setOrderDirection('desc')
          if(sortDirection==='ascend') setOrderDirection('asc')
        },
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const handleCampaignCellClick = (data) => {
    setShowCampaignModal(true)
    setSelectedCampaign(data)
  }

  const handleCampaignDoneCallback = () => {
    setShowCampaignModal(false)
    setSelectedCampaign(null)
  }

  const handleEventCellClick = (event) => {
    setShowEventModal(true)
    setSelectedEvent(event)
  }

  const handleEventDoneCallback = () => {
    setShowEventModal(false)
    setSelectedEvent(null)
  }

  const handleMessageTemplateCellClick = (template) => {
    setShowMessageTemplateModal(true)
    setSelectedMessageTemplate(template)
  }

  const handleMessageTemplateDoneCallback = () => {
    setShowMessageTemplateModal(false)
    setSelectedMessageTemplate(null)
  }

  useEffect(() => {
    getMessagesAPI({limit: pageSize, page: pageNumber, orderKey, orderDirection})
  }, [getMessagesAPI, pageSize, pageNumber, orderKey, orderDirection])

  useEffect(() => {
    if (successMessage) {
      AntMessage.success(successMessage)
      setMessagePropertyValue('successMessage', null)
    }
  }, [setMessagePropertyValue, successMessage])

  return (
    <LayoutAdmin>
      <Table
          dataSource={messages}
          columns={messageListColumns}
          loading={loading}
          rowKey="id"
          scroll={{ x: '100%' }}
          pagination={{ total: totalMessages, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
      />
      <EventModal
          visible={showEventModal}
          doneCallback={handleEventDoneCallback}
          eventData={selectedEvent}
      />
      <MessageTemplateModal
          visible={showMessageTemplateModal}
          doneCallback={handleMessageTemplateDoneCallback}
          messageTemplateData={selectedMessageTemplate}
      />
      <CampaignModal
          visible={showCampaignModal}
          doneCallback={handleCampaignDoneCallback}
          campaignData={selectedCampaign}
      />
    </LayoutAdmin>
  )
}

MessageListPage.propTypes = {
  getMessagesAPI: PropTypes.func,
  messages: PropTypes.array,
  messageListColumns: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ messageAPI }) => {
  const {
    messages, loading, totalMessages, successMessage,
  } = messageAPI
  return {
    messages,
    totalMessages,
    loading,
    successMessage,
  }
}

export default
connect(mapStateToProps, {
  getMessagesAPI,
  setMessagePropertyValue,
})(MessageListPage)
