import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Switch} from 'antd'
import {changeAdminInputValue, changeAdminUserInputValue} from '../../effects/actions'
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons"

const AdminForm = ({adminInputs, changeAdminInputValue, changeAdminUserInputValue, viewMode, isNew}) => {
  const onChangeInput = (e) => {
    const { name, value } = e.target
      if (name.includes('user.')) {
          changeAdminUserInputValue(name.substring(5, name.length), value)
      } else {
          changeAdminInputValue(name, value)
      }
  }
  return (
        <Row gutter={[12, 0]}>
          <Col span={10}>
            <Form.Item label="Name" labelCol={{ span: 24 }}>
              <Input
                  size="large"
                  type="text"
                  name="user.name"
                  disabled={viewMode}
                  placeholder="Name"
                  onChange={onChangeInput}
                  value={adminInputs.user.name}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Email" labelCol={{ span: 24 }}>
              <Input
                  size="large"
                  type="email"
                  name="user.email"
                  disabled={viewMode}
                  placeholder="Email"
                  onChange={onChangeInput}
                  value={adminInputs.user.email}
              />
            </Form.Item>
          </Col>
            {
                isNew && (
                    (
                        <>
                            <Col span={10}>
                                <Form.Item label="Password" labelCol={{ span: 24 }}>
                                    <Input.Password
                                        size="large"
                                        name="user.password"
                                        placeholder="Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        onChange={onChangeInput}
                                        value={adminInputs.user.password}
                                        disabled={viewMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="Confirm Password" labelCol={{ span: 24 }}>
                                    <Input.Password
                                        size="large"
                                        name="user.confirmPassword"
                                        placeholder="Confirm Password"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        onChange={onChangeInput}
                                        value={adminInputs.user.confirmPassword}
                                        disabled={viewMode}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )
                )
            }
          <Col span={6}>
            <Form.Item label="Will Receive Notifications?" labelCol={{ span: 24 }}>
              <Switch
                  size="large"
                  onChange={(value) => changeAdminInputValue('receives_notifications', value)}
                  checked={adminInputs.receives_notifications}
                  disabled={viewMode}
              />
            </Form.Item>
          </Col>
        </Row>
  )
}

AdminForm.propTypes = {
  changeAdminInputValue: PropTypes.func,
  changeAdminUserInputValue: PropTypes.func,
  adminInputs: PropTypes.object,
  viewMode: PropTypes.bool,
  id: PropTypes.any,
}

AdminForm.defaultProps = {
  viewMode: false,
}
const mapStateToProps = ({ adminAPI }) => {
  const {
    errorMessage, successMessage, adminInputs,
  } = adminAPI
  return {
    errorMessage,
    successMessage,
    adminInputs,
  }
}

export default
connect(mapStateToProps, {
  changeAdminInputValue,
  changeAdminUserInputValue,
})(AdminForm)
