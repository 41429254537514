export const MemberActionType = {
  SET_PROPERTY_VALUE: 'MEMBER.SET_PROPERTY_VALUE',

  GET_LIST_API: 'MEMBER.GET_LIST_API',
  GET_LIST_API_SUCCESS: 'MEMBER.GET_LIST_API_SUCCESS',
  GET_LIST_API_FAILURE: 'MEMBER.GET_LIST_API_FAILURE',

  GET_BY_ID_API: 'MEMBER.GET_BY_ID_API',
  GET_BY_ID_API_SUCCESS: 'MEMBER.GET_BY_ID_API_SUCCESS',
  GET_BY_ID_API_FAILURE: 'MEMBER.GET_BY_ID_API_FAILURE',

  CHANGE_MEMBER_USER_INPUT_VALUE: 'MEMBER.CHANGE_MEMBER_USER_INPUT_VALUE',
  CHANGE_MEMBER_INPUT_VALUE: 'MEMBER.CHANGE_MEMBER_INPUT_VALUE',

  PUT_BY_ID_API: 'MEMBER.PUT_BY_ID_API',
  PUT_BY_ID_API_FAILURE: 'MEMBER.PUT_BY_ID_API_FAILURE',
  PUT_BY_ID_API_SUCCESS: 'MEMBER.PUT_BY_ID_API_SUCCESS',

  DELETE_BY_ID_API: 'MEMBER.DELETE_BY_ID_API',
  DELETE_BY_ID_API_FAILURE: 'MEMBER.DELETE_BY_ID_API_FAILURE',
  DELETE_BY_ID_API_SUCCESS: 'MEMBER.DELETE_BY_ID_API_SUCCESS',

  DETACH_BOX_BY_ID_API: 'MEMBER.DETACH_BOX_BY_ID_API',
  DETACH_BOX_BY_ID_API_FAILURE: 'MEMBER.DETACH_BOX_BY_ID_API_FAILURE',
  DETACH_BOX_BY_ID_API_SUCCESS: 'MEMBER.DETACH_BOX_BY_ID_API_SUCCESS',

  DEACTIVATE_BY_ID_API: 'MEMBER.DEACTIVATE_BY_ID_API',
  DEACTIVATE_BY_ID_API_FAILURE: 'MEMBER.DEACTIVATE_BY_ID_API_FAILURE',
  DEACTIVATE_BY_ID_API_SUCCESS: 'MEMBER.DEACTIVATE_BY_ID_API_SUCCESS',

  SET_ORIGINAL_MEMBER_VALUE: 'MEMBER.SET_ORIGINAL_MEMBER_VALUE',

  POST_BY_ID_API: 'MEMBER.POST_BY_ID_API',
  POST_BY_ID_API_FAILURE: 'MEMBER.POST_BY_ID_API_FAILURE',
  POST_BY_ID_API_SUCCESS: 'MEMBER.POST_BY_ID_API_SUCCESS',

  GET_REPLIES_BY_MEMBER_ID_API: 'MEMBER.GET_REPLIES_BY_MEMBER_ID_API',
  GET_REPLIES_BY_MEMBER_ID_API_FAILURE: 'MEMBER.GET_REPLIES_BY_MEMBER_ID_API_FAILURE',
  GET_REPLIES_BY_MEMBER_ID_API_SUCCESS: 'MEMBER.GET_REPLIES_BY_MEMBER_ID_API_SUCCESS',
}
