import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducers } from './../reducers'
import { signOutAPI } from './../actions'
import rootSaga from './../sagas'
import { API } from './../../utils/api'

const sagaMiddleware = createSagaMiddleware()

function axiosTokenMiddleware({ getState }) {
  return (next) => (action) => {
    const state = getState()
    const { token } = state.authAPI
    if (token !== null || token !== undefined) {
      API.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      API.defaults.headers.common.Authorization = null
    }

    return next(action)
  }
}

function axiosErrorMiddleware() {
  return (next) => (action) => {
    API.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.status === 401) {
        next(signOutAPI())
      } else if (error.response !== undefined) {
        return Promise.reject(error.response)
      }
      return Promise.reject(error)
    })

    return next(action)
  }
}

const middlewares = [
  sagaMiddleware,
]
const persistConfig = {
  timeout: 0,
  key: 'root',
  storage,
  whitelist: ['authAPI'],
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares, axiosTokenMiddleware, axiosErrorMiddleware)),
)
const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)
export { store, persistor }
