/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Layout } from 'antd'

import {
  SignInPage, HomePage, MemberListPage, MemberFormPage,
  BoxFormPage, BoxListPage, EventFormPage, EventListPage,
  CampaignFormPage, CampaignListPage, MessageRepliesPage,
  MessageFormPage, MessageListPage, SplashFormPage, SplashListPage,
  MessageTemplateListPage, MessageTemplateFormPage,
} from './pages'
import {MemberFormTabs} from "./pages/member/constants";
import {CampaignFormTabs} from "./pages/campaign/constants";
import {EventsFormTabs} from "./pages/event/constants";
import MembersRequestListPage from "./pages/members-request/list";
import {MessageRequestsTabs} from "./pages/members-request/constants/constant";
import LedSequenceListPage from "./pages/led-sequence/list";
import LedSequenceFormPage from "./pages/led-sequence/form";
import AdminFormPage from "./pages/admin/form";
import AdminListPage from "./pages/admin/list";
import ForgotPasswordPage from "./pages/forgot-password";
import BoxLightStateListPage from "./pages/box-light-states/list";
import BoxLightStateFormPage from "./pages/box-light-states/form";

function PrivateRoute({
  children, logged, ...rest
}) {
  let pathname = '/sign-in'
  if (rest.location.state !== undefined && rest.location.state.from !== undefined) pathname = rest.location.state.from.pathname
  return (
    <Route
      {...rest}
      render={({ location }) => !logged ? (
        children
      ) : (
        <Redirect
          to={{
            pathname,
            state: { from: location },
          }}
        />
      )}
    />
  )
}

function PublicRoute({
  children, logged, ...rest
}) {
  let pathname = '/'
  if (rest.location.state !== undefined && rest.location.state.from !== undefined) pathname = rest.location.state.from.pathname
  return (
    <Route
      {...rest}
      render={({ location }) => logged ? (
        children
      ) : (
        <Redirect
          to={{
            pathname,
            state: { from: location },
          }}
        />
      )}
    />
  )
}

const App = ({ ...props }) => {
  const check = props.loggedUser === null
  return (
    <Layout>
      <Switch>
        <PublicRoute path="/sign-in" logged={check}>
          <SignInPage />
        </PublicRoute>
        <PublicRoute path="/forgot-password" logged={check}>
          <ForgotPasswordPage />
        </PublicRoute>
        <PrivateRoute exact path="/" logged={check}>
          <Redirect from="/" to="/campaign" />
        </PrivateRoute>
        <PrivateRoute path="/admin/:id" logged={check}>
          <AdminFormPage />
        </PrivateRoute>
        <PrivateRoute path="/admin" logged={check}>
          <AdminListPage />
        </PrivateRoute>
        <PrivateRoute exact path={`/member/:id/:tabName(${Object.values(MemberFormTabs).join('|')})?`} logged={check}>
          <MemberFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/member" logged={check}>
          <MemberListPage />
        </PrivateRoute>
        <PrivateRoute exact path={`/campaign/:id/:tabName(${Object.values(CampaignFormTabs).join('|')})?`} logged={check}>
          <CampaignFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/campaign" logged={check}>
          <CampaignListPage />
        </PrivateRoute>
        <PrivateRoute path="/box/:id" logged={check}>
          <BoxFormPage />
        </PrivateRoute>
        <PrivateRoute path="/box" logged={check}>
          <BoxListPage />
        </PrivateRoute>
        <PrivateRoute path={`/event/:id/:tabName(${Object.values(EventsFormTabs).join('|')})?`} logged={check}>
          <EventFormPage />
        </PrivateRoute>
        <PrivateRoute exact path="/event" logged={check}>
          <EventListPage />
        </PrivateRoute>
        <PrivateRoute path="/notification/:id" logged={check}>
          <MessageFormPage />
        </PrivateRoute>
        <PrivateRoute path="/notification" logged={check}>
          <MessageListPage />
        </PrivateRoute>
        <PrivateRoute path="/splash/:id" logged={check}>
          <SplashFormPage />
        </PrivateRoute>
        <PrivateRoute path="/splash" logged={check}>
          <SplashListPage />
        </PrivateRoute>
        <PrivateRoute path="/message-replies" logged={check}>
          <MessageRepliesPage />
        </PrivateRoute>
        <PrivateRoute path="/home" logged={check}>
          <HomePage />
        </PrivateRoute>
        <PrivateRoute path={`/members-request/:tabName(${Object.values(MessageRequestsTabs).join('|')})?`} logged={check}>
          <MembersRequestListPage />
        </PrivateRoute>
        <PrivateRoute path="/message/:id" logged={check}>
          <MessageTemplateFormPage />
        </PrivateRoute>
        <PrivateRoute path="/message" logged={check}>
          <MessageTemplateListPage />
        </PrivateRoute>
        <PrivateRoute path="/led-sequence/:id" logged={check}>
          <LedSequenceFormPage />
        </PrivateRoute>
        <PrivateRoute path="/led-sequence" logged={check}>
          <LedSequenceListPage />
        </PrivateRoute>
        <PrivateRoute path="/box-light-state/:id" logged={check}>
          <BoxLightStateFormPage />
        </PrivateRoute>
        <PrivateRoute path="/box-light-state" logged={check}>
          <BoxLightStateListPage />
        </PrivateRoute>
        <Redirect to={'/'} />
        {/*<PrivateRoute path="/dashboard" logged={check}>*/}
        {/*  <HomePage />*/}
        {/*</PrivateRoute>*/}
      </Switch>
    </Layout>
  )
}

App.propTypes = {
  loggedUser: PropTypes.object,
}

const mapStateToProps = ({ authAPI }) => {
  const {
    loggedUser,
  } = authAPI
  return {
    loggedUser,
  }
}

export default
connect(mapStateToProps, null)(App)
