import {Col, Row, Select, Table} from "antd"
import MessageModal from "../../../modals/message"
import EventModal from "../../../modals/event"
import MessageTemplateModal from "../../../modals/message-template"
import MemberModal from "../../../modals/member"
import React, {useEffect, useState} from "react"
import moment from "moment"
import {defaultFormatDate} from "../../../constants/date"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import styled from "styled-components"
import {
    getEventsAPI,
    getMembersAPI,
    getMessagesAPI,
    getMessageTemplatesAPI,
    getMessageFilterByCampaignIdAPI,
    getReceiverRepliesByCampaignIdAPI,
} from "../../../effects/actions"
import SearchWithOption from "../search-with-option"
import BoxModal from "../../../modals/box"
import {Util} from "../../../utils"

const {Option} = Select

const StyledLink = styled.div`
  cursor: pointer;
  color: #1890ff;
`

const CampaignMessageRepliesTable = ({messageReceiverReplies, getReceiverRepliesByCampaignIdAPI, id, loading, totalMessageReceiverReplies, events, members, messageTemplates, messages, getMembersAPI,  getEventsAPI, getMessagesAPI, getMessageTemplatesAPI, getMessageFilterByCampaignIdAPI, filters}) => {

    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)
    const [showMessageTemplateModal, setShowMessageTemplateModal] = useState(false)
    const [showMemberModal, setShowMemberModal] = useState(false)
    const [showBoxModal, setShowBoxModal] = useState(false)

    const [selectedNotification, setSelectedNotification] = useState()
    const [selectedEvent, setSelectedEvent] = useState()
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState()
    const [selectedMember, setSelectedMember] = useState()
    const [selectedBox, setSelectedBox] = useState()

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [searchFilter, setSearchFilter] = useState({ name: '', value: ''})

    const messageReceiverRepliesListColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 20,
            render: (message, data) => data.id,
        },{
            title: 'Is notification?',
            dataIndex: 'isNotification',
            width: 20,
            render: (message, data) => data && data.message && data.message.is_notification ? "Yes" : "No",
        },
        {
            title: 'Notification',
            dataIndex: 'notification',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message, data) => {
                return (
                    <StyledLink onClick={() => handleNotificationCellClick(data)}>
                        {data.message_id}
                    </StyledLink>
                )
            },
        },
        {
            title: 'Message',
            dataIndex: 'message',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('message_template_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (message, data) => {
                if(message && message.message_template_id){
                    return (
                        <StyledLink onClick={() => handleMessageTemplateCellClick(data)}>
                            {message.message_template_id}
                        </StyledLink>
                    )
                }
                return '-'
            },
        },
        {
            title: 'Event',
            dataIndex: 'event',
            width: 50,
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('event_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (record, data) => {
                if (data && data.message && data.message.event_id){
                    return (
                        <StyledLink onClick={() => handleEventCellClick(data)}>
                            {data.message.event_id}
                        </StyledLink>
                    )
                }else{
                    return '-'
                }

            },
        },
        {
            title: 'Member',
            dataIndex: 'member',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('member_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (member) => (
                <StyledLink onClick={() => handleMemberCellClick(member)}>
                    {member.id} - {member.name}
                </StyledLink>
            ),
        },
        {
            title: 'Box',
            dataIndex: 'box',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('box_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: (box) => (
                <StyledLink onClick={() => handleBoxCellClick(box)}>
                    {box.id} - {box.name}
                </StyledLink>
            ),
        },
        {
            title: 'Response',
            dataIndex: 'status',
            render: (status, record) => {
                if(status.id === 5){
                    return status.name
                }else if(status.id === 3){
                    return record && record.message && record.message && record.message.action_one_text
                }else if(status.id === 4){
                    return record && record.message && record.message && record.message.action_two_text
                }else{
                    return '-'
                }
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('status_id')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Replied at',
            dataIndex: 'updated_at',
            render: (date) => {
                return Util.convertDateToPST(date).format(defaultFormatDate)
            },
            sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Submitted at',
            dataIndex: 'message.created_at',
            render: (date) => {
                return Util.convertDateToPST(date).format(defaultFormatDate)
            },
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('created_at')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },
    ]

    const onSearch = (name, value) => {
        setSearchFilter({ name, value})
    }

    const handleNotificationCellClick = (data) => {
        setShowNotificationModal(true)
        setSelectedNotification(data.message)
    }

    const handleNotificationDoneCallback = () => {
        setShowNotificationModal(false)
        setSelectedNotification(null)
    }

    const handleEventCellClick = (data) => {
        setShowEventModal(true)
        setSelectedEvent(data.message.event)
    }

    const handleEventDoneCallback = () => {
        setShowEventModal(false)
        setSelectedEvent(null)
    }

    const handleMessageTemplateCellClick = (data) => {
        setShowMessageTemplateModal(true)
        setSelectedMessageTemplate(data.message.template)
    }

    const handleMessageTemplateDoneCallback = () => {
        setShowMessageTemplateModal(false)
        setSelectedMessageTemplate(null)
    }

    const handleMemberCellClick = (data) => {
        setShowMemberModal(true)
        setSelectedMember(data)
    }

    const handleBoxCellClick = (data) => {
        setShowBoxModal(true)
        setSelectedBox(data)
    }

    const handleBoxDoneCallBack = () => {
        setShowBoxModal(false)
        setSelectedBox(null)
    }

    const handleMemberDoneCallback = () => {
        setShowMemberModal(false)
        setSelectedMember(null)
    }

    const updateTableDimensions = (pageNum, pageS) => {
        if(pageNum!==pageNumber) {
            setPageNumber(pageNum)
        }
        if(pageS!==pageSize) {
            setPageSize(pageS)
        }
    }

    useEffect( () => {
        getMembersAPI()
        getEventsAPI()
        getMessagesAPI()
        getMessageTemplatesAPI()
        getMessageFilterByCampaignIdAPI(id)
    }, [getMembersAPI, getEventsAPI, getMessagesAPI, getMessageTemplatesAPI, getMessageFilterByCampaignIdAPI, id])

    useEffect(() => {
        if (id !== 'new') {
            getReceiverRepliesByCampaignIdAPI({
                id,
                queryParams: {limit: pageSize, page: pageNumber, orderKey, orderDirection, filter: searchFilter},
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getReceiverRepliesByCampaignIdAPI, id, pageSize, pageNumber, orderKey, orderDirection, searchFilter.name, searchFilter.value])

    return (
        <>
            <Row>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'message_id'}
                        placeholder="Notification"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={filters && filters.messages
                            ? messages.filter(m => filters.messages.indexOf(m.id) !== -1).map(d => <Option key={d.id} value={d.id} id={d.id} label={d.id}>{d.id}</Option>)
                            : messages.map(d => <Option key={d.id} id={d.id} value={d.id} label={d.id}>{d.id}</Option>)
                        }
                    />
                </Col>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'event_id'}
                        placeholder="Event"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={filters && filters.events
                            ? events.filter(m => filters.events.indexOf(m.id) !== -1).map(d => <Option key={d.id} id={d.id} label={`${d.headline} - ${d.subheadline}`}>{d.headline} - {d.subheadline}</Option>)
                            : events.map(d => <Option key={d.id} id={d.id} label={`${d.headline} - ${d.subheadline}`}>{d.headline} - {d.subheadline}</Option>)}
                    />
                </Col>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'message_template_id'}
                        placeholder="Message"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={filters && filters.message_templates
                            ? messageTemplates.filter(m => filters.message_templates.indexOf(m.id) !== -1).map(d => <Option key={d.id} label={`${d.id} - ${d.subject}`}>{d.id} - {d.subject}</Option>)
                            : messageTemplates.map(d => <Option key={d.id} label={`${d.id} - ${d.subject}`}>{d.id} - {d.subject}</Option>)}
                    />
                </Col>
                <Col span={6}>
                    <SearchWithOption
                        onSearch={onSearch}
                        fieldName={'member_id'}
                        placeholder="Member"
                        fieldValue={searchFilter.value}
                        disabled={false}
                        options={filters && filters.members
                            ? members.filter(m => filters.members.indexOf(m.id) !== -1).map(d => <Option key={d.id} label={`${d.id} - ${d.name}`}>{d.id} - {d.name}</Option>)
                            : members.map(d => <Option key={d.id} label={`${d.id} - ${d.name}`}>{d.id} - {d.name}</Option>)}
                    />
                </Col>
            </Row>
            <Table
                dataSource={messageReceiverReplies}
                columns={messageReceiverRepliesListColumns}
                loading={loading}
                rowKey="id"
                pagination={{ total: totalMessageReceiverReplies, defaultCurrent: pageNumber, onChange: (a,b) => {updateTableDimensions(a,b)}, defaultPageSize: pageSize,  showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            />
            <MessageModal
                visible={showNotificationModal}
                doneCallback={handleNotificationDoneCallback}
                messageData={selectedNotification}
            />
            <EventModal
                visible={showEventModal}
                doneCallback={handleEventDoneCallback}
                eventData={selectedEvent}
            />
            <MessageTemplateModal
                visible={showMessageTemplateModal}
                doneCallback={handleMessageTemplateDoneCallback}
                messageTemplateData={selectedMessageTemplate}
            />
            <MemberModal
                visible={showMemberModal}
                doneCallback={handleMemberDoneCallback}
                memberData={selectedMember}
            />
            <BoxModal
                visible={showBoxModal}
                doneCallback={handleBoxDoneCallBack}
                boxData={selectedBox}
            />
        </>
    )
}

CampaignMessageRepliesTable.propTypes = {
    id: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    loading: PropTypes.bool,
    filters: PropTypes.object,
}

const mapStateToProps = ({ messageAPI,eventAPI, memberAPI,messageTemplateAPI  }) => {
    const {
        messages, errorMessage, successMessage, loading,
        messageReceiverReplies, totalMessageReceiverReplies, filters,
    } = messageAPI
    const { events } = eventAPI
    const { members } = memberAPI
    const { messageTemplates } = messageTemplateAPI

    return {
        errorMessage,
        successMessage,
        loading,
        messageReceiverReplies,
        totalMessageReceiverReplies,
        messages,
        events,
        members,
        messageTemplates,
        filters,
    }
}

export default connect(mapStateToProps, {
    getReceiverRepliesByCampaignIdAPI,
    getMembersAPI,
    getEventsAPI,
    getMessagesAPI,
    getMessageTemplatesAPI,
    getMessageFilterByCampaignIdAPI,
})(CampaignMessageRepliesTable)

