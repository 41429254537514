import { SplashActionType } from './../../types'

export const getSplashesAPISuccess = (response) => ({
  type: SplashActionType.GET_LIST_API_SUCCESS,
  payload: response,
})

export const getSplashesAPIFailure = (
  error,
) => ({
  type: SplashActionType.GET_LIST_API_FAILURE,
  payload: error,
})

export const getSplashesAPI = (queryParams) => ({
  type: SplashActionType.GET_LIST_API,
  params: queryParams,
})

export const getSplashAPISuccess = (response) => ({
  type: SplashActionType.GET_BY_ID_API_SUCCESS,
  payload: response,
})

export const getSplashAPIFailure = (
  error,
) => ({
  type: SplashActionType.GET_BY_ID_API_FAILURE,
  payload: error,
})

export const getSplashAPI = (
  id,
) => ({
  type: SplashActionType.GET_BY_ID_API,
  payload: id,
})

export const changeSplashInputValue = (
  property, value,
) => ({
  type: SplashActionType.CHANGE_SPLASH_INPUT_VALUE,
  payload: { property, value },
})

export const setSplashPropertyValue = (
  property, value,
) => ({
  type: SplashActionType.SET_PROPERTY_VALUE,
  payload: { property, value },
})

export const putSplashAPISuccess = (response) => ({
  type: SplashActionType.PUT_BY_ID_API_SUCCESS,
  payload: response,
})

export const putSplashAPIFailure = (
  error,
) => ({
  type: SplashActionType.PUT_BY_ID_API_FAILURE,
  payload: error,
})

export const putSplashAPI = (
  data,
) => ({
  type: SplashActionType.PUT_BY_ID_API,
  payload: data,
})

export const deleteSplashAPI = (
    splash,
) => ({
  type: SplashActionType.DELETE_BY_ID_API,
  payload: splash,
})

export const deleteSplashAPISuccess = (response) => ({
  type: SplashActionType.DELETE_BY_ID_API_SUCCESS,
  payload: response,
})

export const deleteSplashAPIFailure = (
    error,
) => ({
  type: SplashActionType.DELETE_BY_ID_API_FAILURE,
  payload: error,
})

export const setOriginalSplashValue = (isNew) => ({
  type: SplashActionType.SET_ORIGINAL_SPLASH_VALUE,
  payload: { isNew },
})

export const postSplashAPISuccess = (response) => ({
  type: SplashActionType.POST_BY_ID_API_SUCCESS,
  payload: response,
})

export const postSplashAPIFailure = (
  error,
) => ({
  type: SplashActionType.POST_BY_ID_API_FAILURE,
  payload: error,
})

export const postSplashAPI = (
  data,
) => ({
  type: SplashActionType.POST_BY_ID_API,
  payload: data,
})

export const repositionSplashAPISuccess = (response) => ({
  type: SplashActionType.REPOSITION_SPLASH_ITEM_SUCCESS,
  payload: response,
})

export const repositionSplashAPIFailure = (
    error,
) => ({
  type: SplashActionType.REPOSITION_SPLASH_ITEM_FAILURE,
  payload: error,
})

export const repositionSplashAPI = (
    data,
) => ({
  type: SplashActionType.REPOSITION_SPLASH_ITEM,
  payload: data,
})
