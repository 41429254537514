export const MessageRequestsTabs = {
    MESSAGE_REQUESTS: 'message-requests',
    CONTACT_ME_REQUESTS: 'contact-me-requests',
}

export const isContactedMeOptions = [{
    id: 'Yes',
    value: 'true',
}, {
    id: 'No',
    value: 'false',
}]

export const statusOptions = [{
    id: 'Will go',
    value: '3',
}, {
    id: 'Wont go',
    value: '4',
}, {
    id: 'Not replied yet',
    value: '5',
}]
