import {BoxLightStateActionType} from '../../types'

const defaultState = {
  successMessage: null,
  errorMessage: null,
  loading: false,
  boxLightStates: [],
  boxLightState: null,
  boxLightStateInputs: {
    name: '',
    description: '',
    led_sequence_id: null,
    ledSequence: {},
  },
  id: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case BoxLightStateActionType.SET_PROPERTY_VALUE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      }

    case BoxLightStateActionType.GET_LIST_API:
      return {
        ...state,
        loading: true,
      }

    case BoxLightStateActionType.GET_LIST_API_SUCCESS:
      return {
        ...state,
        loading: false,
        boxLightStates: action.payload.data,
      }

    case BoxLightStateActionType.GET_LIST_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxLightStateActionType.GET_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxLightStateActionType.GET_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        boxLightState: {
          ...action.payload.data,
        },
        boxLightStateInputs: {
          ...action.payload.data,
        },
      }

    case BoxLightStateActionType.GET_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    case BoxLightStateActionType.CHANGE_BOX_LIGHT_STATE_INPUT_VALUE:
      return { ...state, boxLightStateInputs: { ...state.boxLightStateInputs, [action.payload.property]: action.payload.value } }

    case BoxLightStateActionType.SET_ORIGINAL_BOX_LIGHT_STATE_VALUE:
      return { ...state, boxLightStateInputs: state.boxLightState }

    case BoxLightStateActionType.PUT_BY_ID_API:
      return {
        ...state,
        loading: true,
      }

    case BoxLightStateActionType.PUT_BY_ID_API_SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.payload.data.id,
        successMessage: 'message.success',
      }

    case BoxLightStateActionType.PUT_BY_ID_API_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.data }

    default: return { ...state }
  }
}
